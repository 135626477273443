import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import { DocumentUploadService, DocumentDownloadService } from 'gw-portals-document-js';

export default class PolicyService {
    static getAccountPolicySummaries(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicySummaries', [], authHeader);
    }

    static getAccountPolicyDetails(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicyDetails', [policyNumber], authHeader);
    }

    static uploadDocument(document, documentMetadata, authHeader = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('uploadPolicyDocument'), document, documentMetadata, authHeader);
    }

    // nfum custom
    static removeDocument(publicID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('docs'), 'removeDocument', [publicID], authHeader);
    }

    // nfum custom
    static getDocumentUploadToken(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('docs'), 'generateUploadToken', params, additionalHeaders);
    }

    // nfum custom
    static getDocuments(params, additionalHeaders, additionalParams) {
        return JsonRPCService.send(getProxiedServiceUrl('docs'), 'getDocuments_NFUM', params, additionalHeaders, additionalParams);
    }

    // nfum custom
    static getDocumentUrl(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('docs'), 'getDocumentRedirectURL_NFUM', params, additionalHeaders);
    }

    /**
     * Added US516289 added to pass assigned agency details to frontend.
     * Retrieves producer details.
     * for NFUM
     * @param {Object} params the retrieval payload (job no or policy no and transaction type)
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static retrieveProducerDetails(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'fetchProducerDetails_NFUM', params, additionalHeaders);
    }

    static downloadDocument(documentID, token) {
        return DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('downloadDocument'), documentID, token);
    }

    /*
         * Added as part of #819557 to include auth headers when downloading doc.
         * @param {string} url
         * @param {Object} authHeader
         * @param {function} errorCallBack
         * @returns void
         */
    static getDocument(url, authHeader, errorCallBack = () => {}) {
        return DocumentDownloadService.getDocument(url, authHeader, errorCallBack);
    }
}
