import React, { useCallback, useMemo, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './NfumCostInfoBox.metadata.json5';
import styles from './NfumCostInfoBox.module.scss';
import NfumCostsPerLocation from '../NfumCostsPerLocation/NfumCostsPerLocation';
import messages from './NfumCostInfoBox.messages';

function NfumCostInfoBox(props) {
    const {
        modelVM,
        topText,
        titleText,
        isPerYear,
        premiumText,
        additionalText,
        policyNumber,
        policyStartDate,
        annualPremiumTitile,
        yourAnnualPremiumTitile,
        remainingPolicyPeriod,
        classNames: receivedClassNames,
        noTransactionCost
    } = props;
    const translator = useContext(TranslatorContext);

    const extraClass = 'amountBold';
    const additionalTextClassNames = `${receivedClassNames} ${extraClass}`;

    const getHomePropertyName = useCallback((costInfo) => {
        const { homePropertyType, homePropertyAddressLine1, homePropertyAddressLine2 } = costInfo;
        if (homePropertyAddressLine1 && homePropertyAddressLine2) {
            return `${homePropertyType} at ${homePropertyAddressLine1}, ${homePropertyAddressLine2}`;
        }
        if (homePropertyAddressLine1 && !homePropertyAddressLine2) {
            return `${homePropertyType} at ${homePropertyAddressLine1}`;
        }
        return undefined;
    }, []);

    const getCostInfosByLocationFiltered = useCallback((costInfos) => {
        if (costInfos.length === 0) return [];

        const map = new Map();
        costInfos.forEach((item) => {
            const { homePropertyId } = item;
            if (!map.has(homePropertyId)) {
                map.set(homePropertyId, { name: getHomePropertyName(item), items: [item] });
            } else {
                map.get(homePropertyId).items.push(item);
            }
        });

        return Array.from(map.values());
    }, [getHomePropertyName]);

    const getSum = useCallback((items, sumPropertyName) => items
        .reduce((accumulator, item) => {
            return accumulator + item[sumPropertyName];
        }, 0), []);

    const isCovExists = useCallback((item, covPatternCode, covExtraCode = undefined) => {
        return item.patternCode === covPatternCode && item.extraCode === covExtraCode;
    }, []);

    const isLobExists = useCallback((items, linePatternCode) => {
        return items.filter((costInfo) => costInfo.patternCode
            .startsWith(linePatternCode)).length > 0;
    }, []);

    const getAmountForLob = useCallback((items, linePatternCode) => {
        return getSum(items.filter((item) => item.patternCode.startsWith(linePatternCode)), 'grossAnnualPremium');
    }, [getSum]);

    const getContentsAccidentalDamageAmount = useCallback((items, costInfo) => {
        if (!costInfo) { // special case when AD is included as standard
            const tierLevel = _.get(modelVM.value, 'tierLevel_NFUM');
            if (tierLevel === 'tierLevel3') {
                return translator(messages.includedAsStandard);
            }
        } else {
            const tierLevel = costInfo.contentsTierLevel;
            if (tierLevel === 'tierLevel1' || tierLevel === 'tierLevel2') {
                return costInfo?.grossAnnualPremium;
            }
            return costInfo && translator(messages.includedAsStandard);
        }
        return undefined;
    }, [modelVM.value, translator]);

    const getContentsAwayName = useCallback((costInfo) => {
        if (!costInfo) return '';
        const {
            isUnspecifiedContentsAway,
            contentsTierLevel,
            isBaseCoverOnly
        } = costInfo;
        if ((contentsTierLevel === 'tierLevel1' || contentsTierLevel === 'tierLevel2') && isUnspecifiedContentsAway) {
            return translator(messages.contentsAwayFromHome);
        }
        if ((contentsTierLevel === 'tierLevel1' || contentsTierLevel === 'tierLevel2') && !isUnspecifiedContentsAway) {
            return translator(messages.contentsAwayFromHomeNamedItemsOnly);
        }
        if (contentsTierLevel === 'tierLevel3' && isBaseCoverOnly) {
            return translator(messages.contentsAwayFromHome);
        }
        return translator(messages.contentsAwayFromHome);
    }, [translator]);

    const getContentsAwayAmount = useCallback((costInfo) => {
        if (!costInfo) return undefined;
        const contentsAwayInfo = costInfo?.find((info) => info.patternCode === 'HOMContentsAwayCov');
        if (!contentsAwayInfo) return '';
        const {
            contentsTierLevel,
            isBaseCoverOnly
        } = contentsAwayInfo;
        if (contentsTierLevel === 'tierLevel3' && isBaseCoverOnly) {
            return translator(messages.includedAsStandard);
        }
        return contentsAwayInfo.grossAnnualPremium;
    }, [translator]);

    const getCyclingProtectionCostInfoElem = useCallback((item) => {
        return (item.patternCode === 'HOMContentsCov'
            && item.extraCode === 'HOMContentsCovPedalCycleExtension')
            || (item.patternCode === 'HOMContentsAwayCov'
            && item.extraCode === 'HOMContentsAwayCovPedalCycleExtension');
    }, []);

    const getHomeEmergencyName = useCallback(() => {
        const isBespoke = _.get(modelVM.value, 'isBespokeHomeInsurance_NFUM') === true;
        if (isBespoke) {
            return translator(messages.homeEmergencyIncludedAsStandard);
        }
        return translator(messages.homeEmergency);
    }, [modelVM.value, translator]);

    const getOtherCoversVisibleCond = useCallback(() => {
        return _.get(modelVM.value, 'isBespokeHomeInsurance_NFUM') !== true
            && _.get(modelVM, 'lobData.value.homeLine.coverables.homhomeProperty')?.homhomePropertyDetails?.otherLandOwned === true;
    }, [modelVM]);

    const getOtherCoversAmount = useCallback((items) => {
        return items.find((item) => item.patternCode === 'HOMPublicLiabilityCov')?.grossAnnualPremium || 0;
    }, []);

    const getEmployersLiabilityAmount = useCallback((items) => {
        return items.find((item) => item.patternCode === 'HOMEmployersLiabilityCov')?.grossAnnualPremium || 0;
    }, []);

    const getPublicLiabilityAmount = useCallback((items) => {
        return items.find((item) => item.patternCode === 'HOMPublicLiabilityCov')?.grossAnnualPremium || 0;
    }, []);

    const getPersonalLexName = useCallback(() => {
        if (_.get(modelVM.value, 'tierLevel_NFUM') === 'tierLevel3'
            || _.get(modelVM.value, 'isBespokeHomeInsurance_NFUM') === true) {
            return translator(messages.personalLegalExpensesIncludedAsStandard);
        }
        return translator(messages.personalLegalExpenses);
    }, [modelVM.value, translator]);

    const getPersonalCyberName = useCallback(() => {
        const isBespoke = _.get(modelVM.value, 'isBespokeHomeInsurance_NFUM') === true;
        if (isBespoke) {
            return translator(messages.personalCyberIncludedAsStandard);
        }
        return translator(messages.personalCyber);
    }, [modelVM.value, translator]);

    const getCaravansName = useCallback(() => {
        const caravans = _.get(modelVM.value, 'lobData.caravanLine.coverables.caravans');
        if (!caravans) return '';
        if (caravans.length > 1) {
            return `${translator(messages.caravans)} - ${translator(messages.referToPolicyDocuments)}`;
        }
        return translator(messages.caravans);
    }, [modelVM.value, translator]);

    const getAnimalsName = useCallback(() => {
        const animals = _.get(modelVM.value, 'lobData.animalLine.coverables.animals');
        if (!animals) return '';
        if (animals.length > 1) {
            return `${translator(messages.dogsAndCats)} - ${translator(messages.referToPolicyDocuments)}`;
        }
        return translator(messages.dogsAndCats);
    }, [modelVM.value, translator]);

    const getIndividualsName = useCallback(() => {
        const individuals = _.get(modelVM.value, 'lobData.personalAccidentLine.coverables.individuals');
        if (!individuals) return '';
        if (individuals.length > 1) {
            return `${translator(messages.personalAccident)} - ${translator(messages.referToPolicyDocuments)}`;
        }
        return translator(messages.personalAccident);
    }, [modelVM.value, translator]);

    const getTravellersName = useCallback(() => {
        const travellers = _.get(modelVM.value, 'lobData.travelLine.coverables.travellers');
        if (!travellers) return '';
        if (travellers.length > 1) {
            return `${translator(messages.annualTravel)} - ${translator(messages.referToPolicyDocuments)}`;
        }
        return translator(messages.annualTravel);
    }, [modelVM.value, translator]);

    const getMutualBonusVisibleCond = useCallback((items) => {
        return items
            .filter((costInfo) => costInfo.mutualBonus !== undefined
            && costInfo.mutualBonus !== 0).length > 0;
    }, []);

    const covsConfig = useMemo(() => [
        {
            costInfos: getCostInfosByLocationFiltered(_.get(modelVM, 'costInfos_NFUM.value')).filter((loc) => !!loc.name),
            getName: (costInfo) => getHomePropertyName(costInfo),
            items: [
                {
                    getName: () => translator(messages.buildings),
                    getCostInfoElem: (item) => isCovExists(item, 'HOMBuildingsCov')
                },
                {
                    getName: () => translator(messages.buildingsAccidentalDamage),
                    getCostInfoElem: (item) => isCovExists(item, 'HOMBuildingsCov', 'HOMBuildingsCovAccidentalDamageRequired')
                },
                {
                    getName: () => translator(messages.contents),
                    getCostInfoElem: (item) => isCovExists(item, 'HOMContentsCov')
                },
                {
                    getName: () => translator(messages.contentsAccidentalDamage),
                    getVisibleCond: () => _.get(modelVM.value, 'isBespokeHomeInsurance_NFUM') !== true,
                    getCostInfoElem: (item) => isCovExists(item, 'HOMContentsCov', 'HOMContentsCovAccidentalDamageCover'),
                    getAmount: getContentsAccidentalDamageAmount
                },
                {
                    getName: getContentsAwayName,
                    getCostInfoElem: (item) => isCovExists(item, 'HOMContentsAwayCov'),
                    getAmount: getContentsAwayAmount
                },
                {
                    getName: () => translator(messages.cyclingProtection),
                    getCostInfoElem: getCyclingProtectionCostInfoElem
                },
                {
                    getName: () => translator(messages.valuables),
                    getCostInfoElem: (item) => isCovExists(item, 'HOMValuablesCov')
                },
                {
                    getName: () => translator(messages.fineArtAndCollections),
                    getCostInfoElem: (item) => isCovExists(item, 'HOMFineArtCollectionsCov')
                },
                {
                    getName: getHomeEmergencyName,
                    getCostInfoElem: (item) => isCovExists(item, 'HOMHomeEmergencyCov')
                },
            ]
        },
        {
            costInfos: getCostInfosByLocationFiltered(_.get(modelVM, 'costInfos_NFUM.value')).filter((loc) => loc.name === undefined),
            getName: () => translator(messages.otherCovers),
            items: [
                {
                    getName: () => translator(messages.extendedPublicLiability),
                    getVisibleCond: getOtherCoversVisibleCond,
                    getAmount: getOtherCoversAmount
                },
                {
                    getName: () => translator(messages.employersLiabilityIncludedAsStandard),
                    getVisibleCond: () => _.get(modelVM.value, 'isBespokeHomeInsurance_NFUM') === true,
                    getAmount: getEmployersLiabilityAmount
                },
                {
                    getName: () => translator(messages.publicLiabilityIncludedAsStandard),
                    getVisibleCond: () => _.get(modelVM.value, 'isBespokeHomeInsurance_NFUM') === true,
                    getAmount: getPublicLiabilityAmount
                },
                {
                    getName: getPersonalLexName,
                    getCostInfoElem: (item) => item.patternCode === 'LEXPersonalLegalExpensesCov'
                            && !item.displayName.includes('Extra')
                },
                {
                    getName: () => translator(messages.personalLegalExpensesExtra),
                    getCostInfoElem: (item) => item.patternCode === 'LEXPersonalLegalExpensesCov'
                            && item.displayName.includes('Extra')
                },
                {
                    getName: getPersonalCyberName,
                    getCostInfoElem: (item) => isCovExists(item, 'HOMPersonalCyberCov')
                },
            ]
        },
        {
            costInfos: getCostInfosByLocationFiltered(_.get(modelVM, 'costInfos_NFUM.value')).filter((loc) => loc.name === undefined),
            getName: () => translator(messages.caravans),
            getVisibleCond: (items) => isLobExists(items, 'CVN'),
            items: [
                {
                    getName: getCaravansName,
                    getAmount: (items) => getAmountForLob(items, 'CVN')
                },
            ]
        },
        {
            costInfos: getCostInfosByLocationFiltered(_.get(modelVM, 'costInfos_NFUM.value')).filter((loc) => loc.name === undefined),
            getName: () => translator(messages.dogsAndCats),
            getVisibleCond: (items) => isLobExists(items, 'ANM'),
            items: [
                {
                    getName: getAnimalsName,
                    getAmount: (items) => getAmountForLob(items, 'ANM')
                },
            ]
        },
        {
            costInfos: getCostInfosByLocationFiltered(_.get(modelVM, 'costInfos_NFUM.value')).filter((loc) => loc.name === undefined),
            getName: () => translator(messages.personalAccident),
            getVisibleCond: (items) => isLobExists(items, 'PPA'),
            items: [
                {
                    getName: getIndividualsName,
                    getAmount: (items) => getAmountForLob(items, 'PPA')
                },
            ]
        },
        {
            costInfos: getCostInfosByLocationFiltered(_.get(modelVM, 'costInfos_NFUM.value')).filter((loc) => loc.name === undefined),
            getName: () => translator(messages.annualTravel),
            getVisibleCond: (items) => isLobExists(items, 'TRV'),
            items: [
                {
                    getName: getTravellersName,
                    getAmount: (items) => getAmountForLob(items, 'TRV')
                },
            ]
        },
        {
            costInfos: getCostInfosByLocationFiltered(_.get(modelVM, 'costInfos_NFUM.value')).filter((loc) => loc.name === undefined),
            getName: () => translator(messages.summary),
            items: [
                {
                    getName: () => translator(messages.mutualBonus),
                    getVisibleCond: getMutualBonusVisibleCond,
                    getAmount: (items) => getSum(items, 'mutualBonus')
                },
                {
                    getName: () => translator(messages.totalCost),
                    getAmount: (items) => getSum(items, 'grossAnnualPremium')
                }
            ]
        }
    ], [
        modelVM,
        translator,
        getHomePropertyName,
        getCostInfosByLocationFiltered,
        getSum,
        isLobExists,
        isCovExists,
        getAmountForLob,
        getContentsAccidentalDamageAmount,
        getContentsAwayName,
        getContentsAwayAmount,
        getCyclingProtectionCostInfoElem,
        getHomeEmergencyName,
        getOtherCoversVisibleCond,
        getOtherCoversAmount,
        getEmployersLiabilityAmount,
        getPublicLiabilityAmount,
        getPersonalLexName,
        getPersonalCyberName,
        getCaravansName,
        getAnimalsName,
        getIndividualsName,
        getTravellersName,
        getMutualBonusVisibleCond
    ]);

    const getGrossAnnualPremium = useCallback((itemConfig, allCostInfos, info) => {
        return itemConfig.getAmount
            ? itemConfig.getAmount(allCostInfos, info)
            : info?.grossAnnualPremium;
    }, []);

    const getInfo = useCallback((itemConfig, location) => itemConfig.getCostInfoElem
        && location.items.find((costInfo) => itemConfig.getCostInfoElem(costInfo)), []);

    const filterResults = useCallback((item) => item.grossAnnualPremium !== undefined
        && item.grossAnnualPremium !== 0, []);

    const costInfosGrouped = useMemo(() => {
        const allCostInfos = _.get(modelVM, 'costInfos_NFUM.value');
        const groups = [];

        covsConfig.forEach((config) => {
            if (!config.getVisibleCond
                || config.getVisibleCond(allCostInfos)) {
                config.costInfos.forEach((location) => {
                    groups.push({
                        name: config.getName(location.items[0]),
                        items: config.items.map((itemConfig) => {
                            if (itemConfig.getVisibleCond
                                && !itemConfig.getVisibleCond(allCostInfos)) {
                                return {};
                            }
                            const info = getInfo(itemConfig, location);
                            return {
                                displayName: itemConfig.getName(info),
                                grossAnnualPremium:
                                    getGrossAnnualPremium(itemConfig, allCostInfos, info)
                            };
                        }).filter(filterResults)
                    });
                });
            }
        });

        return groups.filter((group) => group.items.length > 0);
    }, [modelVM, covsConfig, filterResults, getGrossAnnualPremium, getInfo]);

    const generateOverrides = useCallback(() => {
        if (!costInfosGrouped) return Object.assign({});

        const filterCostInfosPerLocation = (covs) => {
            return {
                name: covs?.name,
                items: covs?.items?.filter((item) => item.displayName && item.grossAnnualPremium)
            };
        };

        const fineArtsAddedOverrides = costInfosGrouped
            .map((locationWithCovs, index) => {
                return {
                    [`costsPerLocation${index}`]: {
                        costInfosPerLocation: filterCostInfosPerLocation(locationWithCovs),
                        // the last table should be without header
                        noHeader: index === costInfosGrouped.length - 1,
                        index: index
                    }
                };
            });

        return Object.assign({}, ...fineArtsAddedOverrides);
    }, [
        costInfosGrouped
    ]);

    const getChangeEffectiveDate = (effectiveDate) => {
        if (effectiveDate) {
            const { year, month, day } = effectiveDate;
            return moment(new Date(year, month, day))
                .format('DD MMMM YYYY');
        }
        return '';
    };

    const overrideProps = {
        topText: {
            visible: topText !== null,
            content: topText
        },
        title: {
            content: titleText,
            className: receivedClassNames
        },
        additionalText: {
            content: additionalText,
            className: additionalTextClassNames
        },
        remaingPolicyPeriod: {
            content: remainingPolicyPeriod,
            className: receivedClassNames,
            visible: !noTransactionCost
        },
        perMonthAsterisk: {
            visible: isPerYear === false
        },
        perYearAsterisk: {
            visible: isPerYear === true
        },
        annualPremiumTitle: {
            content: noTransactionCost ? yourAnnualPremiumTitile : annualPremiumTitile
        },
        amount: {
            content: premiumText,
        },
        premiumContainer: {
            className: receivedClassNames
        },
        costInfosPerLocation: {
            data: costInfosGrouped
        },
        yourPolicyStartDateContent: {
            content: getChangeEffectiveDate(policyStartDate)
        },
        yourPolicyNumberContent: {
            content: policyNumber
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            nfumCostsPerLocation: NfumCostsPerLocation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default NfumCostInfoBox;
