import {
    useCallback
} from 'react';
import _ from 'lodash';
import { useCms } from 'nfum-cms-react';
import usePeriodsDiff from './usePeriodsDiff';

const coverageId = Object.freeze({
    BUILDINGS: 'HOMBuildingsCov',
    CONTENTS: 'HOMContentsCov',
    CONTENTS_AWAY: 'HOMContentsAwayCov',
    HOME_EMERGENCY: 'HOMHomeEmergencyCov',
    LEGAL_EXPENSE: 'LEXPersonalLegalExpensesCov',
    PEDAL_CYCLE_EXT: 'HOMContentsAwayCovPedalCycleExtension',
    PEDAL_CYCLE_BESPOKE: 'HOMContentsCovPedalCycleExtension',
});

const cmsDocId = Object.freeze({
    IPID_BUILDINGS: 'ipid_buildings',
    IPID_CONTENTS_TIER_1: 'ipid_contents_tier_1',
    IPID_CONTENTS_TIER_2: 'ipid_contents_tier_2',
    IPID_CONTENTS_TIER_3: 'ipid_contents_tier_3',
    IPID_BUILDINGS_CONTENTS_TIER_1: 'ipid_buildings_contents_tier_1',
    IPID_BUILDINGS_CONTENTS_TIER_2: 'ipid_buildings_contents_tier_2',
    IPID_BUILDINGS_CONTENTS_TIER_3: 'ipid_buildings_contents_tier_3',
    IPID_CONTENTS_AWAY_TIER_1: 'ipid_contents_away_tier_1',
    IPID_CONTENTS_AWAY_TIER_2: 'ipid_contents_away_tier_2',
    IPID_LEX: 'ipid_lex',
    IPID_LEX_EXTRA: 'ipid_lex_extra',
    IPID_HOME_EMERGENCY: 'ipid_home_emergency',
    IPID_CYCLING_PROTECTION: 'ipid_cycling_protection',
    IPID_CYCLING_PROTECTION_BESPOKE: 'ipid_cycling_protection_bespoke',
    IPID_LEX_EXTRA_BESPOKE: 'ipid_lex_extra_bespoke',
});

const tierLevel = Object.freeze({
    ONE: 'tierLevel1',
    TWO: 'tierLevel2',
    THREE: 'tierLevel3'
});

const lobs = Object.freeze({
    HOME_LINE: 'homeLine',
    LEX_LINE: 'lexLine'
});

const useCmsDocumentsRetriever = (jobVM) => {
    const { getDocuments } = useCms();
    const { getAdditionalCovers } = usePeriodsDiff(jobVM);
    const additionalCovers = getAdditionalCovers();
    const isBespoke = _.get(jobVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const prevHomCov = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.coverages');
    const lexStatus =
    additionalCovers.find((status) => status.coverageId === coverageId.LEGAL_EXPENSE);
    const isLexCovChanged = lexStatus.newValue !== lexStatus.oldValue;

    const levelOfCovers = Object.freeze({
        STANDARD: 'Standard',
        EXTRA: 'Extra'
    });

    const getIsTierLevel = useCallback((level) => {
        const tierLevelNfum = _.get(jobVM.value, 'tierLevel_NFUM');
        return tierLevelNfum === level;
    }, [jobVM.value]);

    const getCoverages = useCallback((line, id) => {
        const coverages = _.get(jobVM.value, `lobData.${line}.lineCoverages.coverages`);
        return coverages.find((cov) => cov.publicID === id);
    }, [jobVM.value]);

    const contentsAway = getCoverages(lobs.HOME_LINE, coverageId.CONTENTS_AWAY);
    const prevContentsAway = prevHomCov.find((cov) => cov.publicID === coverageId.CONTENTS_AWAY);
    const isContentsAwayAdded = !!contentsAway && !prevContentsAway;

    const docsConfig = [
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS,
            getVisibility: () => {
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_TIER_1,
            getVisibility: () => {
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_TIER_2,
            getVisibility: () => {
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_TIER_3,
            getVisibility: () => {
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS_CONTENTS_TIER_1,
            getVisibility: () => {
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS_CONTENTS_TIER_2,
            getVisibility: () => {
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_BUILDINGS_CONTENTS_TIER_3,
            getVisibility: () => {
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_AWAY_TIER_1,
            getVisibility: () => {
                const isTierOne = getIsTierLevel(tierLevel.ONE);
                return isContentsAwayAdded && isTierOne;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CONTENTS_AWAY_TIER_2,
            getVisibility: () => {
                const isTierTwo = getIsTierLevel(tierLevel.TWO);
                return isContentsAwayAdded && isTierTwo;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_LEX,
            getVisibility: () => {
                return lexStatus.newValue === levelOfCovers.STANDARD && isLexCovChanged;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_LEX_EXTRA,
            getVisibility: () => {
                if (!isBespoke) {
                    return lexStatus.newValue === levelOfCovers.EXTRA && isLexCovChanged;
                }
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_LEX_EXTRA_BESPOKE,
            getVisibility: () => {
                if (isBespoke) {
                    return lexStatus.newValue === levelOfCovers.EXTRA && isLexCovChanged;
                }
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_HOME_EMERGENCY,
            getVisibility: () => {
                const homeEmergencyCovStatus =
                additionalCovers.find((status) => status.coverageId === coverageId.HOME_EMERGENCY);
                const isHomCovAdded =
                homeEmergencyCovStatus.newValue && !homeEmergencyCovStatus.oldValue;
                return !!isHomCovAdded && !isBespoke;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CYCLING_PROTECTION,
            getVisibility: () => {
                if (!isBespoke) {
                    const cycleStatus =
                    additionalCovers.find((stat) => stat.coverageId === coverageId.PEDAL_CYCLE_EXT);
                    const isCycleAdded =
                        cycleStatus.newValue && !cycleStatus.oldValue;
                    return isCycleAdded;
                }
                return false;
            }
        },
        {
            cmsDocId: cmsDocId.IPID_CYCLING_PROTECTION_BESPOKE,
            getVisibility: () => {
                if (isBespoke) {
                    const cycleStatus =
                        additionalCovers
                            .find((stat) => stat.coverageId === coverageId.PEDAL_CYCLE_BESPOKE);
                    const isCycleAdded =
                        cycleStatus.newValue && !cycleStatus.oldValue;
                    return isCycleAdded;
                }
                return false;
            }
        }
    ];

    const getDocumentsToDisplay = async () => {
        try {
            const documentsIdsToDisplay = [];

            docsConfig.forEach((docConfig) => {
                if (docConfig.getVisibility()) {
                    documentsIdsToDisplay.push(docConfig.cmsDocId);
                }
            });

            const allDocs = await getDocuments();
            return allDocs
                .filter((doc) => documentsIdsToDisplay.includes(doc.cmsDocId))
                .sort((a, b) => a.priority - b.priority);
        } catch (err) {
            throw err;
        }
    };

    return {
        getDocumentsToDisplay
    };
};

export default useCmsDocumentsRetriever;
