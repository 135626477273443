import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import {
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { NfumConfirmModal } from 'nfum-components-platform-react';
import metadata from './ValuablesAwayCard.metadata.json5';
import styles from './ValuablesAwayCard.module.scss';
import messages from './ValuablesAwayCard.messages';
import HighValueBicycles from '../HighValueBicycles/HighValueBicycles';

function ValuablesAwayCard(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        paths,
        hideLimitsReferral,
        isDisabled
    } = props;
    const { showModal } = useModal();
    const translator = useContext(TranslatorContext);
    const { highValueItemsPath } = paths;
    const TIER_LEVEL_3_CUSTOMER = 'tierLevel3';
    const highValueBicyclesPath = 'lobData.homeLine.lineCoverages.schedules.value[0]';
    const lobScheduleItems = _.get(submissionVM, `${highValueBicyclesPath}.scheduleItems`);

    const mapUnspecifiedValuablesOptionsData = useCallback((item) => {
        return {
            code: item.code,
            name: `£${item.name}`
        };
    }, []);

    const getBicycles = useCallback(() => {
        if (lobScheduleItems) {
            const updatedLobScheduleItems = lobScheduleItems
                .filter(({ itemData }) => !_.isEmpty(itemData.ArticleGeographicLimit));
            _.set(submissionVM, `${highValueBicyclesPath}.scheduleItems`, updatedLobScheduleItems);
        }
        return [];
    }, [submissionVM, lobScheduleItems]);

    const toggleContentsAwayCov = useCallback(
        async (isCoverEnabled, path) => {
            if (!isCoverEnabled) {
                const isContentsAwayFromTheHomeOnPolicy = _.get(submissionVM, 'previousLobData.value.homeLine.lineCoverages.isUnspecifiedContentsAwaySelected');
                const variant = isContentsAwayFromTheHomeOnPolicy ? 'variant4' : 'variant2';
                const { type } = await showModal(
                    <NfumConfirmModal variant={variant} />
                );
                if (type === 'CANCEL') {
                    return;
                }
            }
            getBicycles();
            _.set(submissionVM, path, isCoverEnabled);
            updateWizardData(submissionVM);
        }, [
            submissionVM,
            updateWizardData,
            getBicycles,
            showModal
        ]
    );

    const onUnspecifiedBelongingsReplCostChange = useCallback(
        (newCoverLimitCode) => {
            _.set(submissionVM, 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost', newCoverLimitCode);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const isUnspecifiedContentsAwaySelected = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.isUnspecifiedContentsAwaySelected');
    const scheduleItems = _.get(submissionVM, `${highValueItemsPath}.scheduleItems`) || [];
    const isTierLevel3Customer = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.contentsCovTier') === TIER_LEVEL_3_CUSTOMER;
    const isValuablesAwayFromHome = () => scheduleItems
        .filter(({ itemData }) => itemData.ArticleGeographicLimit.typeCodeValue === 'AwayFromHomeWithinUK').length > 0;
    const isUnspecifiedContentsAwaySwitchDisabled = isTierLevel3Customer;
    const getUnspecifiedContentsAwaySwitchLabel = () => {
        if (isUnspecifiedContentsAwaySwitchDisabled) {
            return translator(messages.coverIncluded);
        }
        return isUnspecifiedContentsAwaySelected
            ? translator(messages.removeCover)
            : translator(messages.addCover);
    };
    const getUnspecifiedName = () => {
        const path = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCostAvailableValues';
        const availableValues = _.get(submissionVM, path);
        if (availableValues) {
            const selectedPath = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost';
            const selectedValue = _.get(submissionVM, selectedPath);
            if (selectedValue) {
                return `£${availableValues.find((item) => item.code === selectedValue)?.name}`;
            }
        }
        return 'Not selected';
    };
    const getUnspecifiedCode = () => {
        const selectedPath = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost';
        const path = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCostAvailableValues';
        const availableValues = _.get(submissionVM, path);
        if (availableValues) {
            return _.get(submissionVM, selectedPath);
        }
        return 'Not selected';
    };
    const getUnspecifiedAvailableValues = () => {
        const path = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCostAvailableValues';
        const availableValues = _.get(submissionVM, path);
        if (availableValues) {
            return availableValues.map(mapUnspecifiedValuablesOptionsData);
        }
        return [];
    };

    useEffect(() => {
        const element = document.querySelector('.jut__SwitchField__inputLabel');
        if (element) {
            element.setAttribute('role', 'switch');
            element.setAttribute('aria-label', 'Switch Field Label');
            element.setAttribute('aria-checked', 'false');
        }
    }, []);

    const overrideProps = {
        valuablesAwayContentNotice: {
            visible: isValuablesAwayFromHome() && !isTierLevel3Customer
        },
        unspecifiedValuables: {
            disabled: isDisabled,
            visible: isUnspecifiedContentsAwaySelected,
            value: {
                code: getUnspecifiedCode(),
                name: getUnspecifiedName()
            },
            availableValues: getUnspecifiedAvailableValues(),
            onValueChange: onUnspecifiedBelongingsReplCostChange
        },
        enableValuablesAwaySwitch: {
            disabled: isUnspecifiedContentsAwaySwitchDisabled || isDisabled,
            onValueChange: toggleContentsAwayCov,
            label: getUnspecifiedContentsAwaySwitchLabel()
        },
        valuablesAwayDescription: {
            value: metadata.valuablesAwayDescription
        },
        highValueBicycles: {
            visible: isUnspecifiedContentsAwaySelected
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            highValueBicycles: (highValueBicycleProps) => (
                <HighValueBicycles
                    {...highValueBicycleProps}
                    wizardData={submissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={hideLimitsReferral}
                    isDisabled={isDisabled}
                />
            ),
        }
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ValuablesAwayCard.propTypes = wizardProps;
export default ValuablesAwayCard;
