import { setComponentMapOverrides } from '@jutro/uiconfig';
import CyclingProtectionCoverage from './components/CyclingProtection/CyclingProtectionCoverage';
import PersonalLegalExpenses from './components/PersonalLegalExpenses/PersonalLegalExpenses';
import ValuablesCard from './components/ValuablesCard/ValuablesCard';
import FineArtsCard from './components/FineArtsCard/FineArtsCard';
import BespokeValuables from './components/BespokeValuables/BespokeValuables';
import BespokeValuablesModel from './components/BespokeValuablesModel/BespokeValuablesModel';
import FineArtsItem from './components/FineArtsCard/FineArtsItem/FineArtsItem';

setComponentMapOverrides(
    {
        CyclingProtectionCoverage: { component: 'CyclingProtectionCoverage' },
        PersonalLegalExpenses: { component: 'PersonalLegalExpenses' },
        ValuablesCard: { component: 'ValuablesCard' },
        BespokeValuables: { component: 'BespokeValuables' },
        FineArtsCard: { component: 'FineArtsCard' },
        BespokeValuablesModel: { component: 'BespokeValuablesModel' },
        FineArtsItem: { component: 'FineArtsItem' }
    },
    {
        CyclingProtectionCoverage,
        PersonalLegalExpenses,
        ValuablesCard,
        BespokeValuables,
        FineArtsCard,
        BespokeValuablesModel,
        FineArtsItem
    }
);

export { default as NGHContext } from './NGHContext';
export { default as useTagManager } from './hooks/useTagManager';
export { default as usePeriodsDiff } from './hooks/usePeriodsDiff';
export { default as useCleanPayload } from './hooks/useCleanPayload';
export { default as useCmsDocumentsRetriever } from './hooks/useCmsDocumentsRetriever';
export { default as PolicyChangeRetrieve } from './components/PolicyChangeRetrieve/PolicyChangeRetrieve';
export { default as PolicyChangeRequestRetrieve } from './components/PolicyChangeRequestRetrieve/PolicyChangeRequestRetrieve';
