import { defineMessages } from 'react-intl';

export default defineMessages({
    specialConditionsTitle: {
        id: 'endorsement.ngh.views.policychange-summary.special-conditions.specialConditionsTitle',
        defaultMessage: 'Special conditions'
    },
    specialConditionsApplyText: {
        id: 'endorsement.ngh.views.policychange-summary.special-conditions.newSpecialConditionsApplyText',
        defaultMessage: 'New Special Conditions apply as a result of this change. Please read the details below before proceeding. If you don\'t comply with them it may affect your cover.'
    },
    expand: {
        id: 'endorsement.ngh.views.policychange-summary.special-conditions.expand',
        defaultMessage: 'Expand'
    },
    collapse: {
        id: 'endorsement.ngh.views.policychange-summary.special-conditions.collapse',
        defaultMessage: 'Collapse'
    }
});
