import React, { useCallback, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './NfumTermsAndConditions.metadata.json5';
import styles from './NfumTermsAndConditions.module.scss';
import messages from './NfumTermsAndConditions.messages';

function NfumTermsAndConditions(props) {
    const {
        isAutoRenewal
    } = props;
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const onClickOfMutualityAndCharitable = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/legal-information/mutual-and-charitable-assignment/';
        pushLinkClickInfo(translator(messages.mutualityAndCharitable), url);
    }, [pushLinkClickInfo, translator]);

    const onClickPrivacyPolicy = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/privacy/';
        pushLinkClickInfo(translator(messages.privacyPolicy), url);
    }, [pushLinkClickInfo, translator]);

    const getMutualityAndCharitableContent = () => (
        <p>
            <span>{translator(messages.termsPoint2)}</span>
            <a href="https://www.nfumutual.co.uk/legal-information/mutual-and-charitable-assignment/" target="_blank" rel="noreferrer" className={styles.link} onClick={() => onClickOfMutualityAndCharitable()}>{translator(messages.mutualityAndCharitable)}</a>
            <span>;</span>
        </p>
    );

    const getPrivacyPolicyContent = () => (
        <p>
            <span>{translator(messages.forMoreInformation)}</span>
            <a href="https://www.nfumutual.co.uk/privacy/" target="_blank" rel="noreferrer" className={styles.link} onClick={() => onClickPrivacyPolicy()}>{translator(messages.privacyPolicy)}</a>
            <span>.</span>
        </p>
    );

    const overrideProps = {
        mutualityAndCharitableContentWithLink: {
            content: getMutualityAndCharitableContent()
        },
        byClickingText: {
            content: isAutoRenewal
                ? translator(messages.byClickingTextAutoRenewal)
                : translator(messages.byClickingText)
        },
        privacyPolicyWithLink: {
            content: getPrivacyPolicyContent()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            overrideProps={overrideProps}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default NfumTermsAndConditions;
