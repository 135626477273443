export default [
    {
        stepId: 0,
        title: {
            id: 'portal.wizardStep.policyChange.Make changes to your home insurance policy',
            defaultMessage: 'Make changes to your insurance policy',
        },
        isTransactionNumberHidden: true,
        additionalInfo: {
            id: 'portal.wizardStep.policyChange.summaryPageAdditionalInfo',
            defaultMessage: 'The premiums shown below are the costs for the remaining policy period.  If you pay monthly, this is the monthly amount for each option.  You can see the annual cost breakdown on the next page.'
        }
    },
    {
        stepId: 1,
        title: {
            id: 'portal.wizardStep.policyChange.reviewYourChanges',
            defaultMessage: 'Review your changes',
        },
        isTransactionNumberHidden: true
    },
    {
        stepId: 2,
        title: {
            id: 'portal.wizardStep.policyChange.Payment',
            defaultMessage: 'Payment',
        },
        isTransactionNumberHidden: true
    },
    {
        stepId: 3,
        title: {
            id: 'portal.wizardStep.policyChange.Your changes have been submitted',
            defaultMessage: 'Your changes have been submitted',
        },
        isTransactionNumberHidden: true,
        isPolicyNumberHidden: true
    }
];
