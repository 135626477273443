/* eslint-disable jsx-a11y/anchor-is-valid */
import { TranslatorContext } from '@jutro/locale';
import React, { useContext } from 'react';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { Link } from '@jutro/components';
import styles from './Footer.module.scss';
import messages from './Footer.messages';

const urls = {
    privacyPolicy: 'https://www.nfumutual.co.uk/legal-information/privacy-policy/',
    legalPolicy: 'https://www.nfumutual.co.uk/legal-information/legal-policy/',
    cookiePolicy: 'https://www.nfumutual.co.uk/legal-information/cookie-policy/',
    termsAndConditions: 'https://www.nfumutual.co.uk/legal-information/our-terms-and-conditions/',
    slaveryEnslavement: 'https://www.nfumutual.co.uk/legal-information/slavery-and-human-trafficking-statement/',
    islandsInsurance: 'https://www.islands.insure/',
    withProfits: ' https://www.nfumutual.co.uk/investments/with-profits-funds/',
    accesibility: 'https://www.nfumutual.co.uk/legal-information/accessibility/',
    complaints: 'https://www.nfumutual.co.uk/complaints/',
    contactUs: 'https://www.nfumutual.co.uk/contact-us/',
    mediaCenter: 'https://www.nfumutual.co.uk/media-centre/',
    careers: 'https://www.nfumutual.co.uk/careers/',
};

const Footer = () => {
    const translator = useContext(TranslatorContext);

    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const onLinkClicked = () => {
        const url = 'https://www.nfumutual.co.uk/insurance/home-insurance/';
        pushLinkClickInfo(translator(messages.nfum), url);
        window.open(url, '_blank');
    };

    const onFooterLinkClicked = (name) => {
        const url = urls[name];
        pushLinkClickInfo(name, url);
        window.open(url, '_blank');
    };

    return (
        <div className={styles.footerWrapper}>
            <footer className={styles.footer}>
                <div className={styles.footerInfoWrapper}>
                    <div className={styles.footerInfo}>
                        <div className={styles.footerInfoGrid}>
                            <div className={styles.footerInfoMessage}>
                                <strong>{ translator(messages.nfum) }</strong>
                                { translator(messages.footerInfoContent) }
                            </div>
                            {/* NOSONAR: TODO: links to be provided later */}
                            <div className={styles.footerInfoLinks}>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('privacyPolicy')} className={styles.link}>Privacy Policy</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('legalPolicy')} className={styles.link}>Legal Policy</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('cookiePolicy')} className={styles.link}>Cookie Policy</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('termsAndConditions')} className={styles.link}>Our terms and conditions</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('slaveryEnslavement')} className={styles.link}>Modern slavery statement</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('islandsInsurance')} className={styles.link}>Islands Insurance</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('withProfits')} className={styles.link}>With-Profits</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('accesibility')} className={styles.link}>Accessibility</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('complaints')} className={styles.link}>Complaints</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('contactUs')} className={styles.link}>Contact us</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('mediaCenter')} className={styles.link}>Media Centre</Link>
                                </div>
                                <div>
                                    <Link role="link" onClick={() => onFooterLinkClicked('careers')} className={styles.link}>Careers</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerBarWrapper}>
                    <div className={styles.footerBar}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link onClick={() => onLinkClicked()} target="_blank" rel="noreferrer" aria-label="NFU Mutual home page" className={styles.footerBarImageAnchor} alt="NFU Mutual Home Page Link">
                            <div className={styles.footerBarImage} />
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    );
};

Footer.defaultProps = {
};
Footer.propTypes = {
};
export default Footer;
