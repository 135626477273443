import { defineMessages } from 'react-intl';

export default defineMessages({
    totalToPay: {
        id: 'renewal.ngh.views.renewal-payment.totalToPay',
        defaultMessage: 'Total to pay now'
    },
    paymentDetails: {
        id: 'renewal.ngh.views.renewal-payment.paymentDetails',
        defaultMessage: 'Payment details'
    },
    paymentUnavailable: {
        id: 'renewal.ngh.views.renewal-payment.paymentUnavailable',
        defaultMessage: 'We’re sorry, online payment is temporarily unavailable'
    },
    paymentUnavailableMessageText: {
        id: 'renewal.ngh.views.renewal-payment.paymentUnavailableMessageText',
        defaultMessage: 'We’re sorry, we’re experiencing some technical difficulties so we’re not able to take online payments at the moment.\nYou can try again later, or get in touch using the details below and we’ll be happy to help.​'
    },
    savedProgress: {
        id: 'renewal.ngh.views.renewal-payment.savedProgress',
        defaultMessage: 'We have saved your progress and will be able to pick up with you where you left off. Alternatively you can'
    },
    cancelTransaction: {
        id: 'renewal.ngh.views.renewal-payment.cancelTransaction',
        defaultMessage: 'Cancel transaction'
    },
    updatePaymentInformation: {
        id: 'renewal.ngh.views.renewal-payment.updatePaymentInformation',
        defaultMessage: 'Update payment information'
    },
    paymentPaid: {
        id: 'renewal.ngh.views.renewal-payment.paymentPaid',
        defaultMessage: 'Your renewal payment has been made'
    },
    paymentPaidMessageText: {
        id: 'renewal.ngh.views.renewal-payment.paymentPaidMessageText',
        defaultMessage: 'You have made your payment and your policy has been renewed.\nIf you have an online account with us, you’ll see this policy in the renewals section of your dashboard until its renewal date, when you’ll see it displayed as an active policy.​'
    },
    paymentGatewayIframe: {
        id: 'renewal.ngh.views.renewal-payment.paymentGatewayIframe',
        defaultMessage: 'Payment gateway iframe​'
    },
});
