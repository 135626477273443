import React, {
    useState, useEffect, useCallback, useContext
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication, withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import {
    useErrorHandler,
    RENEWAL_STEPS,
    ERROR_STEPS,
    VALIDATION_ERROR_CODE
} from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import { useModal } from '@jutro/components';
import { NfumLoader, NfumInactiveModal } from 'nfum-components-platform-react';
import metadata from './RenewalPaymentPage.metadata.json5';
import styles from './RenewalPaymentPage.module.scss';
import messages from './RenewalPaymentPage.messages';
import commonMessages from '../../NGHRenewal.messages';
import useTagManager from '../../hooks/useTagManager';

function RenewalPaymentPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        goNext,
        jumpTo,
        steps
    } = props;
    const { RenewalService } = useDependencies('RenewalService');
    const { returnToDashboard } = useWizardActions();
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [isPaymentGatewayError, setIsPaymentGatewayError] = useState(null);
    const { handleError, getErrorCode } = useErrorHandler();
    const [isUnsuccessfulPaymentMessageVisible,
        setIsUnsuccessfulPaymentMessageVisible] = useState(false);
    const [isAlreadyPaid, setIsAlreadyPaid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushRelativeLinkClickInfo
    } = useTagManager();
    const {
        showModal
    } = useModal();
    const CHOICE_CONTINUE = 'choice-continue';
    const CHOICE_TIMEOUT = 'choice-timeout';
    const PAYMENT_GATEWAY_SESSION_TIMEOUT_MIN = 13;
    const [isPaymentGatewaySessionActive, setPaymentGatewaySessionActive] = useState();
    const [paymentGatewayTimeoutId, setPaymentGatewayTimeoutId] = useState();

    const producerDesc = _.get(renewalVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(renewalVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(renewalVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(renewalVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const isPaymentMockEnabled = _.get(renewalVM.value, 'digitalPaymentMock_NFUM', false);

    useEffect(() => {
        pushFormStepInfo(renewalVM, RENEWAL_STEPS.PAYMENT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateTo = useCallback((pagePath) => {
        const pageIndex = _.findIndex(
            steps,
            ({ path }) => path === pagePath
        );
        jumpTo(pageIndex);
    }, [jumpTo, steps]);

    const navigateToSummary = () => {
        const url = '/renewal-summary';
        pushRelativeLinkClickInfo(translator(messages.cancelTransaction), url);
        navigateTo(url);
    };

    useEffect(() => {
        window.addEventListener('message', async (event) => {
            if (process.env.REACT_APP_NFUM_PAYMENT_STATUS_ORIGIN
                && event.origin !== process.env.REACT_APP_NFUM_PAYMENT_STATUS_ORIGIN) {
                handleError(`Payment status origin different from configured host: ${event.origin}`);
                return;
            }
            const paymentStatus = await RenewalService.checkPaymentStatus_NFUM(
                renewalVM.value.jobID,
                authHeader
            );
            if (paymentStatus === 'CAPTURED') {
                renewalVM.isPaymentSucceed = true;
                goNext();
            } else if (paymentStatus === 'DECLINED') {
                setIsUnsuccessfulPaymentMessageVisible(true);
            } else if (paymentStatus === 'CANCELLED') {
                navigateToSummary();
            } else {
                pushFormStepErrorInfo(
                    renewalVM,
                    RENEWAL_STEPS.PAYMENT,
                    null,
                    ERROR_STEPS.PAYMENT_ERROR
                );
                setIsUnsuccessfulPaymentMessageVisible(true);
            }
        }, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closePaymentGatewaySession = useCallback(() => {
        setPaymentGatewaySessionActive(false);
    }, [setPaymentGatewaySessionActive]);

    const fetchRedirectUrl = useCallback(async () => {
        try {
            setIsLoading(true);
            const result = await RenewalService.getRedirectURL(
                [renewalVM.value.jobID],
                authHeader
            );
            if (!_.isEmpty(result)) {
                setPaymentGatewaySessionActive(true);
                const timeoutId = setTimeout(
                    closePaymentGatewaySession,
                    PAYMENT_GATEWAY_SESSION_TIMEOUT_MIN * 60 * 1000
                );
                setPaymentGatewayTimeoutId(timeoutId);
                setRedirectUrl(result);
                setIsPaymentGatewayError(false);
                setIsLoading(false);
            } else {
                pushFormStepErrorInfo(
                    renewalVM,
                    RENEWAL_STEPS.PAYMENT,
                    null,
                    ERROR_STEPS.PAYMENT_ERROR
                );
                setIsPaymentGatewayError(true);
                setIsLoading(false);
            }
        } catch (err) {
            const errorCode = getErrorCode(err);
            if (errorCode === VALIDATION_ERROR_CODE) {
                setIsAlreadyPaid(true);
                setIsPaymentGatewayError(true);
            } else {
                pushFormStepErrorInfo(
                    renewalVM,
                    RENEWAL_STEPS.PAYMENT,
                    null,
                    ERROR_STEPS.PAYMENT_ERROR
                );
                setIsPaymentGatewayError(true);
            }
            setIsLoading(false);
        }
    }, [
        RenewalService,
        renewalVM,
        authHeader,
        pushFormStepErrorInfo,
        getErrorCode,
        closePaymentGatewaySession
    ]);

    useEffect(() => {
        if (isPaymentGatewaySessionActive === false) {
            showModal(
                <NfumInactiveModal
                    isOpen
                    logoutConfirmationInterval={1}
                />
            ).then((choice) => {
                if (choice.type === CHOICE_CONTINUE) {
                    clearTimeout(paymentGatewayTimeoutId);
                    fetchRedirectUrl();
                } else if (choice.type === CHOICE_TIMEOUT) {
                    clearTimeout(paymentGatewayTimeoutId);
                    const url = '/renewal-summary';
                    const indexOfQuoteSummary = _.findIndex(
                        steps,
                        ({ path }) => path === url
                    );
                    jumpTo(indexOfQuoteSummary);
                }
            }, _.noop);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentGatewaySessionActive]);

    useEffect(() => {
        if (_.get(renewalVM.value, 'baseData.paymentMethod_NFUM') === 'cash'
            && _.get(renewalVM.value, 'transactionCost.amount') > 0) {
            // eslint-disable-next-line no-unused-expressions
            isPaymentMockEnabled ? setIsPaymentGatewayError(false) : fetchRedirectUrl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPaymentStatus = () => {
        if (isAlreadyPaid) {
            return {
                errorMessage: translator(messages.paymentPaid),
                workingOnQuoteText: translator(messages.paymentPaidMessageText),
            };
        }
        return {
            errorMessage: translator(messages.paymentUnavailable),
            workingOnQuoteText: translator(messages.paymentUnavailableMessageText),
        };
    };

    const onMockPay = () => {
        renewalVM.isPaymentSucceed = true;
        goNext();
    };

    const renderNfumIframe = useCallback(() => {
        return (
            <iframe
                src={!isPaymentMockEnabled ? redirectUrl : ''}
                className={styles.nfumIframe}
                title={translator(messages.paymentGatewayIframe)}
                aria-label={translator(messages.paymentGatewayIframe)}
            />
        );
    }, [isPaymentMockEnabled, redirectUrl, translator]);

    if (isLoading) {
        return (
            <div>
                <NfumLoader />
            </div>
        );
    }

    const overrideProps = {
        paymentContainer: {
            visible: isPaymentGatewayError !== true
                && isUnsuccessfulPaymentMessageVisible !== true
        },
        paymentPremiumBox: {
            isIncreaseDecreaseMessage: false,
            titleText: translator(messages.totalToPay),
            premiumText: `£${renewalVM.value.transactionCost.amount}`
        },
        paymentPagePayApp: {
            content: renderNfumIframe(),
            visible: !isPaymentMockEnabled
        },
        paymentPagePayAppMock: {
            visible: isPaymentMockEnabled,
            onClick: onMockPay
        },
        paymentGatewayErrorInfoContainer: {
            visible: isPaymentGatewayError === true
        },
        paymentGatewayErrorInfo: {
            errorMessage: getPaymentStatus().errorMessage,
            showWorkingOnQuoteText: false,
            showSavedQuoteText: true,
            showContactUsTextWithAmendments: false,
            workingOnQuoteText: getPaymentStatus().workingOnQuoteText,
            savedProgressText: translator(messages.savedProgress),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        unsuccessfulPaymentMessage: {
            visible: isUnsuccessfulPaymentMessageVisible === true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <WizardPage
            showCancel={isUnsuccessfulPaymentMessageVisible}
            onCancel={() => {
                const url = '/renewal-summary';
                pushRelativeLinkClickInfo(translator(messages.cancelTransaction), url);
                navigateTo(url);
            }}
            cancelLabel={messages.cancelTransaction}
            showPrevious={false}
            onNext={() => {
                const url = '/renewal-summary';
                pushRelativeLinkClickInfo(translator(messages.updatePaymentInformation), url);
                navigateTo(url);
            }}
            showNext={isUnsuccessfulPaymentMessageVisible}
            nextLabel={messages.updatePaymentInformation}
            showJumpBack={!isUnsuccessfulPaymentMessageVisible}
            jumpBackLabel={commonMessages.returnToDashboard}
            onJumpBack={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

RenewalPaymentPage.propTypes = wizardProps;
export default withAuthenticationContext(RenewalPaymentPage);
