export const FORM_REQUEST_TYPE = {
    quote: 'digital quote',
    renewal: 'renewal',
    policyChange: 'mid term adjustment',
    quoteRetrieval: 'digital quote'
};

export const GTM_EVENTS = {
    formStep: 'formStep',
    formConfirmation: 'formConfirmation',
    accordionOpen: 'accordion_interaction',
    linkClick: 'link_click',
    fileDownload: 'file_download'
};

export const GTM_PARAMS = {
    event: 'event',
    formCustomerType: 'formCustomerType',
    formInsuranceLine: 'formInsuranceLine',
    formProductLine: 'formProductLine',
    formRequestType: 'formRequestType',
    formStepNumber: 'formStepNumber',
    formStepDescription: 'formStepDescription',
    formReference: 'formReference',
    accordionTitlePosition: 'accordionPosition',
    linkText: 'link_text',
    linkUrl: 'link_url',
    fileName: 'file_name'
};

export const FORM_CUSTOMER_TYPES = {
    new: 'new',
    existing: 'existing',
    undefined: 'undefined'
};

export const FORM_INSURANCE_LINE = {
    personalLines: 'personal_lines'
};

export const FORM_PRODUCT_LINE = {
    home: 'home'
};

export const ERROR_STEPS = {
    PAYMENT_ERROR: {
        description: 'Payment Error'
    },
    UW_ERROR: {
        description: 'UW Error'
    },
    CONTACT_US_ERROR: {
        description: 'Contact Us Error'
    },
    TECHNICAL_ERROR: {
        description: 'Technical Error'
    }
};

export const BESPOKE_COVERAGES_MTA_STEPS = {
    MTA_DETAILS: {
        description: 'Policy Change Details',
        number: '1'
    },
    MTA_SUMMARY: {
        description: 'Policy Change Summary',
        number: '2'
    },
    SUCCESS: {
        description: 'Success',
        number: '3'
    }
};

export const BESPOKE_MTA_STEPS = {
    MTA_DETAILS: {
        description: 'Policy Change Details',
        number: '1'
    },
    MTA_SUMMARY: {
        description: 'Policy Change Summary',
        number: '2'
    },
    PAYMENT: {
        description: 'Payment',
        number: '3'
    },
    SUCCESS: {
        description: 'Success',
        number: '4'
    }
};

export const MTA_STEPS = {
    MTA_DETAILS: {
        description: 'Policy Change Details',
        number: '1'
    },
    MTA_COVERAGES: {
        description: 'Policy Change Coverage',
        number: '2'
    },
    MTA_SUMMARY: {
        description: 'Policy Change Summary',
        number: '3'
    },
    PAYMENT: {
        description: 'Payment',
        number: '4'
    },
    SUCCESS: {
        description: 'Success',
        number: '5'
    }
};

export const RENEWAL_STEPS = {
    SUMMARY: {
        description: 'Renewal Summary',
        number: '1'
    },
    PAYMENT: {
        description: 'Renewal Payment',
        number: '2'
    },
    SUCCESS: {
        description: 'Renewal Success',
        number: '3'
    }
};
