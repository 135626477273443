import { defineMessages } from 'react-intl';

export default defineMessages({
    AccidentalDamageCover: {
        id: 'endorsement.ngh.views.policychange-coverages.AccidentalDamageCover',
        defaultMessage: 'Accidental Damage'
    },
    ContentsAccidentalCover: {
        id: 'endorsement.ngh.views.policychange-coverages.ContentsAccidentalCover',
        defaultMessage: 'Contents accidental damage'
    },
    ContentsAccidentalCoverText: {
        id: 'endorsement.ngh.views.policychange-coverages.ContentsAccidentalCoverText',
        defaultMessage: 'Cover for damage to your contents that occurs suddenly as the result of an accident, such as spilling wine on your sofa. '
    },
    seeAllCoverBenefits: {
        id: 'endorsement.common.components.policy-change-coverages.seeAllCoverBenefits',
        defaultMessage: 'See all cover benefits.'
    },
    BuildingsAccidentalCover: {
        id: 'endorsement.ngh.views.policychange-coverages.BuildingsAccidentalCover',
        defaultMessage: 'Buildings accidental damage'
    },
    BuildingsAccidentalCoverText: {
        id: 'endorsement.ngh.views.policychange-coverages.BuildingsAccidentalCoverText',
        defaultMessage: 'Cover for damage to your buildings that occur suddenly as a the result of an accident, such as hitting a pipe when hanging a picture frame. '
    },
    LegalExpensesCover: {
        id: 'endorsement.ngh.views.policychange-coverages.LegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses'
    },
    StandardLegalExpensesCover: {
        id: 'endorsement.ngh.views.policychange-coverages.StandardLegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses'
    },
    StandardLegalExpensesCoverText: {
        id: 'endorsement.ngh.views.policychange-coverages.StandardLegalExpensesCoverText',
        defaultMessage: 'Cover for your legal proceedings that relate to your home, employment death or personal injury up to £100,000. '
    },
    EnhancedLegalExpensesCover: {
        id: 'endorsement.ngh.views.policychange-coverages.EnhancedLegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    EnhancedLegalExpensesCoverText: {
        id: 'endorsement.ngh.views.policychange-coverages.EnhancedLegalExpensesCoverText',
        defaultMessage: 'Cover for an even wider range of personal and family related legal disputes up to £100,000. '
    },
    learnMore: {
        id: 'endorsement.ngh.views.policychange-coverages.learnMore',
        defaultMessage: 'Learn More.'
    },
    BicycleExtensionCover: {
        id: 'endorsement.ngh.views.policychange-coverages.BicycleExtensionCover',
        defaultMessage: 'Cycling Protection'
    },
    BicycleExtensionCoverText: {
        id: 'endorsement.ngh.views.policychange-coverages.BicycleExtensionCoverText',
        defaultMessage: 'Extended bike cover that provides protection for cycling accessories, amateur competitions and more. '
    },
    protectingYourValuables: {
        id: 'endorsement.ngh.views.policychange-coverages.protectingYourValuables',
        defaultMessage: 'Protecting your valuables'
    },
    protectingYourValuablesText: {
        id: 'endorsement.ngh.views.policychange-coverages.protectingYourValuablesText',
        defaultMessage: 'Do you have a safe fitted?'
    },
    protectingYourValuablesDisclaimer: {
        id: 'endorsement.ngh.views.policychange-coverages.protectingYourValuablesDisclaimer',
        defaultMessage: 'In order to protect your valuables, your safe must have a minimum cash rating of '
    },
    HomeEmergencyCover: {
        id: 'endorsement.ngh.views.policychange-coverages.HomeEmergencyCover',
        defaultMessage: 'Home Emergency'
    },
    HomeEmergencyCoverText: {
        id: 'endorsement.ngh.views.policychange-coverages.HomeEmergencyCoverText',
        defaultMessage: 'Cover for the cost of calling out a tradesperson due to sudden incident, such as your boiler breaking down or your pipes bursting. Our Home Emergency cover includes protection for a wide range of home emergencies. '
    },
    LegalExpensesCoverText: {
        id: 'endorsement.ngh.views.policychange-coverages.LegalExpensesCoverText',
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed leo viverra erat accumsan mattis sed at leo. Phasellus non mauris ut nisi venenatis consequat '
    },
    TechnicalProblems: {
        id: 'endorsement.ngh.views.policychange-coverages.technicalProblems',
        defaultMessage: 'Sorry, we are experiencing some technical problems at the moment.'
    },
    midTermErrorOnline: {
        id: 'endorsement.ngh.views.policychange-coverages.midTermErrorOnline',
        defaultMessage: 'Sorry, you can not complete this mid-term change online'
    },
    ProgressSaved: {
        id: 'endorsement.ngh.views.policychange-coverages.ProgressSaved',
        defaultMessage: 'We have saved your progress and will be able to pick up with you where you left off. Alternatively you can '
    },
    homeEmergencyCoverTextClaimPeriod: {
        id: 'endorsement.ngh.views.policychange-coverages.homeEmergencyCoverTextClaimPeriod',
        defaultMessage: 'We will not pay for any claims under Home Emergency which occur within the first 7 days of the start of the period of cover, as shown on your schedule.'
    },
    saveForLater: {
        id: 'endorsement.ngh.views.policychange-coverages.saveForLater',
        defaultMessage: 'Save for later'
    },
    saveForLaterModalTitle: {
        id: 'endorsement.ngh.views.policychange-coverages.saveForLaterModalTitle',
        defaultMessage: 'Save for later'
    },
    saveForLaterConfirm: {
        id: 'endorsement.ngh.views.policychange-coverages.saveForLaterConfirm',
        defaultMessage: 'Save progress'
    },
    saveForLaterCancel: {
        id: 'endorsement.ngh.views.policychange-coverages.saveForLaterCancel',
        defaultMessage: 'Cancel'
    },
    saveForLaterIntroduction: {
        id: 'endorsement.ngh.views.policychange-coverages.saveForLaterIntroduction',
        defaultMessage: 'We will save your changes and send an email to containing a link to continue this quote:'
    },
    saveForLaterValidDate: {
        id: 'endorsement.ngh.views.policychange-coverages.saveForLaterValidDate',
        defaultMessage: 'This quote is valid until {validDate}.'
    },
    policyLegalExpenseDesc: {
        id: 'endorsement.ngh.views.policychange-coverages.personalLegalExpenseDescription',
        defaultMessage: 'Cover is administered by DAS Legal Expenses Insurance Company Limited'
    },
    homeEmergencyCoverTextDesc: {
        id: 'endorsement.ngh.views.policychange-coverages.homeEmergencyCoverTextDesc',
        defaultMessage: 'Cover is administered by ARAG Plc'
    },
    ratingServiceTimedOut: {
        id: 'endorsement.ngh.views.policychange-coverages.ratingServiceTimedOut',
        defaultMessage: 'Rating service has timed out'
    },
    removeCover: {
        id: 'endorsement.ngh.views.policychange-coverages.removeCover',
        defaultMessage: 'Remove cover'
    },
    addCover: {
        id: 'endorsement.ngh.views.policychange-coverages.addCover',
        defaultMessage: 'Add cover'
    },
    perMonth: {
        id: 'endorsement.ngh.views.policychange-coverages.perMonth',
        defaultMessage: 'Per Month'
    },
    perYear: {
        id: 'endorsement.ngh.views.policychange-coverages.perYear',
        defaultMessage: 'Per Year'
    },
    includedAsStandard: {
        id: 'endorsement.ngh.views.policychange-coverages.includedAsStandard',
        defaultMessage: 'Included as standard'
    },
    additionalCoverText1: {
    id: 'quoteandbind.ngh.views.quote.additionalCoverText1',
    defaultMessage: 'Additional excess of £100 applies. This will be in addition to any voluntary excess you have.'
    }
});
