import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { NfumLoader } from 'nfum-components-platform-react';
import { Wizard } from 'gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { messages as platformMessages } from 'gw-platform-translations';
import { ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { useErrorHandler, AppContext } from 'nfum-portals-utils-react';
import { NGHContext } from 'nfum-capability-policychange-common-react';
import { useMtaCms } from 'nfum-cms-react';
import wizardConfig from './config/ngh-wizard-config.json5';
import 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';

const setPeriodStartDate = (date) => {
    const currentDate = new Date(date);
    const effectiveDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    return effectiveDate;
};

function NGHPolicyChangeWizard(props) { /* NOSONAR: pure declarative usage  */
    const { steps, title } = wizardConfig;
    const { showConfirm } = useModal();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { location, history, authHeader } = props;
    const { handleError } = useErrorHandler();
    const [isContentsReplCostsConfirmed, setIsContentsReplCostsConfirmed] = useState(undefined);
    const [isNavigationDisabled, setIsNavigationDisabled] = useState(false);
    const { marketingInfo, setMarketingInfo, setJourney } = useContext(AppContext);
    const { getCmsContentForMta } = useMtaCms(marketingInfo, setMarketingInfo);
    const productCodes = Object.freeze({
        BESPOKE: 'bespoke',
        NGH: 'ngh',
        COMMON: 'common'
    });
    const journeys = Object.freeze({
        RENEWAL: 'renewal',
        MTA: 'mta',
        COMMON: 'common'
    });

    const getAndSaveCmsData = useCallback((modelData) => {
        const isBespokeJourney = modelData
            .isBespokeHomeInsurance_NFUM;
        getCmsContentForMta(isBespokeJourney
            ? productCodes.BESPOKE
            : productCodes.NGH);
    }, [getCmsContentForMta, productCodes]);

    useEffect(
        () => {
            setJourney(journeys.MTA);
            if (!location.state) {
                history.push('/');
                return;
            }
            const { state: { policyNumber } } = location;
            if (!policyNumber) {
                return;
            }
            const errorModalBox = (errorMessage) => {
                showConfirm(errorMessage).then((results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    setIsLoading(false);
                    return history.push(`/service-unavailable?jobid=${policyNumber}`);
                }, _.noop);
            };
            EndorsementService.getAvailablePolicy(policyNumber, authHeader)
                .then((response) => {
                    if (appConfig.persona === 'policyholder') {
                        /* Start- Below code has been modified for TimeTravel */
                        const effectivePolicyDate = response.systemDate_NFUM
                            ? new Date(response.systemDate_NFUM)
                            : new Date();
                        /** End Time Travel Fix */
                        const date = setPeriodStartDate(effectivePolicyDate);
                        EndorsementService.loadEndorsementWithEffectiveDate(
                            [response.policyNumber, date],
                            authHeader
                        )
                            .then((policyChangeInfo) => {
                                _.unset(policyChangeInfo, 'previousLobData');
                                _.unset(policyChangeInfo, 'lobData.homeLine.lineCoverages');
                                _.unset(policyChangeInfo, 'costInfos_NFUM');
                                EndorsementService.createOrSave(
                                    [policyChangeInfo],
                                    authHeader
                                ).then((newPolicyChangeData) => {
                                    getAndSaveCmsData(newPolicyChangeData);
                                    const policyChangeVM = viewModelService.create(
                                        newPolicyChangeData,
                                        'pc',
                                        'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                                    );
                                    setInitialSubmission(policyChangeVM);
                                    setIsLoading(false);
                                }).catch((error) => {
                                    handleError(error, policyNumber);
                                });
                            }).catch((error) => {
                                handleError(error, policyNumber);
                            });
                    } else {
                        EndorsementService.loadEndorsement(response.policyNumber, authHeader)
                            .then((responseData) => {
                                getAndSaveCmsData(responseData);
                                const policyChangeVM = viewModelService.create(
                                    responseData,
                                    'pc',
                                    'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                                );
                                setInitialSubmission(policyChangeVM);
                                setIsLoading(false);
                            }).catch(() => {
                                errorModalBox({
                                    title: commonMessages.unableToLoadDraftPolicy,
                                    message: commonMessages.somethingWentWrong,
                                });
                            }).catch(() => {
                                errorModalBox({
                                    title: commonMessages.unableToStartPolicy,
                                    message: commonMessages.anErrorOccurred,
                                    status: 'warning',
                                    icon: 'gw-error-outline'
                                });
                            });
                    }
                }).catch((error) => {
                    handleError(error, policyNumber);
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback(() => {
        return true;
    }, []);

    if (isLoading) {
        return (
            <NfumLoader loaded={!isLoading} /> /* NOSONAR: pure declarative usage */
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <NGHContext.Provider
            value={{
                isContentsReplCostsConfirmed,
                setIsContentsReplCostsConfirmed,
                isNavigationDisabled,
                setIsNavigationDisabled
            }}
        >
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={initialSubmission}
                    onCancel={handleOnCancel}
                    onPreviousModalProps={{
                        title: platformMessages.wantToJump,
                        message: platformMessages.wantToJumpMessage,
                        status: 'warning',
                        icon: 'gw-error-outline',
                        confirmButtonText: platformMessages.yes,
                        cancelButtonText: platformMessages.no
                    }}
                />
            </ErrorBoundary>
        </NGHContext.Provider>
    );
}

NGHPolicyChangeWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            policyNumber: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
};

export default withRouter(withAuthenticationContext(withViewModelService(NGHPolicyChangeWizard)));
