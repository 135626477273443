import { defineMessages } from 'react-intl';

export default defineMessages({
    addCover: {
        id: 'endorsement.Bespoke.ngh.legalExpense-coverages.addCover',
        defaultMessage: 'Add cover'
    },
    removeCover: {
        id: 'endorsement.Bespoke.ngh.views.legalExpense-coverages.addCover',
        defaultMessage: 'Remove cover'
    },
    includedAsStandard: {
        id: 'endorsement.Bespoke.ngh.policychange-coverages.includedAsStandard',
        defaultMessage: 'Included as standard'
    },
    personalLegalExpenseDescription: {
        id: 'endorsement.ngh.views.bespoke-policychange.personalLegalExpenseDescription',
        defaultMessage: 'Cover is administered by DAS Legal Expenses Insurance Company Limited'
    },
    StandardLegalExpensesCover: {
        id: 'endorsement.ngh.views.bespoke-policychange.StandardLegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses'
    },
    StandardLegalExpensesCoverText: {
        id: 'endorsement.ngh.views.bespke-policychange.StandardLegalExpensesCoverText',
        defaultMessage: 'Advice helpline and cover for legal proceedings relating to disputes to your home, employment, death, or personal injury up to £150,000.'
    },
    seeAllCoverBenefits: {
        id: 'endorsement.common.components.personal-legal-expenses.seeAllCoverBenefits',
        defaultMessage: 'See all cover benefits.'
    },
    EnhancedLegalExpensesCover: {
        id: 'endorsement.ngh.views.bespoke-policychange.EnhancedLegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    EnhancedLegalExpensesCoverText: {
        id: 'endorsement.ngh.views.bespoke-policychange.EnhancedLegalExpensesCoverText',
        defaultMessage: 'Cover for an even wider range of personal and family related legal disputes up to £150,000. '
    },
    learnMore: {
        id: 'endorsement.ngh.views.bespoke-policychange.learnMore',
        defaultMessage: 'Learn more.'
    },
    perMonth: {
        id: 'endorsement.ngh.views.bespoke-policychange.perMonth',
        defaultMessage: 'Per Month'
    },
    perYear: {
        id: 'endorsement.ngh.views.bespoke-policychange.perYear',
        defaultMessage: 'Per Year'
    }
});
