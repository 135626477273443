/* eslint-disable max-len */
import React, {
    useCallback, useState, useContext, useEffect, useRef, useMemo
} from 'react';
import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import {
    useErrorHandler,
    POLICYCHANGE_ERROR_CODE,
    AppContext,
    MTA_STEPS,
    useCurrency
} from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import { useTagManager, useCleanPayload } from 'nfum-capability-policychange-common-react';
import commonMessages from 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';
import metadata from './PolicyChangeCoveragesPage.metadata.json5';
import styles from './PolicyChangeCoveragesPage.module.scss';
import messages from './PolicyChangeCoveragesPage.messages';



const homeEmergencyCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/home-emergency-cover/';
const contAccidentCovUrl = 'https://www.nfumutual.co.uk/home-insurance/contents-insurance/accidental-damage/';
const buildingAccCovUrl = 'https://www.nfumutual.co.uk/home-insurance/buildings-insurance/accidental-damage/';
const standardLegalExpensesCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/personal-legal-expenses/';
const enhancedLegalExpensesCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/personal-legal-expenses-extra/';
const cyclingProtectionCoverUrl = 'https://www.nfumutual.co.uk/home-insurance/cycling-protection/';

const generateLineCoveragesPath = (submissionVM) => (lobPath) => (coverage) => {
    const lobCoverages = _.get(submissionVM, lobPath);
    if (lobCoverages) {
        const lobCoverageIndex = lobCoverages?.findIndex(
            (lobCoverage) => lobCoverage.publicID === coverage
                || lobCoverage.displayName === coverage
                || lobCoverage.name === coverage
        );
        return `${lobPath}[${lobCoverageIndex}]`;
    }

    return lobPath;
};

const generateAccidentalCoverPath = (submissionVM, coverage, term) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)(coverage);
    const lobAccidentalCoverPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)(term);
    return [lobCoveragePath, lobAccidentalCoverPath];
};

const generateBicycleExtensionCoverPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsAwayCov');
    const lobPedalCycleExtensionPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('HOMContentsAwayCovPedalCycleExtension');
    return [lobCoveragePath, lobPedalCycleExtensionPath];
};

function PolicyChangeCoveragesPage(props) { /* NOSONAR: pure declarative usage  */
    const {
        wizardData: policySubmissionVM, updateWizardData, authHeader, history
    } = props;
    const { returnToDashboard } = useWizardActions();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { initialValidation, onValidate, isComponentValid } = useValidation('PolicyChangeCoveragesPage');
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [showWorkingOnQuoteText, setShowWorkingOnQuoteText] = useState(false);
    const [showSavedQuoteText, setShowSavedQuoteText] = useState(false);
    const [showContactUsTextWithAmendments, setShowContactUsTextWithAmendments] = useState(false);
    const previousPageWizardData = useRef(null);
    const [isLexStandardCover, setIsLexStandardCover] = useState(false);
    const [isLexAllRisksCover, setIsLexAllRisksCover] = useState(false);
    const [showBicycleCover, updateShowBicycleCover] = useState(false);
    const LEGAL_EXPENSES_STANDARD_KEY = 'Standard';
    const LEGAL_EXPENSES_EXTRA_KEY = 'Extra';
    const YES_KEY = 'Yes';
    const { handleError } = useErrorHandler();
    const { setIsMarketingQuoteLoadingView } = useContext(AppContext);
    const MARKETING_LOADING_VIEW_DISPLAY_TIME_MS = 5000;
    const RATING_LOADING_TIME_MS = _.get(policySubmissionVM.value, 'baseData.ratingServiceTimeOut_NFUM') * 1000;
    const MAX_PREMIUM_ALLOWED = _.get(policySubmissionVM.value, 'baseData.maxPremiumAllowedToProceed_NFUM');
    const isSafeConditionExistPath = 'lobData.homeLine.coverables.homhomeProperty.securityQuestions.isSafeConditionExists';
    const isSafeConditionAvailable = _.get(policySubmissionVM, `${isSafeConditionExistPath}.value`, false);
    const SCHEDULE_ITEM_WATCH = 'Watch';
    const SCHEDULE_ITEM_JEWELLARY = 'Jewellery';
    const [ratingCashValue, setRatingCash] = useState(0);
    const TIER_LEVEL_3_CUSTOMER = 'tierLevel3';
    const isTierLevel3Customer = _.get(policySubmissionVM, 'lobData.value.homeLine.lineCoverages.contentsCovTier') === TIER_LEVEL_3_CUSTOMER;
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushLinkClickInfo
    } = useTagManager();
    const currencyFormatter = useCurrency();
    const dynamicPricing = useMemo(
        () => _.get(policySubmissionVM.value, 'dynamicPricing_NFUM'),
        [policySubmissionVM.value]
    );
    const producerDesc = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const { cleanNotUpdatedCoverages, cleanCostInfos } = useCleanPayload();
    // Added below method to calculate cash rating for Jewelleries and watches
    const getRatingCashValue = () => {
        let ratingCash = 0;
        const scheduleItems = _.get(policySubmissionVM.value, 'lobData.homeLine.lineCoverages.schedules[0].scheduleItems');
        const valuables = scheduleItems?.filter((item) => (item.itemData.ArticleValuationMethod.typeCodeValue === SCHEDULE_ITEM_WATCH)
        || (item.itemData.ArticleValuationMethod.typeCodeValue === SCHEDULE_ITEM_JEWELLARY)) || [];
        valuables.forEach((item) => {
            ratingCash += item.itemData.ArticleLimit?.bigDecimal;
        });
        ratingCash /= 10;
        return Math.ceil(ratingCash / 1000) * 1000;
    };
    const handleIsSafetyFittedCover = useCallback(
        (value, path) => {
            _.set(policySubmissionVM, `${path}.value`, value);
            const scheduleItems = _.get(policySubmissionVM.value, 'lobData.homeLine.lineCoverages.schedules[0].scheduleItems');
            const valuables = scheduleItems?.filter((item) => (item.itemData.ArticleValuationMethod.typeCodeValue === SCHEDULE_ITEM_WATCH)
            || (item.itemData.ArticleValuationMethod.typeCodeValue === SCHEDULE_ITEM_JEWELLARY)) || [];
            valuables.forEach((item) => {
                const changedItem = item;
                changedItem.itemData.ArticleIsSafeFitted.booleanValue = true;
            });
            updateWizardData(policySubmissionVM);
        },
        [policySubmissionVM, updateWizardData]
    );
    useEffect(() => {
        pushFormStepInfo(policySubmissionVM, MTA_STEPS.MTA_COVERAGES);
        if (isSafeConditionAvailable) {
            _.set(policySubmissionVM, 'lobData.homeLine.coverables.homhomeProperty.securityQuestions.isSafeCondition', null);
            setRatingCash(getRatingCashValue());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRatingCash]);

    const onSeeCoverClick = useCallback((url, coverName) => {
        pushLinkClickInfo(coverName, url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo]);

    const generateLegalExpensesCoverPath = useCallback((polChangeVM) => {
        const lobCoveragesPath = 'lobData.lexLine.lineCoverages.coverages.value';
        const lobCoveragePath = generateLineCoveragesPath(polChangeVM)(lobCoveragesPath)('LEXPersonalLegalExpensesCov');
        const lobPersonalLegalExpensesPath = generateLineCoveragesPath(polChangeVM)(`${lobCoveragePath}.terms`)('Level of Cover');
        return lobPersonalLegalExpensesPath;
    }, []);

    useEffect(() => {
        const legalExpensesCoverPath = generateLegalExpensesCoverPath(policySubmissionVM);
        const legalExpensesCoverValue = _.get(policySubmissionVM, `${legalExpensesCoverPath}.chosenTermValue`);
        if (legalExpensesCoverValue && legalExpensesCoverValue === LEGAL_EXPENSES_STANDARD_KEY) {
            setIsLexStandardCover(true);
            setIsLexAllRisksCover(false);
        } else if (legalExpensesCoverValue && legalExpensesCoverValue === LEGAL_EXPENSES_EXTRA_KEY) {
            setIsLexAllRisksCover(true);
            setIsLexStandardCover(false);
        }
        const coverablesType = _.get(policySubmissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables');
        const coverable = coverablesType !== 'buildingsOnly';
        updateShowBicycleCover(coverable);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        previousPageWizardData.current = viewModelService.clone(policySubmissionVM);
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            _.set(policySubmissionVM, path, value);
            updateWizardData(policySubmissionVM);
        },
        [policySubmissionVM, updateWizardData]
    );
    const contentsAccidentalCoverPath = generateAccidentalCoverPath(
        policySubmissionVM,
        'HOMContentsCov',
        'HOMContentsCovAccidentalDamageCover'
    );
    const buildingsAccidentalCoverPath = generateAccidentalCoverPath(
        policySubmissionVM,
        'HOMBuildingsCov',
        'HOMBuildingsCovAccidentalDamageRequired'
    );
    const bicycleExtensionPath = generateBicycleExtensionCoverPath(policySubmissionVM);
    const propertyStatusPath = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.propertyStatus';
    const homEmergencyCovAvailPath = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.isHomEmergencyCovAvail';
    const handleAccidentalDamageCover = useCallback(
        (value, path) => {
            const accidentalDamageCoverPath = path === 'contentsAccidentalCover'
                ? contentsAccidentalCoverPath[1]
                : buildingsAccidentalCoverPath[1];
            const accidentalDamageCoverUpdatePath = path === 'contentsAccidentalCover'
                ? contentsAccidentalCoverPath[0]
                : buildingsAccidentalCoverPath[0];
            const newSubmissionVM = viewModelService.clone(policySubmissionVM);
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.chosenTerm`, String(value));
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.chosenTermValue`, value ? 'Yes' : 'No');
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.updated`, true);
            _.set(newSubmissionVM, `${accidentalDamageCoverPath}.directBooleanValue`, value);
            _.set(newSubmissionVM, `${accidentalDamageCoverUpdatePath}.updated`, true);
            updateWizardData(newSubmissionVM);
        }, [
            buildingsAccidentalCoverPath,
            contentsAccidentalCoverPath,
            policySubmissionVM,
            updateWizardData,
            viewModelService
        ]
    );

    const handleEnableBicycleExtensionCover = useCallback(
        (value) => {
            const newSubmissionVM = viewModelService.clone(policySubmissionVM);
            _.set(newSubmissionVM, `${bicycleExtensionPath[1]}.chosenTermValue`, value ? 'Yes' : 'No');
            _.set(newSubmissionVM, `${bicycleExtensionPath[1]}.updated`, true);
            _.set(newSubmissionVM, `${bicycleExtensionPath[0]}.updated`, true);
            _.set(newSubmissionVM, `${bicycleExtensionPath[1]}.directBooleanValue`, value);
            updateWizardData(newSubmissionVM);
        },
        [bicycleExtensionPath, policySubmissionVM, updateWizardData, viewModelService]
    );

    const handleEnableStandardLegalExpensesCover = useCallback((isStandardChecked) => {
        if (!isLexAllRisksCover && !isStandardChecked) {
            _.set(policySubmissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', null);
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(false);
        } else if (isStandardChecked === true) {
            _.set(policySubmissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'Standard');
            setIsLexStandardCover(true);
            setIsLexAllRisksCover(false);
        } else {
            _.set(policySubmissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'AllRisks');
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(true);
        }
        updateWizardData(policySubmissionVM);
    }, [
        policySubmissionVM,
        updateWizardData,
        isLexAllRisksCover
    ]);

    const handleEnableAllRisksLegalExpensesCover = useCallback((isAllRisksChecked) => {
        if (!isLexStandardCover && !isAllRisksChecked) {
            _.set(policySubmissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', null);
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(false);
        } else if (isAllRisksChecked === true) {
            _.set(policySubmissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'AllRisks');
            setIsLexStandardCover(false);
            setIsLexAllRisksCover(true);
        } else {
            _.set(policySubmissionVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'Standard');
            setIsLexStandardCover(true);
            setIsLexAllRisksCover(false);
        }
        if (isTierLevel3Customer && !isAllRisksChecked) {
            setIsLexStandardCover(true);
        }
        updateWizardData(policySubmissionVM);
    }, [
        policySubmissionVM,
        updateWizardData,
        isLexStandardCover,
        isTierLevel3Customer
    ]);

    useEffect(() => {
        const legalExpensesCoverPath = generateLegalExpensesCoverPath(policySubmissionVM);
        const legalExpensesCoverValue = _.get(policySubmissionVM, `${legalExpensesCoverPath}.chosenTermValue`);
        if (legalExpensesCoverValue && legalExpensesCoverValue === LEGAL_EXPENSES_STANDARD_KEY) {
            setIsLexStandardCover(true);
            setIsLexAllRisksCover(false);
        } else if (legalExpensesCoverValue && legalExpensesCoverValue === LEGAL_EXPENSES_EXTRA_KEY) {
            setIsLexAllRisksCover(true);
            setIsLexStandardCover(false);
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEnableHomeEmergencyCover = useCallback(
        (value, path) => {
            _.set(policySubmissionVM, `${path}.value`, value);
            updateWizardData(policySubmissionVM);
        },
        [policySubmissionVM, updateWizardData]
    );

    const contentsAccidentalCoverValue = _.get(policySubmissionVM, `${contentsAccidentalCoverPath[1]}.chosenTermValue`);
    const buildingsAccidentalCoverValue = _.get(policySubmissionVM, `${buildingsAccidentalCoverPath[1]}.chosenTermValue`);
    const bicycleExtensionValue = _.get(policySubmissionVM, `${bicycleExtensionPath[1]}.chosenTermValue`);
    const propertyStatusValue = _.get(policySubmissionVM, `${propertyStatusPath}.value.code`);
    const isHomEmergencyCovAvailValue = _.get(policySubmissionVM, `${homEmergencyCovAvailPath}.value`, false);
    /* Added below method to have timeout promise while quote MTA */
    const promiseWithTimeout = useCallback((promise) => {
        let timeoutId;
        const timeoutPromise = new Promise((reject) => {
            timeoutId = setTimeout(() => {
                reject(new Error(translator(messages.ratingServiceTimedOut)));
            }, RATING_LOADING_TIME_MS);
        });
        return {
            promiseOrTimeout: Promise.race([promise, timeoutPromise]),
            timeoutId,
        };
    }, [RATING_LOADING_TIME_MS, translator]);

    const onNext = useCallback(async () => {
        if (showError) {
            history.push('/home');
            return false;
        }
        const isFrauded = _.get(policySubmissionVM, 'baseData.value.sirafraudCheckStatus_NFUM');
        if (isFrauded === true) {
            return history.push('/security-check');
        }
        const saveAndQuotePromise = new Promise((resolve, reject) => {
            cleanNotUpdatedCoverages(policySubmissionVM);
            cleanCostInfos(policySubmissionVM);
            EndorsementService.saveEndorsement([policySubmissionVM.value], authHeader).then((updateResponse) => {
                EndorsementService.quoteEndorsement(updateResponse.jobID, false, authHeader).then((quotedSubmission) => {
                    resolve(quotedSubmission);
                }).catch((err) => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
        const { promiseOrTimeout, timeoutId } = promiseWithTimeout(saveAndQuotePromise);
        try {
            setIsMarketingQuoteLoadingView(true);
            const someSecondsDelayPromise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, MARKETING_LOADING_VIEW_DISPLAY_TIME_MS);
            });
            const results = await Promise.all([promiseOrTimeout, someSecondsDelayPromise]);
            const quotePromiseResponse = results[0];
            if (quotePromiseResponse.status === 'rejected') {
                setIsMarketingQuoteLoadingView(false);
                throw quotePromiseResponse.reason;
            }
            if (results[0].message === translator(messages.ratingServiceTimedOut)) {
                history.push(`/service-unavailable?jobid=${policySubmissionVM.value.policyNumber}`);
            }
            policySubmissionVM.value = quotePromiseResponse;
            setIsMarketingQuoteLoadingView(false);
            const totalPremium = _.get(policySubmissionVM.value, 'totalCost.amount');
            if (totalPremium > MAX_PREMIUM_ALLOWED) {
                return history.push('/security-check');
            }
            return policySubmissionVM;
        } catch (error) {
            pushFormStepErrorInfo(policySubmissionVM, MTA_STEPS.MTA_COVERAGES, error);
            setIsMarketingQuoteLoadingView(false);
            if (error?.appErrorCode === POLICYCHANGE_ERROR_CODE) {
                setShowWorkingOnQuoteText(false);
                setShowSavedQuoteText(false);
                setShowContactUsTextWithAmendments(true);
                setErrorMessage(translator(messages.midTermErrorOnline));
                setShowError(true);
            } else {
                handleError(error, policySubmissionVM.value.policyNumber);
            }
            return false;
        } finally {
            clearTimeout(timeoutId);
        }
    }, [
        EndorsementService,
        authHeader,
        policySubmissionVM,
        translator,
        handleError,
        setIsMarketingQuoteLoadingView,
        showError,
        history,
        pushFormStepErrorInfo,
        MAX_PREMIUM_ALLOWED,
        promiseWithTimeout,
        cleanNotUpdatedCoverages,
        cleanCostInfos
    ]);

    const getAddRemoveCoverToggleButtonLabel = (conditionForRemoveCoverLabel) => {
        return conditionForRemoveCoverLabel
            ? translator(messages.removeCover)
            : translator(messages.addCover);
    };

    const getCoverLabelForTier3Customers = (conditionForRemoveCoverLabel) => {
        if (isTierLevel3Customer) {
            return translator(messages.includedAsStandard);
        }
        return conditionForRemoveCoverLabel
            ? translator(messages.removeCover)
            : translator(messages.addCover);
    };

    const getExtraDescription = useCallback(() => {
        const paymentMethod = _.get(policySubmissionVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return messages.perMonth;
        }
        return messages.perYear;
    }, [policySubmissionVM]);

    const getDynamicPrice = useCallback((amount) => {
        const paymentMethod = _.get(policySubmissionVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return currencyFormatter.formatCurrency(amount / 12, true);
        }
        return currencyFormatter.formatCurrency(amount, true);
    }, [policySubmissionVM, currencyFormatter]);

    const getDynamicPriceForLEXExtra = useCallback(() => {
        if (isTierLevel3Customer) {
            return getDynamicPrice(dynamicPricing.lexExtraPremium?.amount);
        }
        const allLexAmount = dynamicPricing.lexStandardPremium?.amount
            + dynamicPricing.lexExtraPremium?.amount;
        return getDynamicPrice(allLexAmount);
    }, [getDynamicPrice, dynamicPricing, isTierLevel3Customer]);

    const seeAllCoverBenefits = translator(messages.seeAllCoverBenefits);
    const learnMore = translator(messages.learnMore);

    const overrideProps = {
        protectingYourValuablesContainer: {
            visible: isSafeConditionAvailable
        },
        accidentalDamageCoverContainer: {
            visible: !!contentsAccidentalCoverValue || !!buildingsAccidentalCoverValue
        },
        additionalText1: {
            visible: !isBespoke
        },
        contentsAccidentalCoverContainer: {
            visible: !!contentsAccidentalCoverValue
        },
        enableContentsAccidentalCoverSwitch: {
            disabled: isTierLevel3Customer,
            value: contentsAccidentalCoverValue === YES_KEY,
            label: getCoverLabelForTier3Customers(contentsAccidentalCoverValue === YES_KEY),
            description: isTierLevel3Customer ? undefined : getDynamicPrice(dynamicPricing.contentsAccDamagePremium?.amount),
            extraDescription: isTierLevel3Customer ? undefined : getExtraDescription()
        },
        buildingsAccidentalCoverContainer: {
            visible: !!buildingsAccidentalCoverValue
        },
        enableBuildingsAccidentalCoverSwitch: {
            value: buildingsAccidentalCoverValue === YES_KEY,
            label: getAddRemoveCoverToggleButtonLabel(buildingsAccidentalCoverValue === YES_KEY),
            description: getDynamicPrice(dynamicPricing.buildingsAccDamagePremium?.amount),
            extraDescription: getExtraDescription()
        },
        enableStandardLegalExpensesCoverSwitch: {
            value: isTierLevel3Customer ? true : isLexStandardCover,
            disabled: isTierLevel3Customer,
            label: getCoverLabelForTier3Customers(isLexStandardCover),
            description: isTierLevel3Customer ? undefined : getDynamicPrice(dynamicPricing.lexStandardPremium?.amount),
            extraDescription: isTierLevel3Customer ? undefined : getExtraDescription()
        },
        enableEnhancedLegalExpensesCoverSwitch: {
            value: isLexAllRisksCover,
            label: getAddRemoveCoverToggleButtonLabel(isLexAllRisksCover),
            description: getDynamicPriceForLEXExtra(),
            extraDescription: getExtraDescription()
        },
        bicycleExtensionCoverContainer: {
            visible: !!bicycleExtensionValue && showBicycleCover
        },
        enableBicycleExtensionCoverSwitch: {
            value: bicycleExtensionValue === YES_KEY,
            path: bicycleExtensionPath,
            label: getAddRemoveCoverToggleButtonLabel(bicycleExtensionValue === YES_KEY),
            description: getDynamicPrice(dynamicPricing.cycleProtectionPremium?.amount),
            extraDescription: getExtraDescription()
        },
        homeEmergencyCoverContainer: {
            visible: propertyStatusValue && propertyStatusValue.includes('Owned')
        },
        enableHomeEmergencyCoverSwitch: {
            label: getAddRemoveCoverToggleButtonLabel(_.get(policySubmissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.isHomEmergencyCovAvail') === true),
            description: getDynamicPrice(dynamicPricing.homeEmergencyPremium?.amount),
            extraDescription: getExtraDescription()
        },
        pageContainer: {
            visible: !showError
        },
        midTermErrorMessageContainer: {
            visible: showError
        },
        midTermError: {
            errorMessage: errorMessage,
            showWorkingOnQuoteText: showWorkingOnQuoteText,
            showSavedQuoteText: showSavedQuoteText,
            showContactUsTextWithAmendments: showContactUsTextWithAmendments,
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
        },
        homeEmergencyCoverTextClaimPeriod: {
            visible: isHomEmergencyCovAvailValue
        },
        protectingYourValuablesInfoContainer: {
            content: `${translator(messages.protectingYourValuablesDisclaimer)} £${ratingCashValue.toLocaleString()}`
        },
        homeEmergencyCoverLink: {
            onClick: () => {
                onSeeCoverClick(homeEmergencyCoverUrl, seeAllCoverBenefits);
            }
        },
        contentsAccidentalCoverLink: {
            onClick: () => {
                onSeeCoverClick(contAccidentCovUrl, seeAllCoverBenefits);
            }
        },
        buildingsAccidentalCoverLink: {
            onClick: () => {
                onSeeCoverClick(buildingAccCovUrl, seeAllCoverBenefits);
            }
        },
        standardLegalExpensesCoverLink: {
            onClick: () => {
                onSeeCoverClick(standardLegalExpensesCoverUrl, seeAllCoverBenefits);
            }
        },
        enhancedLegalExpensesCoverLink: {
            onClick: () => {
                onSeeCoverClick(enhancedLegalExpensesCoverUrl, learnMore);
            }
        },
        bicycleExtensionCoverLink: {
            onClick: () => {
                onSeeCoverClick(cyclingProtectionCoverUrl, seeAllCoverBenefits);
            }
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onEnableAccidentalDamageCover: handleAccidentalDamageCover,
            onEnableBicycleExtensionCover: handleEnableBicycleExtensionCover,
            onStandardLexCoverChange: handleEnableStandardLegalExpensesCover,
            onAllRisksLexCoverChange: handleEnableAllRisksLegalExpensesCover,
            onEnableHomeEmergencyCover: handleEnableHomeEmergencyCover,
            onEnableSafetyFittedCover: handleIsSafetyFittedCover,
        },
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            showPrevious
            nextLabel={showError ? commonMessages.returnToPortal : commonMessages.reviewChanges}
            cancelLabel={commonMessages.cancelAllChanges}
            showCancel={!showError}
            onCancel={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySubmissionVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PolicyChangeCoveragesPage.propTypes = wizardProps;
export default withAuthenticationContext(PolicyChangeCoveragesPage);
