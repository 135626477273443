import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { Icon } from '@jutro/components';
import messages from './SpecialConditions.messages';
import metadata from './SpecialConditions.metadata.json5';
import styles from './SpecialConditions.module.scss';

function SpecialConditions(props) {
    const translator = useContext(TranslatorContext);
    const { jobVM } = props;
    const CONDITION_UPPER = 'Condition';
    const CONDITION_LOWER = 'condition';
    const WORDING = 'Wording';

    const getNewConditionsVM = useCallback(() => {
        if (!jobVM.value.lobData.homeLine.lineCoverages.conditions) {
            return [];
        }

        const prevConds = jobVM.previousLobData.homeLine.lineCoverages.conditions.children
            .filter((cond) => cond.value.selected);

        const conditions = jobVM.lobData.homeLine.lineCoverages.conditions.children
            .filter((cond) => cond.value.selected);

        return conditions.filter((cond) => !prevConds
            .find((prevCond) => prevCond.value.codeIdentifier_NFUM ===
                cond.value.codeIdentifier_NFUM));
    }, [jobVM]);

    function formatTextToHTML(text) {
        return text.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>').replace(/££/g, '£');
    }

    function formatName(name) {
        if (name.includes(CONDITION_UPPER)) {
            return name.replace(CONDITION_UPPER, CONDITION_LOWER);
        }
        return name;
    }

    const getProcessedConditions = useCallback(() => {
        return getNewConditionsVM()
            .filter((cond) => cond.value.selected).map((cond) => {
                const wordingTerm = cond.value.terms.find(
                    (term) => term.codeIdentifier_NFUM.includes(WORDING)
                );
                const newCond = {
                    name: formatName(cond.value.name),
                    text: <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: formatTextToHTML(wordingTerm.chosenTerm)
                        }}
                    />
                };

                return newCond;
            });
    }, [getNewConditionsVM]);

    // eslint-disable-next-line max-len
    const renderSimpleAccordionHeader = useCallback((isOpen, title) => { /* NOSONAR: pure declarative usage */
        return (
            <div className={styles.simpleAccordionTitleContainer}>
                <h3>{ title }</h3>
                <div>
                    { !isOpen && <Icon icon="gw-add" aria-label={translator(messages.expand)} /> }
                    { isOpen && <Icon icon="gw-remove" aria-label={translator(messages.collapse)} /> }
                </div>
            </div>
        );
    }, [translator]);

    const generateConditionsOverrides = useCallback(() => {
        const conditionItems = getProcessedConditions();
        const covOverrides = conditionItems.map((condition, index) => {
            return {
                [`conditionCard${index}`]: {
                    visible: true,
                    renderHeader: (isOpen) => {
                        return renderSimpleAccordionHeader(
                            isOpen,
                            condition.name
                        );
                    }
                },
                [`wording${index}`]: {
                    content: condition.text
                },
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [getProcessedConditions, renderSimpleAccordionHeader]);

    const overrideProps = {
        conditions: {
            data: getNewConditionsVM()
        },
        ...generateConditionsOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    if (getNewConditionsVM().length === 0) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default SpecialConditions;
