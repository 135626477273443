import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import classNames from 'classnames';
import {
    Switch, Route, Redirect, BrowserRouter as Router
} from 'react-router-dom';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import {
    Main,
    ModalNextEmitter
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { NavRoutes, routeConfirmationModal } from '@jutro/router';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { DynamicRoute, GrantedAuthorities, withAuthContextProvider, useAuthentication } from 'gw-digital-auth-react'; // nfum custom // okta-patch
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { EnrollmentService } from 'gw-capability-policyenrollment';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { PolicyDetails } from 'nfum-capability-policy-react'; // nfum custom
import { AvailabilityService } from 'gw-capability-policycommon';
import { EndorsementService } from 'gw-capability-policychange';
import { RenewalService } from 'nfum-capability-renewal'; // nfum custom
import { PAPolicyChangeWizard } from 'gw-capability-policychange-pa-react';
import { HOPolicyChangeWizard } from 'gw-capability-policychange-ho-react';
import { PolicyChangeRetrieve, PolicyChangeRequestRetrieve } from 'nfum-capability-policychange-common-react'; // nfum custom
import { NGHPolicyChangeWizard } from 'nfum-capability-policychange-ngh-react'; // nfum custom
import { NGHBespokePolicyChangeWizard, NGHBespokeCoveragesPolicyChangeWizard } from 'nfum-capability-policychange-bespoke-ngh-react'; // nfum custom
import { NGHRenewalWizard } from 'nfum-capability-renewal-ngh-react'; // nfum custom
import { LoginCapabilityComponentMap, LoginCapabilityComponents } from 'gw-portals-login-react';
import { personalizedOfferComponentMap, personalizedOfferComponents } from 'gw-capability-personalizedoffers-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import {
    BillingSummaryPage,
    MakePaymentPage,
    billingComponentMap,
    billingAdditionalComponents
} from 'gw-capability-billing-react';
import { AdditionalEnrollment, SignUpEnrollment } from 'gw-capability-enrollment-react';
import { UsersProfile } from 'gw-capability-profileinfo-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui'; // upgrade
import { ProducerInfoService } from 'gw-capability-profileinfo';

import { DependencyProvider } from 'gw-portals-dependency-react';
import { AccountBillingDetailsService, BillingService } from 'gw-capability-billing';
import { PolicyService } from 'gw-capability-policy';
import { ClaimDocumentService } from 'gw-capability-claimdocument';

import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';
import {
    platformComponents as customPlatformComponents,
    platformComponentMap as customPlatformComponentMap
} from 'nfum-components-platform-react'; // nfum custom
import { ErrorBoundary } from 'gw-portals-error-react';
import BaseFNOLWizard, { ClaimsConfirmationPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage } from 'gw-pages-platform-react';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';

import {
    ClaimList, ClaimDetails, PolicyLevelCoverages, CreateNote
} from 'gw-capability-claim-react';
import { ClaimService } from 'gw-capability-claim';
import { FNOLService } from 'gw-capability-fnol';
import FaqPage from 'gw-capability-faq-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import { filterCapabilityRoutes } from 'gw-portals-config-js';
import { useJobRetrieval } from 'nfum-portals-wizard-react'; // nfum custom
import { AppContext, useErrorHandler } from 'nfum-portals-utils-react'; // nfum custom
import {
    useCommonCms
} from 'nfum-cms-react'; // nfum custom
import QuoteLoadingView from '../components/QuoteLoadingView/QuoteLoadingView'; // nfum custom
import PageHeaderComponent from '../components/PageHeaderComponent/PageHeaderComponent';
import { routes } from './App.routes.metadata.json5';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import EntryPage from '../pages/EntryPage/EntryPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import ServiceUnavailablePage from '../pages/ServiceUnavailablePage/ServiceUnavailablePage'; // nfum custom
import ContactUsPage from '../pages/ContactUsPage/ContactUsPage'; // nfum custom
import SecurityCheckPage from '../pages/SecurityCheckPage/SecurityCheckPage'; // nfum custom
import messages from './App.messages';
import OnlineSupport from '../components/OnlineSupport/OnlineSupport'; // nfum custom
import Footer from '../components/Footer/Footer'; // nfum custom

const { capabilitiesConfig } = appConfig;

const componentMap = {
    landingpage: LandingPage,
    serviceunavailable: ServiceUnavailablePage, // nfum custom
    policydocumenterror: PolicyDocumentError,
    fnolwcwizard: FNOLWCWizard,
    basefnolwizard: BaseFNOLWizard,
    claimsconfirmationpage: ClaimsConfirmationPage,
    contactagent: ContactAgentPage,
    fnolpawizard: FNOLPAWizard,
    fnolhowizard: FNOLHOWizard,
    additionalenrollmentpage: AdditionalEnrollment,
    claimlist: ClaimList,
    claimdetails: ClaimDetails,
    policydetailspage: PolicyDetails,
    papolicychangewizard: PAPolicyChangeWizard,
    hopolicychangewizard: HOPolicyChangeWizard,
    nghpolicychangewizard: NGHPolicyChangeWizard, // nfum custom
    nghbespokepolicychangewizard: NGHBespokePolicyChangeWizard, // nfum custom
    nghbespokecoveragespolicychangewizard: NGHBespokeCoveragesPolicyChangeWizard, // nfum custom
    policychangeretrieve: PolicyChangeRetrieve, // nfum custom
    policychangerequestretrieve: PolicyChangeRequestRetrieve, // nfum custom
    nghrenewalwizard: NGHRenewalWizard, // nfum custom
    createnote: CreateNote,
    billingsummarypage: BillingSummaryPage,
    makepaymentpage: MakePaymentPage,
    usersprofile: UsersProfile,
    policylevelcoverages: PolicyLevelCoverages,
    faqpage: FaqPage,
    fnolcawizard: FNOLCAWizard,
    fnolcpwizard: FNOLCPWizard,
    fnolbopwizard: FNOLBOPWizard,
    fnolgeneralwizard: FNOLGeneralWizard
};

// nfum custom // okta-patch
/** Commented below lines as it is available with UAA and not for OKTA */

const shouldRouteToSignUp = (authorities) => { /* NOSONAR: OKTA/UAA Config  */
    /* NOSONAR: OKTA/UAA Config
    const accountAuthorities = authorities.filter((authority) =>
    authority.authorityType === 'ACCOUNT');*/
    // if user doesn't have ACCOUNT authorities it is supposed to be enrolled
    /* NOSONAR: OKTA/UAA Config
    if (accountAuthorities.length === 0) {
        return <Redirect to="/signup-enrollment" />;
    }*/
    return null;
};

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...customPlatformComponentMap, // nfum custom
        ...personalizedOfferComponentMap,
        ...VehicleInfoLookupComponentMap,
        ...LoginCapabilityComponentMap,
        ...policyJobComponentMap,
        ...billingComponentMap,
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        // replace the native IMG component with a proxied version
        img: { component: 'img' },
    },
    {
        ...platformComponents,
        ...customPlatformComponents, // nfum custom
        ...personalizedOfferComponents,
        ...VehicleInfoLookupComponents,
        ...LoginCapabilityComponents,
        ...policyJobComponents,
        ...billingAdditionalComponents,
        WizardPageTemplateWithTitle,
        img: ImageComponent,
    }
);

// (Router) Title is required and used for (Browser) title
// in a format of "${title} - ${appName}". In order to show
// the application name only, it needs to be an empty value.
const routesWithTitle = filterCapabilityRoutes(capabilitiesConfig, routes);
const routesWithoutTitle = routesWithTitle.map((route) => {
    return {
        ...route,
        title: ''
    };
});

const modalEmitter = new ModalNextEmitter();

function App() {
    const translator = useTranslator();
    const { isLoggedIn, isAuthInProgress } = useAuthentication();
    const [viewModelService, setViewModelService] = useState(undefined);
    const {
        storePolicyNumberToRetrieveRenewal,
        storePolicyNumberToRetrievePolicyChange
    } = useJobRetrieval(); // nfum custom
    const { location: { pathname } } = window; // nfum custom
    if (!isLoggedIn && !isAuthInProgress) { // nfum custom
        storePolicyNumberToRetrieveRenewal(pathname);
    }
    storePolicyNumberToRetrievePolicyChange(pathname); // nfum custom
    // nfum custom
    const [isMarketingQuoteLoadingView, setIsMarketingQuoteLoadingView] = useState(false);
    const [marketingInfo, setMarketingInfo] = useState({}); // nfum custom
    const { handleError: customHandleError } = useErrorHandler();
    const [isOnlineSupportOpened, setIsOnlineSupportOpened] = useState(false); // nfum custom
    const journeys = Object.freeze({ // nfum custom
        RENEWAL: 'renewal',
        MTA: 'mta',
        COMMON: 'common'
    });
    const [journey, setJourney] = useState(journeys.COMMON); // nfum custom

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);

    useEffect(() => {
        const skipNav = document.querySelector('.jut__SkipNav__skipNav');
        if (skipNav) {
            skipNav.removeAttribute('role');
            skipNav.removeAttribute('tabindex');
            skipNav.style.display = 'none';
        }
    }, []);

    const mainContentClassNames = classNames({
        hidden: isMarketingQuoteLoadingView
    });
    const loadingViewClassNames = classNames('loadingViewContainer', {
        hidden: !isMarketingQuoteLoadingView
    });

    const openOnlineSupport = useCallback(() => { // nfum custom
        setIsOnlineSupportOpened(true);
    }, []);

    const closeOnlineSupport = useCallback(() => { // nfum custom
        setIsOnlineSupportOpened(false);
    }, []);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    AccountBillingDetailsService: AccountBillingDetailsService,
                    PolicyService: PolicyService,
                    EndorsementService: EndorsementService,
                    AvailabilityService: AvailabilityService,
                    FNOLService: FNOLService,
                    ClaimService: ClaimService,
                    ClaimDownloadService: ClaimDocumentService,
                    BillingService: BillingService,
                    EnrollmentService: EnrollmentService,
                    ContactService: ProducerInfoService,
                    RenewalService: RenewalService,
                }}
            >
                <ErrorBoundary onError={customHandleError}>
                    <ViewModelServiceContext.Provider value={viewModelService}>
                        <AppContext.Provider
                            value={{
                                isMarketingQuoteLoadingView,
                                setIsMarketingQuoteLoadingView,
                                marketingInfo,
                                setMarketingInfo,
                                journey,
                                setJourney
                            }}
                        >
                            <Router /* upgrade */
                                basename="/account-management"
                                getUserConfirmation={
                                    (message, callback) => {
                                        return routeConfirmationModal(
                                            message,
                                            callback,
                                            modalEmitter
                                        );
                                    }
                                }
                            >
                                <Switch>
                                    <Route path="/service-unavailable">
                                        <PageHeaderComponent
                                            routes={routesWithTitle}
                                            openOnlineSupport={openOnlineSupport}
                                        />
                                        <OnlineSupport
                                            open={isOnlineSupportOpened}
                                            closeOnlineSupport={closeOnlineSupport}
                                        />
                                        <ServiceUnavailablePage />
                                        <Footer />
                                    </Route>
                                    <Route path="/contact-us">
                                        <PageHeaderComponent
                                            routes={routesWithTitle}
                                            openOnlineSupport={openOnlineSupport}
                                        />
                                        <OnlineSupport
                                            open={isOnlineSupportOpened}
                                            closeOnlineSupport={closeOnlineSupport}
                                        />
                                        <ContactUsPage />
                                        <Footer />
                                    </Route>
                                    <Route path="/security-check">
                                        <PageHeaderComponent
                                            routes={routesWithTitle}
                                            openOnlineSupport={openOnlineSupport}
                                            isPhoneNumberHidden
                                        />
                                        <SecurityCheckPage />
                                        <Footer />
                                    </Route>
                                    <Route exact path="/login-page" component={EntryPage} />
                                    <Route exact path="/auth/resetpassword" component={ResetPasswordPage} />
                                    <Route exact path="/signup-enrollment" component={SignUpEnrollment} />
                                    <DynamicRoute path="/">
                                        <GrantedAuthorities
                                            checkRenderBasedOnAuthorities={shouldRouteToSignUp}
                                        >
                                            <div className={loadingViewClassNames}>
                                                <QuoteLoadingView
                                                    isMarketingInfo
                                                />
                                            </div>
                                            <div className={mainContentClassNames}>
                                                <PageHeaderComponent
                                                    routes={routesWithTitle}
                                                    openOnlineSupport={openOnlineSupport}
                                                />
                                                <OnlineSupport
                                                    open={isOnlineSupportOpened}
                                                    closeOnlineSupport={closeOnlineSupport}
                                                />
                                                <Main>
                                                    <NavRoutes
                                                        resolveComponentMap={componentMap}
                                                        routes={routesWithoutTitle}
                                                    />
                                                </Main>
                                                <Footer />
                                            </div>
                                        </GrantedAuthorities>
                                    </DynamicRoute>
                                </Switch>
                            </Router>
                        </AppContext.Provider>
                    </ViewModelServiceContext.Provider>
                </ErrorBoundary>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthContextProvider()(App);
