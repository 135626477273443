import React, {
    useCallback, useState, useContext, useEffect
} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import {
    useErrorHandler,
    POLICYCHANGE_ERROR_CODE,
    VALIDATION_ERROR_CODE,
    BESPOKE_COVERAGES_MTA_STEPS
} from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import { useTagManager, NGHContext, useCleanPayload } from 'nfum-capability-policychange-common-react';
import FineArtsCard from 'nfum-capability-policychange-common-react/components/FineArtsCard/FineArtsCard';
import BespokeValuables from 'nfum-capability-policychange-common-react/components/BespokeValuables/BespokeValuables';
import commonMessages from 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';
import metadata from './BespokePolicyChangeDetailsPage.metadata.json5';
import styles from './BespokePolicyChangeDetailsPage.module.scss';
import messages from './BespokePolicyChangeDetailsPage.messages';

const generateLineCoveragesPath = (policySubmissionVM) => (lobPath) => (coverage) => {
    const lobCoverages = _.get(policySubmissionVM, lobPath);
    if (lobCoverages) {
        const lobCoverageIndex = lobCoverages?.findIndex(
            (lobCoverage) => lobCoverage.publicID === coverage
                || lobCoverage.displayName === coverage
                || lobCoverage.name === coverage
        );
        return `${lobPath}[${lobCoverageIndex}]`;
    }

    return lobPath;
};

const generateHighValueItemsPath = (policySubmissionVM) => {
    const lobSchedulesPath = 'lobData.homeLine.lineCoverages.schedules.value';
    const lobScheduleItemsPath = generateLineCoveragesPath(policySubmissionVM)(lobSchedulesPath)('ScheduleItems');
    return lobScheduleItemsPath;
};

const generateUnspecifiedValuablesPath = (policySubmissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const unspecifiedBelongingsName = 'HOMContentsAwayCovUnspecifiedBelongingsReplCost';
    const lobCoveragePath = generateLineCoveragesPath(policySubmissionVM)(lobCoveragesPath)('HOMContentsAwayCov');
    return generateLineCoveragesPath(policySubmissionVM)(`${lobCoveragePath}.terms`)(unspecifiedBelongingsName);
};

function BespokePolicyChangeDetailsPage(props) {
    const {
        wizardData: policySubmissionVM, updateWizardData, authHeader
    } = props;
    const translator = useContext(TranslatorContext);
    const { returnToDashboard } = useWizardActions();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { initialValidation, onValidate, isComponentValid } = useValidation('PolicyDetailsChangePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const [showError, setShowError] = useState(false);
    const [isWithinRenewalPeriod, setIsWithinRenewalPeriod] = useState(false);
    const [isLimitsValidationError, setIsLimitsValidationError] = useState(false);
    const { handleError } = useErrorHandler();
    const { cleanNotUpdatedCoverages, cleanCostInfos } = useCleanPayload();
    const [valuablesSingleItemLimit] = useState(_.get(policySubmissionVM, 'previousLobData.value.homeLine.lineCoverages.valuablesSingleItemLimit'));
    const [prevFineArts] = useState(_.get(policySubmissionVM, 'previousLobData.homeLine.lineCoverages.fineArts.children'));
    const {
        setIsNavigationDisabled
    } = useContext(NGHContext);
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo
    } = useTagManager();
    const MAX_PERIOD_START_DATE_DAYS_SHIFT = 30;
    const minDate = moment();
    const maxDate = moment().add(MAX_PERIOD_START_DATE_DAYS_SHIFT, 'days');
    const setLimitsValidationError = useCallback((isError) => {
        setIsLimitsValidationError(isError);
        setIsNavigationDisabled(isError);
    }, [setIsLimitsValidationError, setIsNavigationDisabled]);

    // #region GTM EVENTS

    useEffect(() => {
        pushFormStepInfo(policySubmissionVM, BESPOKE_COVERAGES_MTA_STEPS.MTA_DETAILS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #endregion GTM EVENTS

    const checkIfWithinRenewalPeriod = useCallback((effectiveDate) => {
        const isEffectiveDateValid = !!effectiveDate?.year
            && !!effectiveDate?.month
            && !!effectiveDate?.day;
        if (!isEffectiveDateValid) {
            setIsWithinRenewalPeriod(false);
            return;
        }
        const dateConversion = (date) => new Date(date.year, date.month, date.day, 0, 0, 0, 0);
        const { renewalStartDate_NFUM: renewalStartDate } = policySubmissionVM.baseData;
        const isBelowRenewalCycle = dateConversion(effectiveDate)
            <= dateConversion(renewalStartDate.value);
        setIsWithinRenewalPeriod(!isBelowRenewalCycle);
    }, [setIsWithinRenewalPeriod, policySubmissionVM]);

    useEffect(() => {
        const {
            effectiveDate
        } = policySubmissionVM.baseData;
        checkIfWithinRenewalPeriod(effectiveDate.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMidnightFromMomentDate = useCallback((date) => {
        return date.toDate().setHours(0, 0, 0, 0);
    }, []);

    const onEffectiveDateChange = useCallback((inputDate) => {
        const isStartDateEmpty = inputDate?.year === undefined
            && inputDate?.month === undefined
            && inputDate?.day === undefined;
        if (isStartDateEmpty) {
            return;
        }
        const periodStartDate = new Date(inputDate.year, inputDate.month, inputDate.day)
            .setHours(0, 0, 0, 0);
        const minimumDate = getMidnightFromMomentDate(minDate);
        const maximumDate = getMidnightFromMomentDate(maxDate);
        const minDateDTO = {
            day: minDate.date(),
            month: minDate.month(),
            year: minDate.year()
        };
        const maxDateDTO = {
            day: maxDate.date(),
            month: maxDate.month(),
            year: maxDate.year()
        };
        const isStartDateValid = !!inputDate.year
        && (inputDate?.month >= 0 && inputDate?.month <= 11)
        && !!inputDate.day;
        const newPolicyChangeVM = viewModelService.clone(policySubmissionVM);
        _.set(newPolicyChangeVM.value, 'baseData.minDate_NFUM', minDateDTO);
        _.set(newPolicyChangeVM.value, 'baseData.maxDate_NFUM', maxDateDTO);
        if (!isStartDateValid || periodStartDate < minimumDate || periodStartDate > maximumDate) {
            _.set(newPolicyChangeVM.value, 'baseData.effectiveDate', undefined);
        } else {
            _.set(newPolicyChangeVM.value, 'baseData.effectiveDate', inputDate);
        }
        updateWizardData(newPolicyChangeVM);
        checkIfWithinRenewalPeriod(_.get(newPolicyChangeVM.value, 'baseData.effectiveDate'));
    }, [
        updateWizardData,
        viewModelService,
        policySubmissionVM,
        checkIfWithinRenewalPeriod,
        maxDate,
        minDate,
        getMidnightFromMomentDate
    ]);

    const Next = useCallback(async () => {
        try {
            if (showError) {
                returnToDashboard();
            }
            cleanNotUpdatedCoverages(policySubmissionVM);
            cleanCostInfos(policySubmissionVM);
            policySubmissionVM.value = await EndorsementService
                .saveEndorsement([policySubmissionVM.value], authHeader);
            return policySubmissionVM;
        } catch (error) {
            pushFormStepErrorInfo(policySubmissionVM,
                BESPOKE_COVERAGES_MTA_STEPS.MTA_DETAILS, error);
            if (error?.appErrorCode === POLICYCHANGE_ERROR_CODE) {
                setShowError(true);
            } else if (error?.appErrorCode === VALIDATION_ERROR_CODE) {
                policySubmissionVM.value = error.appData;
                updateWizardData(policySubmissionVM);
            } else {
                handleError(error, policySubmissionVM.value.policyNumber);
            }
            return false;
        }
    }, [
        showError,
        policySubmissionVM,
        EndorsementService,
        authHeader,
        handleError,
        updateWizardData,
        pushFormStepErrorInfo,
        returnToDashboard,
        cleanNotUpdatedCoverages,
        cleanCostInfos
    ]);
    const onNext = useCallback(async () => {
        return Next();
    }, [Next]);

    const overrideProps = {
        effectiveDate: {
            tooltip: {
                text: translator(messages.EffectiveDateTooltip)
            },
            onBlur: onEffectiveDateChange,
            minDate: minDate.toDate(),
            maxDate: getMidnightFromMomentDate(maxDate),
            disabled: isLimitsValidationError === true
        },
        pageContainer: {
            visible: !showError
        },
        valuablesCardWrapper: {
            visible: isWithinRenewalPeriod !== true
        },
        fineArtsCardWrapper: {
            visible: isWithinRenewalPeriod !== true
        },
        fineArtsCard: {
            wizardData: policySubmissionVM,
            updateWizardData,
            prevFineArts
        }
    };

    const generateLegalExpensesCoverPath = useCallback((polChangeVM) => {
        const lobCoveragesPath = 'lobData.lexLine.lineCoverages.coverages.value';
        const lobCoveragePath = generateLineCoveragesPath(polChangeVM)(lobCoveragesPath)('LEXPersonalLegalExpensesCov');
        const lobPersonalLegalExpensesPath = generateLineCoveragesPath(polChangeVM)(`${lobCoveragePath}.terms`)('Level of Cover');
        return lobPersonalLegalExpensesPath;
    }, []);

    const legalExpensesCoverPath = generateLegalExpensesCoverPath(policySubmissionVM);
    const highValueItemsPath = generateHighValueItemsPath(policySubmissionVM);
    const unspecifiedValuablesPath = generateUnspecifiedValuablesPath(policySubmissionVM);

    const paths = {
        highValueItemsPath,
        unspecifiedValuablesPath,
        legalExpensesCoverPath
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            fineArtsCard: FineArtsCard,
            BespokeValuables: (valuablesCardProps) => (
                <BespokeValuables
                    {...valuablesCardProps}
                    wizardData={policySubmissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={() => setLimitsValidationError(false)}
                    isDisabled={isLimitsValidationError}
                    valuablesSingleItemLimit={valuablesSingleItemLimit}
                />
            )
        },
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={isWithinRenewalPeriod === true
                || (!isComponentValid)}
            skipWhen={initialValidation}
            showPrevious={false}
            nextLabel={(showError && commonMessages.returnToPortal) || commonMessages.reviewChanges}
            cancelLabel={commonMessages.cancelAllChanges}
            showNext
            showCancel={!showError}
            onCancel={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySubmissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

BespokePolicyChangeDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(withViewModelService(BespokePolicyChangeDetailsPage));
