import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext, useCommonTagManager } from 'nfum-portals-utils-react';
import cmsComponents from '../../constants/CmsConstants';
import appConfig from 'app-config';
import styles from './BridgeNeedHelpBox.module.scss';

const NEED_HELP_PAGE_ID = 'need-help';
const actions = Object.freeze({
    OPENS_POPUP: 'opensPopup',
    URL_REDIRECT: 'urlRedirect',
    INTERNAL_URL_REDIRECT: 'urlInternalRedirect',
    PHONE_APP_REDIRECT: 'phoneAppRedirect'
});

const SvgImage = ({ image, imageAlt }) => (
    <svg width="50" height="50">
        <image alt={imageAlt} xlinkHref={image?.url ? `${appConfig.env.NFUM_CMS_URL}${image.url}` : ''} width="50" height="50" />
    </svg>
);

const NeedHelpContent = ({ needHelpBox, mode }) => (
    <div className={styles.container}>
        {mode !== 'mobile' ? (
            <div className={styles.callUs}>
                {needHelpBox?.image && (
                    <div className={styles.iconContainer}>
                        <div className={styles.phoneIcon}>
                            <SvgImage image={needHelpBox.image} imageAlt={needHelpBox.imageAlt} />
                        </div>
                    </div>
                )}
                <div className={styles.infoContainer}>
                    <p
                        className={styles.callUsInfo}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: needHelpBox?.topTextHtml }}
                    />
                    <p
                        className={styles.phoneInfo}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: needHelpBox?.subTextHtml }}
                    />
                </div>
            </div>
        ) : (
            <div className={styles.callUsMobile}>
                <span
                    className={styles.callUsInfoMobile}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: needHelpBox?.topTextHtml }}
                />
                <span
                    className={styles.phoneInfoMobile}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: needHelpBox?.subTextHtml }}
                />
            </div>
        )}
    </div>
);

const BridgeNeedHelpBox = ({ mode, openOnlineSupport }) => {
    const { marketingInfo } = useContext(AppContext);
    const history = useHistory();
    const { pushLinkClickInfo, pushRelativeLinkClickInfo } = useCommonTagManager();

    const needHelpBox = marketingInfo && marketingInfo[NEED_HELP_PAGE_ID]?.pageBlocks
        ?.find((block) => block.contentType.includes(cmsComponents.NEED_HELP));

    const onNeedHelpButtonClicked = () => {
        switch (needHelpBox?.action) {
            case actions.OPENS_POPUP:
                openOnlineSupport();
                break;
            case actions.PHONE_APP_REDIRECT:
                window.location.href = `tel: ${needHelpBox?.phoneNumber}`;
                break;
            case actions.URL_REDIRECT:
                pushLinkClickInfo(needHelpBox?.ariaLabel, needHelpBox?.link);
                window.open(needHelpBox?.link, '_blank');
                break;
            case actions.INTERNAL_URL_REDIRECT:
                pushRelativeLinkClickInfo(needHelpBox?.ariaLabel, needHelpBox?.link);
                history.push(needHelpBox?.link);
                break;
            default:
                break;
        }
    };

    return (
        <button className={styles.buttonContainer} type="button" onClick={onNeedHelpButtonClicked} aria-label={needHelpBox?.ariaLabel}>
            <NeedHelpContent needHelpBox={needHelpBox} mode={mode} />
        </button>
    );
};

export default BridgeNeedHelpBox;
