import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import {
    useHistory
} from 'react-router-dom';
import { NfumLoader } from 'nfum-components-platform-react';
import _ from 'lodash';
import { getConfigValue } from '@jutro/config';
import { TranslatorContext } from '@jutro/locale';
import { PolicyService } from 'gw-capability-policy';
import { useAuthentication } from 'gw-digital-auth-react';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import styles from './ContactUsPage.module.scss';
import metadata from './ContactUsPage.metadata.json5';
import messages from './ContactUsPage.messages';

const ContactUsPage = () => {
    const history = useHistory();
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const {
        pushLinkClickInfo
    } = useCommonTagManager();
    const [jobId, setJobId] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [hasFutureMTA, setHasFutureMTA] = useState('');
    const [producerDesc, setProducerDesc] = useState(undefined);
    const [producerTele, setProducerTele] = useState(undefined);
    const [isBespoke, setIsBspoke] = useState(undefined);
    const [showAgencyDetails, setShowAgencyDetails] = useState(undefined);
    const [isLoading, setLoadingState] = useState(false);
    const [contentsReferral, setContentsReferral] = useState(false);
    const [showRenewalDesc, setShowRenewalDesc] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        const jobID = searchParams.get('jobid');
        const policyNum = searchParams.get('policyNumber');
        setJobId(jobID);
        setPolicyNumber(policyNum);
        setHasFutureMTA(searchParams.get('hasFutureMta'));
        setContentsReferral(searchParams.get('contentsReferral'));
        setShowRenewalDesc(searchParams.get('showRenewalDesc'));
        const transactionRefNumber = jobID || policyNum;
        if (transactionRefNumber) {
            setLoadingState(true);
            PolicyService.retrieveProducerDetails([transactionRefNumber, 'policy'], authHeader)
                .then((producerDetails) => {
                    setProducerDesc(producerDetails.producerCodeDescription);
                    setProducerTele(producerDetails.producerCodeTelephone);
                    setIsBspoke(producerDetails.isBespoke);
                    setShowAgencyDetails(producerDetails.showProducerDetails);
                }).catch(() => {
                    history.push('/contact-us');
                }).finally(() => {
                    setLoadingState(false);
                });
        }
    }, [history, authHeader]);

    const onClose = useCallback(() => {
        const backToDashboardURL = getConfigValue('JUTRO_BACK_TO_DASHBOARD_PATH', '/');
        pushLinkClickInfo(translator(messages.backToDashboard), backToDashboardURL);
        window.location.replace(backToDashboardURL);
    }, [pushLinkClickInfo, translator]);

    if (isLoading) {
        return <NfumLoader loaded={!isLoading} />; /* NOSONAR: pure declarative usage */
    }
    const overrideProps = {

        referralMessage: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
            showCallBack: false,
            contentsReferral: contentsReferral
        },
        closeButton: {
            onClick: onClose
        },
        policyNumberReference: {
            content: policyNumber ? `${translator(messages.policyNumberReference)}: ${policyNumber}` : '',
            visible: !_.isEmpty(policyNumber)
        },
        quoteNumberReference: {
            content: jobId ? `${translator(messages.policyNumberReference)}: ${jobId}` : '',
            visible: !_.isEmpty(jobId) && _.isEmpty(policyNumber)
        },
        title: {
            visible: (_.isEmpty(policyNumber) && _.isEmpty(hasFutureMTA))
            || !_.isEmpty(contentsReferral) || !_.isEmpty(showRenewalDesc)
        },
        renewalDescription: {
            visible: !_.isEmpty(showRenewalDesc)
        },
        description: {
            visible: (_.isEmpty(policyNumber) && _.isEmpty(hasFutureMTA)
            && _.isEmpty(showRenewalDesc)) || !_.isEmpty(contentsReferral)
        },
        titleDiv: {
            visible: _.isEmpty(hasFutureMTA)
        },
        blockFutureMTA: {
            visible: !_.isEmpty(hasFutureMTA)
        },
        blockFutureMTADescription: {
            visible: !_.isEmpty(hasFutureMTA)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default ContactUsPage;
