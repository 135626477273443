import { useErrorHandler } from 'nfum-portals-utils-react';
import useCms from './useCms';

const useMtaCms = (marketingInfo, setMarketingInfo) => {
    const { handleNotBlockingError } = useErrorHandler();
    const MTA_URL_PREFIX = '/bridge/account-management/mta';
    const { createCmsSiteMapPromise, createCmsContentPromise } = useCms();

    const MTA_CMS_CONFIG = [
        // NGH
        {
            productId: 'ngh',
            portalPageId: 'need-help',
            cmsPageId: 'need-help'
        },
        {
            productId: 'ngh',
            portalPageId: 'policychange-details',
            cmsPageId: 'policychange-details'
        },
        {
            productId: 'ngh',
            portalPageId: 'policychange-coverages',
            cmsPageId: 'policychange-coverages'
        },
        {
            productId: 'ngh',
            portalPageId: 'quote',
            cmsPageId: 'quote'
        },
        {
            productId: 'ngh',
            portalPageId: 'policychange-summary',
            cmsPageId: 'policychange-summary'
        },
        {
            productId: 'ngh',
            portalPageId: 'payment',
            cmsPageId: 'payment'
        },
        {
            productId: 'ngh',
            portalPageId: 'success',
            cmsPageId: 'success'
        },
        // Bespoke
        {
            productId: 'bespoke',
            portalPageId: 'need-help',
            cmsPageId: 'need-help'
        },
        {
            productId: 'bespoke',
            portalPageId: 'policychange-details',
            cmsPageId: 'policychange-details'
        },
        {
            productId: 'bespoke',
            portalPageId: 'quote',
            cmsPageId: 'quote'
        },
        {
            productId: 'bespoke',
            portalPageId: 'policychange-summary',
            cmsPageId: 'policychange-summary'
        },
        {
            productId: 'bespoke',
            portalPageId: 'payment',
            cmsPageId: 'payment'
        },
        {
            productId: 'bespoke',
            portalPageId: 'success',
            cmsPageId: 'success'
        },
        // Bespoke Coverages
        {
            productId: 'bespoke',
            portalPageId: 'bespoke-policychange-details',
            cmsPageId: 'bespoke-policychange-details'
        },
        {
            productId: 'bespoke',
            portalPageId: 'bespoke-policychange-summary',
            cmsPageId: 'bespoke-policychange-summary'
        },
        {
            productId: 'bespoke',
            portalPageId: 'bespoke-success',
            cmsPageId: 'bespoke-success'
        },
    ];

    const getCmsContentForMta = (productCode) => {
        createCmsSiteMapPromise().then((siteMapInfo) => {
            const allPagesPromises = [];
            MTA_CMS_CONFIG
                .filter((configItem) => configItem.productId === productCode)
                .forEach((page) => {
                    const siteId = siteMapInfo?.find((site) => site.url === `${MTA_URL_PREFIX}/${productCode}/${page.cmsPageId}/`)?.id;
                    if (siteId) {
                        allPagesPromises.push(createCmsContentPromise(siteId));
                    }
                });
            const newMarketingInfo = {};
            Promise.all(allPagesPromises).then((allPagesCmsContent) => {
                allPagesCmsContent.forEach((pageContent) => {
                    MTA_CMS_CONFIG
                        ?.filter((page) => page.cmsPageId === pageContent.routeSegment
                            && page.productId === productCode).forEach((configItem) => {
                            newMarketingInfo[configItem.portalPageId] = pageContent;
                        });
                });
                setMarketingInfo({
                    ...marketingInfo,
                    ...newMarketingInfo
                });
            });
        }).catch((err) => {
            handleNotBlockingError(err);
        });
    };

    return {
        getCmsContentForMta
    };
};

export default useMtaCms;
