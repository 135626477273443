import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    ViewModelForm, ViewModelServiceContext
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { useModal } from '@jutro/components';
import { EntityUtil } from 'gw-portals-util-js';
import { useCurrency, useCommonTagManager } from 'nfum-portals-utils-react';
import { NfumAccordionHeader, NfumConfirmModal } from 'nfum-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './FineArtsCard.metadata.json5';
import styles from './FineArtsCard.module.scss';
import messages from './FineArtsCard.messages';
import FineArtsAddedItems from './FineArtsAddedItems/FineArtsAddedItems';
import FineArtsItem from './FineArtsItem/FineArtsItem';
import FineArtsModal from './FineArtsModal/FineArtsModal';

function FineArtsCard(props) {
    const {
        wizardData: policyChangeVM, updateWizardData, prevFineArts: fineArtsOnPolicy
    } = props;
    const { showModal } = useModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);
    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });
    const {
        pushLinkClickInfo,
        pushAccordionOpenInfo
    } = useCommonTagManager();
    const EXISTING_FINE_ARTS_MAX = 21;
    const NEW_FINE_ARTS_MAX = 5;
    const [isLimitReached, setIsLimitReached] = useState(false);
    const existingFineArts = _.get(policyChangeVM, 'lobData.homeLine.lineCoverages.fineArts.children').filter((fineArt) => {
        const isInExisting = fineArtsOnPolicy
            .find((existingFineArt) => existingFineArt.value.fixedID === fineArt.value.fixedID)
            !== undefined;

        if (isInExisting) {
            return true;
        }

        return false;
    });

    const newFineArts = useMemo(() => {
        return _.get(policyChangeVM, 'lobData.homeLine.lineCoverages.fineArts.children').filter((fineArt) => {
            if (fineArt.value.fixedID === undefined) {
                return true;
            }

            const isInExisting = fineArtsOnPolicy
                .find((existingFineArt) => existingFineArt.value.fixedID === fineArt.value.fixedID)
                !== undefined;

            if (isInExisting) {
                return false;
            }

            return true;
        });
    }, [policyChangeVM, fineArtsOnPolicy]);

    const openAddFineArtsModal = useCallback(async (items = []) => {
        const newfineArtVM = viewModelService.create(
            { tempID: EntityUtil.nextId() },
            'pc',
            'nfum.edge.capabilities.policyjob.lob.homeline.coverages.dto.FineArtDTO_NFUM'
        );

        const { type, data: formData } = await showModal(
            <FineArtsModal
                fineArtVM={newfineArtVM}
                viewModelService={viewModelService}
                itemsToAdd={items}
                newFineArts={newFineArts}
            />
        );

        if (type === 'CANCEL') {
            return [];
        }

        items.push(formData);

        if (type === 'REOPEN') {
            await openAddFineArtsModal(items);
        }

        return items;
    }, [viewModelService, newFineArts, showModal]);

    const addItem = useCallback(async () => {
        if (newFineArts.length > NEW_FINE_ARTS_MAX - 1) {
            setIsLimitReached(true);
            return;
        }
        const items = await openAddFineArtsModal();

        const scheduledItems = _.get(policyChangeVM, 'lobData.value.homeLine.lineCoverages.fineArts') || [];
        const newPolicyChangeVM = viewModelService.clone(policyChangeVM);
        _.set(newPolicyChangeVM, 'lobData.value.homeLine.lineCoverages.fineArts', [...scheduledItems, ...items.map((item) => item.value)]);
        updateWizardData(newPolicyChangeVM);
    }, [
        policyChangeVM,
        openAddFineArtsModal,
        viewModelService,
        updateWizardData,
        newFineArts
    ]);

    useEffect(() => {
        if (newFineArts.length <= NEW_FINE_ARTS_MAX - 1) {
            setIsLimitReached(false);
        }
    }, [newFineArts]);

    const removeItem = useCallback(async (fineArtVM) => {
        const { fixedID, tempID } = fineArtVM.value;
        const { type } = await showModal(
            <NfumConfirmModal variant="variant3" />
        );

        if (type === 'CANCEL') {
            return;
        }

        const scheduledItems = _.get(policyChangeVM, 'lobData.value.homeLine.lineCoverages.fineArts') || [];
        const newPolicyChangeVM = viewModelService.clone(policyChangeVM);
        const newScheduledItems = scheduledItems.filter((item) => (fixedID !== undefined
            ? item.fixedID !== fixedID
            : item.tempID !== tempID));
        _.set(newPolicyChangeVM, 'lobData.value.homeLine.lineCoverages.fineArts', newScheduledItems);
        updateWizardData(newPolicyChangeVM);
    }, [
        policyChangeVM,
        viewModelService,
        updateWizardData,
        showModal
    ]);

    const editItem = useCallback(async (fineArtVM, isExisting) => {
        const { fixedID, tempID } = fineArtVM.value;
        const fineArtToEditVM = viewModelService.clone(fineArtVM);
        const { type, data: formData } = await showModal(
            <FineArtsModal
                isEditMode
                fineArtVM={fineArtToEditVM}
                viewModelService={viewModelService}
                policyChangeVM={policyChangeVM}
                isExisting={isExisting}
            />
        );

        if (type === 'CANCEL') {
            return [];
        }

        const scheduledItems = _.get(policyChangeVM, 'lobData.value.homeLine.lineCoverages.fineArts') || [];

        const index = _.get(policyChangeVM, 'lobData.value.homeLine.lineCoverages.fineArts').findIndex((item) => (fixedID !== undefined
            ? item.fixedID === fixedID
            : item.tempID === tempID));

        scheduledItems[index] = {
            ...scheduledItems[index],
            ...formData.value
        };

        _.set(policyChangeVM, 'lobData.value.homeLine.lineCoverages.fineArts', scheduledItems);
        updateWizardData(policyChangeVM);

        return [];
    }, [viewModelService, policyChangeVM, updateWizardData, showModal]);

    const generateFineArtsItemsOverrides = useCallback(() => {
        const covOverrides = existingFineArts.map((fineArt, index) => {
            return {
                [`fineArtsItem${index}`]: {
                    fineArtVM: fineArt,
                    onRemoveItem: removeItem,
                    onEditItem: (fineArtVM) => editItem(fineArtVM, true),
                    isExisting: true
                }
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [existingFineArts, removeItem, editItem]);

    const getHeaderTitle = useCallback((itemType) => {
        if (itemType === category.FINE_ART) {
            return translator(messages.fineArts);
        }
        if (itemType === category.WINES) {
            return translator(messages.wineCollection);
        }
        if (itemType === category.STAMPS_MEDALS_COINS) {
            return translator(messages.stampsMedalsAndCoins);
        }
        return translator(messages.otherCollections);
    }, [category, translator]);

    const generateNewFineArtsItemsOverrides = useCallback(() => {
        const covOverrides = newFineArts.map((fineArt, index) => {
            return {
                [`newFineArtsItem${index}`]: {
                    fineArtVM: fineArt,
                    onRemoveItem: removeItem,
                    onEditItem: (fineArtVM) => editItem(fineArtVM, false)
                }
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [newFineArts, removeItem, editItem]);

    const getFineArtsByItemTypesFiltered = useCallback((fineArtItems) => {
        if (fineArtItems.length === 0) return [];

        const map = new Map();
        fineArtItems.forEach((item) => {
            const { itemType } = item.value;
            if (!map.has(itemType)) {
                map.set(itemType, { name: getHeaderTitle(itemType), items: [item] });
            } else {
                map.get(itemType).items.push(item);
            }
        });

        const newFineArtsTypes = Array.from(map.values());

        return newFineArtsTypes;
    }, [getHeaderTitle]);

    const generateOverrides = useCallback(() => {
        const newFineArtsTypes = getFineArtsByItemTypesFiltered(newFineArts);
        const existingFineArtsTypes = getFineArtsByItemTypesFiltered(existingFineArts);

        const fineArtsAddedOverrides = newFineArtsTypes
            .map((item, index) => {
                return {
                    [`fineArtsAddedItems${index}`]: {
                        itemsModel: item,
                        index: index,
                        editItem,
                        removeItem,
                        isExistingItems: false
                    }
                };
            });

        const fineArtsExistingOverrides = existingFineArtsTypes
            .map((item, index) => {
                return {
                    [`fineArtsExistingItems${index}`]: {
                        itemsModel: item,
                        index: index,
                        editItem,
                        removeItem,
                        isExistingItems: true
                    }
                };
            });

        return Object.assign({},
            ...fineArtsAddedOverrides,
            ...fineArtsExistingOverrides);
    }, [
        getFineArtsByItemTypesFiltered,
        editItem,
        removeItem,
        newFineArts,
        existingFineArts
    ]);

    const onFindAgencyClicked = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/agent-offices/';
        pushLinkClickInfo(translator(messages.contactYourAgent), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const renderAccordionHeader = (isOpen, title) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
                isOpenCloseMessage={false}
            />
        );
    };

    const fineArts = _.get(policyChangeVM, 'previousLobData.homeLine.lineCoverages.fineArts.value');
    const isFineArtsCov = !!fineArts;
    const [singleItemLimit] = useState(_.get(policyChangeVM, 'previousLobData.value.homeLine.lineCoverages.fineArtsSingleItemLimit'));

    const onUpdateOpenAccordion = useCallback((accordionCardsIds) => {
        const openCardId = accordionCardsIds && accordionCardsIds.length > 0
            ? accordionCardsIds[0] : null;
        switch (openCardId) {
            case 'newFineArtsItemsAccordion':
                pushAccordionOpenInfo(translator(messages.newlyAddedFineArts));
                break;
            case 'existingFineArtsItemsAccordion':
                pushAccordionOpenInfo(translator(messages.existingFineArts));
                break;
            default:
                // do nothing
        }
    }, [pushAccordionOpenInfo, translator]);

    const overrideProps = {
        existingFineArtsItemsAccordion: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.existingFineArts
                );
            }
        },
        newFineArtsItemsAccordion: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.newlyAddedFineArts
                );
            }
        },
        fineArtsLimitShort: {
            content: translator(
                messages.fineArtsLimitShort,
                { limit: currencyFormatter.formatCurrency(singleItemLimit) }
            )
        },
        fineArtsLimitLong: {
            content: translator(
                messages.fineArtsLimitLong,
                { limit: currencyFormatter.formatCurrency(singleItemLimit) }
            )
        },
        fineArtsDescriptionShort: {
            visible: (!isFineArtsCov
                || existingFineArts.length === 0
                || existingFineArts.length >= EXISTING_FINE_ARTS_MAX)
                && !isLimitReached
        },
        fineArtsDescriptionLong1: {
            visible: isFineArtsCov
                && existingFineArts.length > 0
                && existingFineArts.length < EXISTING_FINE_ARTS_MAX
                && !isLimitReached
        },
        fineArtsDescriptionLong2: {
            visible: isFineArtsCov
                && existingFineArts.length > 0
                && existingFineArts.length < EXISTING_FINE_ARTS_MAX
                && !isLimitReached
        },
        additionalDescriptionContainer: {
            visible: !isLimitReached
        },
        limitReachedInfoContainer: {
            visible: isLimitReached
        },
        addButton: {
            disabled: isLimitReached,
            onClick: addItem
        },
        contactAgentInfoContainer: {
            visible: existingFineArts.length >= EXISTING_FINE_ARTS_MAX
        },
        existingValuablesList: {
            visible: existingFineArts.length > 0
                && existingFineArts.length < EXISTING_FINE_ARTS_MAX,
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        existingFineArtsItems: {
            data: existingFineArts
        },
        newlyAddedFineArtsList: {
            visible: newFineArts.length > 0,
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        newFineArtsItems: {
            data: newFineArts
        },
        fineArtsAdded: {
            data: getFineArtsByItemTypesFiltered(newFineArts)
        },
        fineArtsExisting: {
            data: getFineArtsByItemTypesFiltered(existingFineArts)
        },
        contactYourAgent: {
            onClick: onFindAgencyClicked
        },
        ...generateFineArtsItemsOverrides(),
        ...generateNewFineArtsItemsOverrides(),
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            fineArtsItem: FineArtsItem,
            fineArtsAddedItems: FineArtsAddedItems
        }
    };

    return (
        <ViewModelForm
            model={policyChangeVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

FineArtsCard.propTypes = wizardProps;
export default FineArtsCard;
