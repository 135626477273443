/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {  Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import classNames from 'classnames';

import messages from './NfumAccordionHeader.messages';
import styles from './NfumAccordionHeader.module.scss';

function NfumAccordionHeader(props) {
    const translator = useContext(TranslatorContext);

    const {
        isOpen,
        title,
        openMessage,
        isOpenCloseMessage = true
    } = props;

    return (
        <React.Fragment>
            <div className={styles.accordionChevronContainer}>
                <Icon icon= {isOpen? "gw-chevron-right": "gw-expand-more"}/>
            </div>
            <div className={styles.accordionTitleContainer}>
                <h2 className={styles.accordionTitle}>{ translator(title) }</h2>
                { isOpenCloseMessage && <span aria-hidden="true" role="presentation" className={styles.accordionOpenMessage}>
                    { isOpen ? translator(messages.close) : translator(openMessage) }
                </span>}
            </div>
        </React.Fragment>
    );
}

NfumAccordionHeader.propTypes = {
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    openMessage: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    isOpen: PropTypes.bool
};

NfumAccordionHeader.defaultProps = {
    openMessage: {
        id: 'nfum.platform.label.accordion.Open',
        defaultMessage: 'Open'
    }
};

export default NfumAccordionHeader;