import React, {
    useCallback, useContext, useMemo, useState, useEffect
} from 'react';
import moment from 'moment';
import {
    ViewModelForm, ViewModelServiceContext
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { NfumAccordionHeader, NfumConfirmModal } from 'nfum-components-platform-react';
import { useModal } from '@jutro/components';
import { EntityUtil } from 'gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency, useCommonTagManager } from 'nfum-portals-utils-react';
import metadata from './BespokeValuables.metadata.json5';
import styles from './BespokeValuables.module.scss';
import messages from './BespokeValuables.messages';
import BespokeValuablesModel from '../BespokeValuablesModel/BespokeValuablesModel';

const generateLineCoveragesPath = (submissionVM) => (lobPath) => (coverage) => {
    const lobCoverages = _.get(submissionVM, lobPath);
    if (lobCoverages) {
        const lobCoverageIndex = lobCoverages?.findIndex(
            (lobCoverage) => lobCoverage.displayName === coverage
                || lobCoverage.name === coverage || lobCoverage.publicID === coverage
        );
        return `${lobPath}[${lobCoverageIndex}]`;
    }

    return lobPath;
};

const generateHighValueItemsPath = (submissionVM) => {
    const lobSchedulesPath = 'lobData.homeLine.lineCoverages.schedules.value';
    const lobScheduleItemsPath = generateLineCoveragesPath(submissionVM)(lobSchedulesPath)('ScheduleItems');
    return lobScheduleItemsPath;
};

function BespokeValuables(props) {
    const {
        wizardData: policyChangeVM, updateWizardData, isDisabled, valuablesSingleItemLimit
    } = props;
    const { showModal } = useModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const highValueItemsPath = generateHighValueItemsPath(policyChangeVM);
    const translator = useContext(TranslatorContext);
    const { formatCurrency } = useCurrency();
    const [showWarning, setShowWarning] = useState(false);
    const categoriesAdded = useMemo(() => [], []);
    const categoriesExisting = useMemo(() => [], []);
    const sortValuables = (valuables) => {
        return valuables
            .sort(
                (a, b) => {
                    // eslint-disable-next-line max-len
                    return a.itemData.ArticleType.typeCodeValue.localeCompare(b.itemData.ArticleType.typeCodeValue);
                }
            );
    };
    const {
        pushAccordionOpenInfo
    } = useCommonTagManager();

    const scheduleItemsPath = 'lobData.homeLine.lineCoverages.schedules.children[0].scheduleItems.value';
    const getNewValuables = () => {
        const valuables = _.get(policyChangeVM, scheduleItemsPath) || [];
        return valuables.filter((valuable) => valuable.tempID !== undefined);
    };
    const getExistingValuables = () => {
        const valuables = _.get(policyChangeVM, scheduleItemsPath) || [];
        return valuables.filter((valuable) => valuable.tempID === undefined);
    };
    const existingValuables = getExistingValuables();
    const [newValuables, setNewValuables] = useState(getNewValuables());
    const openHighValueItemsModal = useCallback(async (items = []) => {
        const { type, data: formData } = await showModal(
            <BespokeValuablesModel
                viewModelService={viewModelService}
                itemCount={newValuables.length + items.length}
            />
        );

        if (type === 'CANCEL') {
            return [];
        }

        items.push(formData);

        if (type === 'REOPEN') {
            await openHighValueItemsModal(items);
        }

        return items;
    }, [
        viewModelService,
        newValuables,
        showModal]);

    const mapHighValueItemToDTO = useCallback((itemData, {
        publicID, fixedID, tempID, itemNumber
    } = {}) => {
        const dto = {
            '@deserialization-class': 'edge.capabilities.policycommon.coverage.schedule.dto.patterns.SimpleScheduledItemDTO',
            itemData: {
                ArticleType: {
                    typeCodeValue: itemData.category
                },
                ArticleDescription: {
                    stringValue: itemData.description
                },
                ArticleValuationMethod: {
                    booleanValue: itemData.professionalValuation
                },
                ArticleSubType: {
                    typeCodeValue: itemData.type
                },
                ArticleAlwaysWorn: {
                    booleanValue: itemData.alwaysWorn
                },
                ArticleSafePlaceKept: {
                    typeCodeValue: itemData.notBeingWorn
                },
                ArticleOtherPlaceKept: {
                    stringValue: itemData.otherKeptPlace
                },
                ArticleValuationDate: {
                    dateValue: itemData.valuationDone
                },
                ArticleReplacementCost: {
                    bigDecimal: Number(`${itemData.replacementCost}`.replaceAll(',', ''))
                },
                ArticleMakeType: {
                    typeCodeValue: itemData.category === 'Watches' ? itemData.watchMake : itemData.gunMake
                },
                ArticleGunOnDisplay: {
                    booleanValue: itemData.gunDisplay
                },
                ArticlePartPair: {
                    booleanValue: itemData.gunPair
                },
                ArticleSerialNumber: {
                    stringValue: itemData.serialNumber
                }
            }
        };

        dto.tempID = tempID || `hvi-${EntityUtil.nextId()}`;

        if (publicID) {
            dto.itemData.PublicID = {
                typeCodeValue: publicID
            };
        }
        if (fixedID) {
            dto.itemData.FixedID = {
                stringValue: fixedID
            };
        }
        if (itemNumber) {
            dto.itemNumber = itemNumber;
        }

        return dto;
    }, []);

    const mapNewHighValueItemToDTO = useCallback((itemData) => {
        const {
            ArticleType,
            ArticleDescription,
            ArticleValuationMethod,
            ArticleSubType,
            ArticleAlwaysWorn,
            ArticleSafePlaceKept,
            ArticleOtherPlaceKept,
            ArticleValuationDate,
            ArticleReplacementCost,
            ArticleMakeType,
            ArticleSerialNumber,
            ArticlePartPair,
            ArticleGunOnDisplay,
            PublicID,
        } = itemData;
    let notBeingWorn = '';
    let otherKeptPlace = '';
    const category = ArticleType?.typeCodeValue;
    const description = ArticleDescription?.stringValue;
    const professionalValuation =  ArticleValuationMethod?.booleanValue ;
    const type = ArticleSubType?.typeCodeValue;
    const alwaysWorn = ArticleAlwaysWorn?.booleanValue;

    if (ArticleSafePlaceKept ||  !alwaysWorn) {
      notBeingWorn = ArticleSafePlaceKept?.typeCodeValue   
    }

    if (ArticleOtherPlaceKept || !alwaysWorn || notBeingWorn === 'Other') {
        otherKeptPlace = ArticleOtherPlaceKept?.stringValue 
    }
    
    const valuationDone = ArticleValuationDate?.dateValue;
    const replacementCost = formatCurrency(`${ArticleReplacementCost?.bigDecimal}`?.replaceAll(',', ''), true, false);
    const watchMake = ArticleMakeType?.typeCodeValue;
    const gunMake = ArticleMakeType?.typeCodeValue;
    const serialNumber = ArticleSerialNumber?.stringValue;
    const gunPair = ArticlePartPair?.booleanValue;
    const gunDisplay = ArticleGunOnDisplay?.booleanValue;
    const publicId =  PublicID?.typeCodeValue ? PublicID?.typeCodeValue : '';
    const agreedValue =  ArticleValuationMethod?.booleanValue;
        return {
            category,
            description,
            professionalValuation,
            type,
            alwaysWorn,
            notBeingWorn,
            otherKeptPlace,
            valuationDone,
            replacementCost,
            watchMake,
            gunMake,
            serialNumber,
            gunPair,
            gunDisplay,
            publicId,
            agreedValue,
        };
    }, [formatCurrency]);

    const addItem = useCallback(async () => {
        if (newValuables.length > 4) {
            setShowWarning(true);
            return;
        }
        const items = await openHighValueItemsModal();
        const valuables = [...newValuables, ...items.map((item) => mapHighValueItemToDTO(item))];
        const sortedValuables = sortValuables(valuables);
        setNewValuables(sortedValuables);
        const newSubmissionVM = viewModelService.clone(policyChangeVM);
        _.set(newSubmissionVM, `${highValueItemsPath}.scheduleItems`, [...existingValuables, ...sortedValuables]);
        updateWizardData(newSubmissionVM);
    }, [openHighValueItemsModal,
        policyChangeVM,
        highValueItemsPath,
        viewModelService,
        updateWizardData,
        mapHighValueItemToDTO,
        existingValuables,
        newValuables]);

    const removeItem = useCallback(async (id, itemType) => {
        const { type } = await showModal(
            <NfumConfirmModal variant="variant3" />
        );
        if (type === 'CANCEL') {
            return;
        }
        if (itemType === 'newValuables') {
            const removedNewValuables = newValuables.filter((valuables) => valuables.tempID !== id);
            const sortedRemovedVals = sortValuables(removedNewValuables);
            setNewValuables(sortedRemovedVals);
            const newSubmissionVM = viewModelService.clone(policyChangeVM);
            _.set(newSubmissionVM, `${highValueItemsPath}.scheduleItems`, [...existingValuables, ...sortedRemovedVals]);
            updateWizardData(newSubmissionVM);
        }
        if (itemType === 'existingValuables') {
            // eslint-disable-next-line max-len
            const remvdExstngVal = existingValuables.filter((val) => val.itemData.PublicID.typeCodeValue !== id);
            const sortedRemovedVals = sortValuables(remvdExstngVal);
            const newSubmissionVM = viewModelService.clone(policyChangeVM);
            _.set(newSubmissionVM, `${highValueItemsPath}.scheduleItems`, [...sortedRemovedVals, ...newValuables]);
            updateWizardData(newSubmissionVM);
        }
    }, [
        existingValuables,
        highValueItemsPath,
        newValuables,
        policyChangeVM,
        updateWizardData,
        viewModelService,
        showModal]);

    const editItem = useCallback(async (lobPath, item, newlyAddedItem) => {
        const initialFormData = mapNewHighValueItemToDTO(item.itemData);

        const { type, data: formData } = await showModal(
            <BespokeValuablesModel
                isEditMode
                initialFormData={initialFormData}
                viewModelService={viewModelService}
                newlyAddedItem={newlyAddedItem}
            />
        );

        if (type === 'CANCEL') {
            return;
        }

        const scheduleItems = _.get(policyChangeVM, lobPath);
        const index = _.get(policyChangeVM, lobPath).findIndex((hvi) => {
            return hvi?.itemData?.PublicID?.typeCodeValue
                ? hvi?.itemData?.PublicID?.typeCodeValue === item.itemData?.PublicID?.typeCodeValue
                : hvi?.tempID === item.tempID;
        });
        scheduleItems[index] = mapHighValueItemToDTO(
            _.merge(initialFormData, formData),
            {
                publicID: item.itemData?.PublicID?.typeCodeValue,
                fixedID: item.itemData?.FixedID?.stringValue,
                tempID: item.tempID,
                itemNumber: item.itemNumber
            }
        );

        _.set(policyChangeVM, lobPath, scheduleItems);
        updateWizardData(policyChangeVM);

        if (type === 'REOPEN') {
            addItem();
        }
    }, [mapNewHighValueItemToDTO,
        policyChangeVM,
        viewModelService,
        mapHighValueItemToDTO,
        updateWizardData,
        addItem,
        showModal]);

    const getDateDisplay = useCallback((effectiveDate) => {
        if (effectiveDate) {
            const { year, month, day } = effectiveDate;
            return moment(new Date(year, month, day))
                .format('DD MMMM YYYY');
        }
        return '';
    }, []);

    const getBooleanValue = useCallback((val) => {
        if (!_.isEmpty(val)) {
            if (val.booleanValue) {
                return translator(messages.yes);
            }
            return translator(messages.no);
        }
        return '';
    }, [translator]);

    const getArticleValuableType = useCallback((articleSubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecifiedValuableType_NFUM.${articleSubType}`,
            defaultMessage: articleSubType
        }) : '';
    }, [translator]);

    const getArticleMakeType = useCallback((articleSubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecfdValuableMake_NFUM.${articleSubType}`,
            defaultMessage: articleSubType
        }) : '';
    }, [translator]);

    const truncateString = useCallback((str) => {
        if (str.length > 60) {
            return str.substring(0, 59);
        }
        return str;
    }, []);

    const renderAccordionHeader = (isOpen, title) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
                isOpenCloseMessage={false}
            />
        );
    };

    // eslint-disable-next-line max-len
    const generateExistingValuablesOverrides = useCallback(() => { /* NOSONAR: GW Jutro specific convention */
        const lobPath = `${highValueItemsPath}.scheduleItems`;
        const covOverrides = existingValuables.map((changedField, index) => {
            const {
                ArticleType,
                ArticleDescription,
                ArticleValuationMethod,
                ArticleSubType,
                ArticleAlwaysWorn,
                ArticleSafePlaceKept,
                ArticleOtherPlaceKept,
                ArticleValuationDate,
                ArticleReplacementCost,
                ArticleMakeType,
                ArticleSerialNumber,
                ArticlePartPair,
                ArticleGunOnDisplay,
                PublicID,
            } = changedField?.itemData;
            const safePlaceKept = ArticleSafePlaceKept ? ArticleSafePlaceKept?.typeCodeValue : '';
            const category = ArticleType ? ArticleType?.typeCodeValue : '';
            const articleSubType = ArticleSubType ? ArticleSubType?.typeCodeValue : '';
            const articleMakeType = ArticleMakeType ? ArticleMakeType?.typeCodeValue : '';
            let catValues = {};
            if (categoriesExisting.length === 0 && index === 0) {
                categoriesExisting.push(category);
                catValues[`articleCategory${index}`] = {
                    content: translator({
                        id: `typekey.ValuablesSpecItem_NFUM.${category}`,
                        defaultMessage: category
                    }),
                };
            }
            const isCategoryAdded = categoriesExisting.includes(category);
            if (!isCategoryAdded) {
                categoriesExisting.push(category);
                catValues[`articleCategory${index}`] = {
                    content: translator({
                        id: `typekey.ValuablesSpecItem_NFUM.${category}`,
                        defaultMessage: category
                    }),
                };
            }
            catValues = {
                ...catValues,
                category: category,
                [`articleDescription${index}`]: {
                    content: ArticleDescription ? truncateString(ArticleDescription.stringValue) : '',
                    visible: !!ArticleDescription
                },
                [`articleProfessionalValuation${index}`]: {
                    value: getBooleanValue(ArticleValuationMethod),
                    visible: false
                },
                [`articleValuationDone${index}`]: {
                    value: (ArticleValuationDate || ArticleValuationMethod.booleanValue) ? getDateDisplay(ArticleValuationDate.dateValue) : '',
                    visible: false
                },
                [`articleNotBeingWorn${index}`]: {
                    value: translator({
                        id: `typekey.ItemKeptWhenNotWorn_NFUM.${safePlaceKept}`,
                        defaultMessage: safePlaceKept
                    }),
                    visible: false
                },
                [`articleOtherKeptPlace${index}`]: {
                    value: ArticleOtherPlaceKept ? ArticleOtherPlaceKept?.stringValue : '',
                    visible: false
                },
                [`articleAgreedValue${index}`]: {
                    content: getBooleanValue(ArticleValuationMethod),
                    visible: false
                },
                [`articleWatchMake${index}`]: {
                    content: `${getArticleMakeType(articleMakeType)}, `,
                    visible: !_.isEmpty(ArticleMakeType)
                        && !_.isEmpty(ArticleType)
                        && ArticleType.typeCodeValue === 'Watches'
                },
                [`articleGunMake${index}`]: {
                    content: `${getArticleMakeType(articleMakeType)}, `,
                    visible: !_.isEmpty(ArticleMakeType)
                        && !_.isEmpty(ArticleType)
                        && ArticleType.typeCodeValue === 'Guns'
                },
                [`articleSerialNumber${index}`]: {
                    value: ArticleSerialNumber ? ArticleSerialNumber?.stringValue : '',
                    visible: false
                },
                [`articleGunPair${index}`]: {
                    value: ArticlePartPair ? ArticlePartPair?.booleanValue : '',
                    visible: false
                },
                [`articleGunDisplay${index}`]: {
                    value: ArticleGunOnDisplay ? ArticleGunOnDisplay?.booleanValue : '',
                    visible: false
                },
                [`articleValuableType${index}`]: {
                    content: `${getArticleValuableType(articleSubType)}, `,
                    visible: !_.isEmpty(ArticleSubType)
                        && !_.isEmpty(ArticleType)
                        && ArticleType.typeCodeValue === 'JewelleryAndGemstones'
                },
                [`articleReplacementCost${index}`]: {
                    value: formatCurrency(ArticleReplacementCost.bigDecimal,
                        true),
                    visible: !_.isEmpty(ArticleReplacementCost)
                },
                [`articleAlwaysWorn${index}`]: {
                    value: getBooleanValue(ArticleAlwaysWorn),
                    visible: false
                },
                [`editButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => editItem(lobPath, changedField, false)
                },
                [`deleteButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => {
                        removeItem(
                            PublicID.typeCodeValue,
                            'existingValuables'
                        );
                    }
                }
            };
            return catValues;
        });
        return Object.assign({}, ...covOverrides);
    }, [highValueItemsPath,
        existingValuables,
        categoriesExisting,
        truncateString,
        getBooleanValue,
        getDateDisplay,
        translator,
        getArticleMakeType,
        getArticleValuableType, formatCurrency, isDisabled, editItem, removeItem]);

    // eslint-disable-next-line max-len
    const generateNewValuablesOverrides = useCallback(() => { /* NOSONAR: GW Jutro specific convention */
        const lobPath = `${highValueItemsPath}.scheduleItems`;
        const covOverrides = newValuables.map((changedField, index) => {
            const {
                ArticleType,
                ArticleDescription,
                ArticleValuationMethod,
                ArticleSubType,
                ArticleAlwaysWorn,
                ArticleSafePlaceKept,
                ArticleOtherPlaceKept,
                ArticleValuationDate,
                ArticleReplacementCost,
                ArticleMakeType,
                ArticleSerialNumber,
                ArticlePartPair,
                ArticleGunOnDisplay,
            } = changedField?.itemData;
            const safePlaceKept = ArticleSafePlaceKept ? ArticleSafePlaceKept?.typeCodeValue : '';
            const category = ArticleType ? ArticleType?.typeCodeValue : '';
            const articleSubType = ArticleSubType ? ArticleSubType?.typeCodeValue : '';
            const articleMakeType =  ArticleMakeType ? ArticleMakeType?.typeCodeValue : '';
            let catValues = {};
            if (categoriesAdded.length === 0 && index === 0) {
                categoriesAdded.push(category);
                catValues[`newArticleCategory${index}`] = {
                    content: translator({
                        id: `typekey.ValuablesSpecItem_NFUM.${category}`,
                        defaultMessage: category
                    }),
                };
            }
            const isCategoryAdded = categoriesAdded.includes(category);
            if (!isCategoryAdded) {
                categoriesAdded.push(category);
                catValues[`newArticleCategory${index}`] = {
                    content: translator({
                        id: `typekey.ValuablesSpecItem_NFUM.${category}`,
                        defaultMessage: category
                    }),
                };
            }
            catValues = {
                ...catValues,
                category: category,
                [`newArticleDescription${index}`]: {
                    value: ArticleDescription ? ArticleDescription?.stringValue : '',
                    visible: !!ArticleDescription
                },
                [`newArticleTypeFurs${index}`]: {
                    content: translator({
                        id: `typekey.ValuablesSpecItem_NFUM.${category}`,
                        defaultMessage: category
                    }),
                    visible: !!ArticleType
                    && ArticleType.typeCodeValue === 'Furs'
                },
                [`newArticleWatchMake${index}`]: {
                    content: `${getArticleMakeType(articleMakeType)}`,
                    visible: !!ArticleMakeType
                        && !!ArticleType
                        && ArticleType.typeCodeValue === 'Watches'
                },
                [`newArticleGunMake${index}`]: {
                    content: `${getArticleMakeType(articleMakeType)}`,
                    visible: !!ArticleMakeType
                        && !!ArticleType
                        && ArticleType.typeCodeValue === 'Guns'
                },
                [`newArticleProfessionalValuation${index}`]: {
                    value: getBooleanValue(ArticleValuationMethod),
                    visible: !_.isEmpty(ArticleValuationMethod) && (ArticleType.typeCodeValue === 'JewelleryAndGemstones' || ArticleType.typeCodeValue === 'Watches')
                },
                [`newArticleValuationDone${index}`]: {
                    value: (!ArticleValuationDate || !ArticleValuationMethod.booleanValue) ? '' : getDateDisplay(ArticleValuationDate.dateValue),
                    visible: !!ArticleValuationDate
                        && !!ArticleValuationMethod
                        && ArticleValuationMethod.booleanValue
                },
                [`newArticleNotBeingWorn${index}`]: {
                    value: translator({
                        id: `typekey.ItemKeptWhenNotWorn_NFUM.${safePlaceKept}`,
                        defaultMessage: safePlaceKept
                    }),
                    visible: !!safePlaceKept
                        && !!ArticleAlwaysWorn
                        && !ArticleAlwaysWorn.booleanValue
                },
                [`newArticleOtherKeptPlace${index}`]: {
                    value: ArticleOtherPlaceKept ? ArticleOtherPlaceKept.stringValue : '',
                    visible: !!ArticleOtherPlaceKept
                        && !!ArticleAlwaysWorn
                        && !ArticleAlwaysWorn.booleanValue
                        && safePlaceKept === 'Other'
                },
                [`newArticleAgreedValue${index}`]: {
                    value: getBooleanValue(ArticleValuationMethod),
                    visible: !_.isEmpty(ArticleValuationMethod) && (ArticleType.typeCodeValue === 'Guns' || ArticleType.typeCodeValue === 'Furs')
                },
                [`newArticleMake${index}`]: {
                    value: ArticleSafePlaceKept ? ArticleSafePlaceKept.typeCodeValue : '',
                    visible: false
                },
                [`newArticleSerialNumber${index}`]: {
                    value: ArticleSerialNumber ? ArticleSerialNumber.stringValue : '',
                    visible: !!ArticleSerialNumber && ArticleType.typeCodeValue === 'Guns'
                },
                [`newArticleGunPair${index}`]: {
                    value: getBooleanValue(ArticlePartPair),
                    visible: !!ArticlePartPair && ArticleType.typeCodeValue === 'Guns'
                },
                [`newArticleGunDisplay${index}`]: {
                    value: getBooleanValue(ArticleGunOnDisplay),
                    visible: !!ArticleGunOnDisplay && ArticleType.typeCodeValue === 'Guns'
                },
                [`newArticleValuableType${index}`]: {
                    content: `${getArticleValuableType(articleSubType)}`,
                    visible: !!ArticleSubType && ArticleType.typeCodeValue === 'JewelleryAndGemstones'
                },
                [`newArticleReplacementCost${index}`]: {
                    value: formatCurrency(ArticleReplacementCost.bigDecimal,
                        true),
                    visible: !!ArticleReplacementCost
                },
                [`newArticleAlwaysWorn${index}`]: {
                    value: ArticleAlwaysWorn?.booleanValue
                        ? translator(messages.yes)
                        : translator(messages.no),
                    visible: !_.isEmpty(ArticleAlwaysWorn) && (ArticleType.typeCodeValue === 'JewelleryAndGemstones' || ArticleType.typeCodeValue === 'Watches')
                },
                [`newEditButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => editItem(lobPath, changedField, true)
                },
                [`newDeleteButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => {
                        removeItem(
                            changedField.tempID,
                            'newValuables'
                        );
                    }
                }
            };
            return catValues;
        });
        return Object.assign({}, ...covOverrides);
    }, [highValueItemsPath,
        newValuables,
        categoriesAdded,
        translator,
        getArticleMakeType,
        getBooleanValue,
        getDateDisplay,
        getArticleValuableType, formatCurrency, isDisabled, editItem, removeItem]);

    const onUpdateOpenAccordion = useCallback((accordionCardsIds) => {
        const openCardId = accordionCardsIds && accordionCardsIds.length > 0
            ? accordionCardsIds[0] : null;
        switch (openCardId) {
            case 'newlyAddedvaluablesCard':
                pushAccordionOpenInfo(translator(messages.newlyAddedValuables));
                break;
            case 'existingValuablesCard':
                pushAccordionOpenInfo(translator(messages.existingValuables));
                break;
            default:
                // do nothing
        }
    }, [pushAccordionOpenInfo, translator]);

    const overrideProps = {
        existingValuablesCard: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.existingValuables
                );
            }
        },
        newlyAddedvaluablesCard: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.newlyAddedValuables
                );
            }
        },
        ValuablesItems: {
            data: existingValuables,
            visible: existingValuables.length > 0 && existingValuables.length < 21
        },
        newValuableItems: {
            data: newValuables,
            visible: newValuables.length > 0
        },
        addButton: {
            onClick: addItem
        },
        valuablesLimitValue: {
            content: formatCurrency(valuablesSingleItemLimit),
            visible: (existingValuables.length === 0
                || existingValuables.length > 20)
                && !showWarning
        },
        valuablesCtaDescription1: {
            visible: (existingValuables.length === 0
                || existingValuables.length > 20)
                && !showWarning
        },
        valuablesLimitValue1: {
            content: formatCurrency(valuablesSingleItemLimit),
            visible: existingValuables.length < 21
                && existingValuables.length > 0
                && !showWarning
        },
        valuablesCtaDescription3: {
            visible: existingValuables.length < 21
                && existingValuables.length > 0
                && !showWarning
        },
        valuablesCtaDescription4: {
            visible: (existingValuables.length < 21
                && existingValuables.length > 0)
                && !showWarning
        },
        valuablesCtaDescription2: {
            visible: !showWarning
        },
        contactAgentInfoContainer: {
            visible: existingValuables.length > 20
                && !showWarning
        },
        valuablesCtaDescription6: {
            visible: existingValuables.length > 20
        },
        valuablesWarningWraper: {
            visible: showWarning
        },
        newlyAddedvaluablesList: {
            visible: newValuables.length > 0,
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        existingValuablesList: {
            visible: existingValuables.length > 0 && existingValuables.length < 21,
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        ...generateNewValuablesOverrides(),
        ...generateExistingValuablesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={policyChangeVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

BespokeValuables.propTypes = wizardProps;
export default BespokeValuables;
