import React, {
    useCallback, useState, useContext, useEffect
} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import {
    useErrorHandler,
    POLICYCHANGE_ERROR_CODE,
    AppContext,
    VALIDATION_ERROR_CODE,
    MTA_STEPS,
    BESPOKE_MTA_STEPS,
    useCurrency
} from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import { useTagManager, NGHContext, useCleanPayload } from 'nfum-capability-policychange-common-react';
import PersonalLegalExpenses from 'nfum-capability-policychange-common-react/components/PersonalLegalExpenses/PersonalLegalExpenses';
import CyclingProtectionCoverage from 'nfum-capability-policychange-common-react/components/CyclingProtection/CyclingProtectionCoverage';
import ValuablesCard from 'nfum-capability-policychange-common-react/components/ValuablesCard/ValuablesCard';
import commonMessages from 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';
import metadata from './PolicyChangeDetailsPage.metadata.json5';
import styles from './PolicyChangeDetailsPage.module.scss';
import messages from './PolicyChangeDetailsPage.messages';
import ValuablesAwayCard from './ValuablesAwayCard/ValuablesAwayCard';
import ProtectingYourValuables from './ProtectingYourValuables/ProtectingYourValuables';

const helpguideUrl = 'https://www.nfumutual.co.uk/help-and-advice/calculating-home-contents';

const generateLineCoveragesPath = (submissionVM) => (lobPath) => (coverage) => {
    const lobCoverages = _.get(submissionVM, lobPath);
    if (lobCoverages) {
        const lobCoverageIndex = lobCoverages?.findIndex(
            (lobCoverage) => lobCoverage.publicID === coverage
                || lobCoverage.displayName === coverage
                || lobCoverage.name === coverage
        );
        return `${lobPath}[${lobCoverageIndex}]`;
    }

    return lobPath;
};

const generateHighValueItemsPath = (submissionVM) => {
    const lobSchedulesPath = 'lobData.homeLine.lineCoverages.schedules.value';
    const lobScheduleItemsPath = generateLineCoveragesPath(submissionVM)(lobSchedulesPath)('ScheduleItems');
    return lobScheduleItemsPath;
};

const generateReplacementCostPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsCov');
    const lobAccidentalCoverPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('HOMContentsCovValuablesReplacmntCostLvlLimit');
    return lobAccidentalCoverPath;
};

const generateDoesReplacementCostExceedLimitPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsCov');
    const lobDoesReplacementCostExceedLimitPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('HOMContentsCovReplacementCostExceedLimit');
    return lobDoesReplacementCostExceedLimitPath;
};

const generateUnspecifiedValuablesPath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const unspecifiedBelongingsName = 'HOMContentsAwayCovUnspecifiedBelongingsReplCost';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsAwayCov');
    return generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)(unspecifiedBelongingsName);
};

const generateUnspecifiedPedalCylePath = (submissionVM) => {
    const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
    const unspecifiedBelongingsName = 'HOMContentsAwayCovUnspecifiedPedalCyclesLimit';
    const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsAwayCov');
    return generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)(unspecifiedBelongingsName);
};

function PolicyChangeDetailsPage(props) { /* NOSONAR: pure declarative usage  */
    const {
        wizardData: policySubmissionVM, updateWizardData, authHeader, history
    } = props;
    const translator = useContext(TranslatorContext);
    const { returnToDashboard } = useWizardActions();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { initialValidation, onValidate, isComponentValid } = useValidation('PolicyDetailsChangePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [showWorkingOnQuoteText, setShowWorkingOnQuoteText] = useState(false);
    const [showSavedQuoteText, setShowSavedQuoteText] = useState(false);
    const [showContactUsTextWithAmendments, setShowContactUsTextWithAmendments] = useState(false);
    const [isWithinRenewalPeriod, setIsWithinRenewalPeriod] = useState(false);
    const [isLimitsValidationError, setIsLimitsValidationError] = useState(false);
    const RATING_LOADING_TIME_MS = _.get(policySubmissionVM.value, 'baseData.ratingServiceTimeOut_NFUM') * 1000;
    const MARKETING_LOADING_VIEW_DISPLAY_TIME_MS = 5000;
    const MAX_PREMIUM_ALLOWED = _.get(policySubmissionVM.value, 'baseData.maxPremiumAllowedToProceed_NFUM');
    const { setIsMarketingQuoteLoadingView } = useContext(AppContext);
    const { handleError, handleUWError } = useErrorHandler();
    const [showValuables, updateShowValuables] = useState(false);
    const [isContentsAvailable, setIsContentsAvailable] = useState(false);
    const [newContentsReplCost, setNewContentsReplCost] = useState('');
    const [isContentsReplCostsConfirmed, setIsContentsReplCostsConfirmed] = useState(null);
    const [isContentsReplCostValid, setIsContentsReplCostValid] = useState(true);
    const [showContentsReplReferralMessage, setShowContentsReplReferralMessage] = useState(false);
    const contentsCostCalcThresholdTwo = 60000;
    const contentsCostCalcThresholdThree = 100000;
    const [isContentsCovTierNew, setIsContentsCovTierNew] = useState('');
    const [isContentsCovTierOld, setIsContentsCovTierOld] = useState('');
    const [isNGHValid, setIsNGHValid] = useState(false);
    const { cleanNotUpdatedCoverages, cleanCostInfos } = useCleanPayload();
    const [previousContentsReplacementCost] = useState(_.get(policySubmissionVM, 'previousLobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid'));
    const [valuablesSingleArticleLimit] = useState(policySubmissionVM?.value?.lobData?.homeLine?.lineCoverages?.coverages?.find((cov) => cov.publicID === 'HOMContentsCov')?.terms?.find((term) => term.publicID === 'HOMContentsCovValuablesSingleArticleLimit')?.chosenTermValue);
    const [valuablesReplacementLimit] = useState(policySubmissionVM?.value?.lobData?.homeLine?.lineCoverages?.coverages?.find((cov) => cov.publicID === 'HOMContentsCov')?.terms?.find((term) => term.publicID === 'HOMContentsCovValuablesReplacmntCostLvlLimit')?.chosenTermValue);

    const {
        setIsNavigationDisabled
    } = useContext(NGHContext);
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushLinkClickInfo
    } = useTagManager();
    const MAX_PERIOD_START_DATE_DAYS_SHIFT = 30;
    const minDate = moment();
    const maxDate = moment().add(MAX_PERIOD_START_DATE_DAYS_SHIFT, 'days');
    const setLimitsValidationError = useCallback((isError) => {
        setIsLimitsValidationError(isError);
        setIsNavigationDisabled(isError);
    }, [setIsLimitsValidationError, setIsNavigationDisabled]);
    const producerDesc = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const isSevenDigit = useCallback((value) => {
        const regex = /^\d{1,7}(\.\d{0,2})?$/;
        return regex.test(value);
    }, []);
    const contentsCostValidator = [{
        name: 'isValidCurrency',
        valid: isSevenDigit,
        message: 'currency value within seven digits'
    }];
    const currencyFormatter = useCurrency();
    const JEWELLERY_WATCHES_LIMIT = 20000;

    // #region GTM EVENTS

    useEffect(() => {
        pushFormStepInfo(policySubmissionVM, isBespoke
            ? BESPOKE_MTA_STEPS.MTA_DETAILS
            : MTA_STEPS.MTA_DETAILS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #endregion GTM EVENTS

    const checkContentsCovTierLevelNew = useCallback((contentsReplCost) => {
        if (contentsReplCost <= contentsCostCalcThresholdTwo) {
            setIsContentsCovTierNew(1);
        } else if (contentsReplCost > contentsCostCalcThresholdTwo
            && contentsReplCost <= contentsCostCalcThresholdThree) {
            setIsContentsCovTierNew(2);
        } else if (contentsReplCost > contentsCostCalcThresholdThree) {
            setIsContentsCovTierNew(3);
        }
    }, []);

    const checkContentsCovTierLevelOld = useCallback((contentsReplCost) => {
        if (contentsReplCost <= contentsCostCalcThresholdTwo) {
            setIsContentsCovTierOld(1);
        } else if (contentsReplCost > contentsCostCalcThresholdTwo
            && contentsReplCost <= contentsCostCalcThresholdThree) {
            setIsContentsCovTierOld(2);
        } else if (contentsReplCost > contentsCostCalcThresholdThree) {
            setIsContentsCovTierOld(3);
        }
    }, []);

    useEffect(() => {
        checkContentsCovTierLevelOld(previousContentsReplacementCost);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const coverablesType = _.get(policySubmissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables');
        const isContentsIncluded = coverablesType !== 'buildingsOnly' && !_.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM');
        const ContentsAvailable = coverablesType !== 'buildingsOnly';
        setIsContentsAvailable(ContentsAvailable);
        updateShowValuables(isContentsIncluded);
    }, [policySubmissionVM]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            _.set(policySubmissionVM.value, changedPath, value);
            updateWizardData(policySubmissionVM);
        },
        [policySubmissionVM, updateWizardData]
    );

    const checkIfWithinRenewalPeriod = useCallback((effectiveDate) => {
        const isEffectiveDateValid = !!effectiveDate?.year
            && !!effectiveDate?.month
            && !!effectiveDate?.day;
        if (!isEffectiveDateValid) {
            setIsWithinRenewalPeriod(false);
            return;
        }
        const dateConversion = (date) => new Date(date.year, date.month, date.day, 0, 0, 0, 0);
        const { renewalStartDate_NFUM: renewalStartDate } = policySubmissionVM.baseData;
        const isBelowRenewalCycle = dateConversion(effectiveDate)
            <= dateConversion(renewalStartDate.value);
        setIsWithinRenewalPeriod(!isBelowRenewalCycle);
    }, [setIsWithinRenewalPeriod, policySubmissionVM]);

    useEffect(() => {
        const {
            effectiveDate
        } = policySubmissionVM.baseData;
        checkIfWithinRenewalPeriod(effectiveDate.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMidnightFromMomentDate = useCallback((date) => {
        return date.toDate().setHours(0, 0, 0, 0);
    }, []);

    const onEffectiveDateChange = useCallback((inputDate) => {
        const isStartDateEmpty = inputDate?.year === undefined
            && inputDate?.month === undefined
            && inputDate?.day === undefined;
        if (isStartDateEmpty) {
            return;
        }
        const periodStartDate = new Date(inputDate.year, inputDate.month, inputDate.day)
            .setHours(0, 0, 0, 0);
        const minimumDate = getMidnightFromMomentDate(minDate);
        const maximumDate = getMidnightFromMomentDate(maxDate);
        const minDateDTO = {
            day: minDate.date(),
            month: minDate.month(),
            year: minDate.year()
        };
        const maxDateDTO = {
            day: maxDate.date(),
            month: maxDate.month(),
            year: maxDate.year()
        };
        const isStartDateValid = !!inputDate.year
            && (inputDate?.month >= 0 && inputDate?.month <= 11)
            && !!inputDate.day;
        const newPolicyChangeVM = viewModelService.clone(policySubmissionVM);
        _.set(newPolicyChangeVM.value, 'baseData.minDate_NFUM', minDateDTO);
        _.set(newPolicyChangeVM.value, 'baseData.maxDate_NFUM', maxDateDTO);
        if (!isStartDateValid || periodStartDate < minimumDate || periodStartDate > maximumDate) {
            _.set(newPolicyChangeVM.value, 'baseData.effectiveDate', undefined);
        } else {
            _.set(newPolicyChangeVM.value, 'baseData.effectiveDate', inputDate);
        }
        updateWizardData(newPolicyChangeVM);
        checkIfWithinRenewalPeriod(_.get(newPolicyChangeVM.value, 'baseData.effectiveDate'));
    }, [
        updateWizardData,
        viewModelService,
        policySubmissionVM,
        checkIfWithinRenewalPeriod,
        maxDate,
        minDate,
        getMidnightFromMomentDate
    ]);

    const confirmContentsReplacementCostLimit = (value) => {
        setIsContentsReplCostsConfirmed(value);
        if (value) {
            // resetting the states for override Repl cost
            setNewContentsReplCost('');
            setShowContentsReplReferralMessage(false);
            setIsContentsCovTierNew('');
            _.set(policySubmissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid', previousContentsReplacementCost);
            updateWizardData(policySubmissionVM);
        }
    };

    const promiseWithTimeout = useCallback((promise) => {
        let timeoutId;
        const timeoutPromise = new Promise((reject) => {
            timeoutId = setTimeout(() => {
                reject(new Error(translator(messages.ratingServiceTimedOut)));
            }, RATING_LOADING_TIME_MS);
        });
        return {
            promiseOrTimeout: Promise.race([promise, timeoutPromise]),
            timeoutId,
        };
    }, [RATING_LOADING_TIME_MS, translator]);

    const handleWatchesJewelleryLimit = useCallback(() => {
        const homeScheduleItems = _.get(policySubmissionVM.value, 'lobData.homeLine.lineCoverages.schedules[0].scheduleItems');
        const jewellery = homeScheduleItems?.filter((item) => item.itemData.ArticleValuationMethod.typeCodeValue === 'Jewellery');
        const watches = homeScheduleItems?.filter((item) => item.itemData.ArticleValuationMethod.typeCodeValue === 'Watch');
        const jewelleryCost = jewellery?.map((a) => { return Number(`${a.itemData?.ArticleLimit?.bigDecimal}`); });
        const watchCost = watches?.map((a) => { return Number(`${a.itemData?.ArticleLimit?.bigDecimal}`); });
        if (jewelleryCost > JEWELLERY_WATCHES_LIMIT || watchCost > JEWELLERY_WATCHES_LIMIT) {
            handleUWError(null, policySubmissionVM.value.policyNumber);
        }
    }, [policySubmissionVM, handleUWError]);

    const Next = useCallback(async () => {
        try {
            if (showError) {
                history.push('/home');
            }
            cleanNotUpdatedCoverages(policySubmissionVM);
            cleanCostInfos(policySubmissionVM);
            policySubmissionVM.value = await EndorsementService
                .saveEndorsement([policySubmissionVM.value], authHeader);
            policySubmissionVM.value = await EndorsementService
                .quoteEndorsement(policySubmissionVM.value.jobID, true, authHeader);
            handleWatchesJewelleryLimit();
            return policySubmissionVM;
        } catch (error) {
            pushFormStepErrorInfo(policySubmissionVM, MTA_STEPS.MTA_DETAILS, error);
            handleWatchesJewelleryLimit();
            if (error?.appErrorCode === POLICYCHANGE_ERROR_CODE) {
                setShowWorkingOnQuoteText(false);
                setShowSavedQuoteText(false);
                setShowContactUsTextWithAmendments(true);
                setErrorMessage(translator(messages.midTermErrorOnline));
                setShowError(true);
            } else if (error?.appErrorCode === VALIDATION_ERROR_CODE) {
                setLimitsValidationError(true);
                policySubmissionVM.value = error.appData;
                updateWizardData(policySubmissionVM);
            } else {
                handleError(error, policySubmissionVM.value.policyNumber);
            }
            return false;
        }
    }, [
        showError,
        policySubmissionVM,
        EndorsementService,
        authHeader,
        history,
        translator,
        handleError,
        updateWizardData,
        pushFormStepErrorInfo,
        setLimitsValidationError,
        cleanNotUpdatedCoverages,
        cleanCostInfos,
        handleWatchesJewelleryLimit
    ]);

    const onNext = useCallback(async () => {
        const jobId = _.get(policySubmissionVM.value, 'jobID');
        const policyNumber = _.get(policySubmissionVM.value, 'policyNumber');
        if (showContentsReplReferralMessage || (isContentsCovTierNew > isContentsCovTierOld)) {
            history.push(`/contact-us?jobid=${jobId}&policyNumber=${policyNumber}&contentsReferral=true`);
        }
        if (!_.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM')) {
            return Next();
        }
        const isFrauded = _.get(policySubmissionVM, 'baseData.value.sirafraudCheckStatus_NFUM');
        if (isFrauded === true) {
            return history.push('/security-check');
        }
        const saveAndQuotePromise = new Promise((resolve, reject) => {
            cleanNotUpdatedCoverages(policySubmissionVM);
            cleanCostInfos(policySubmissionVM);
            EndorsementService.saveEndorsement(
                [policySubmissionVM.value],
                authHeader
            ).then((updateResponse) => {
                EndorsementService.quoteEndorsement(
                    updateResponse.jobID,
                    false,
                    authHeader
                ).then((quotedSubmission) => {
                    resolve(quotedSubmission);
                }).catch((error) => {
                    handleError(error, policySubmissionVM.value.policyNumber);
                });
            }).catch((error) => {
                reject(error);
            });
        });
        const { promiseOrTimeout, timeoutId } = promiseWithTimeout(saveAndQuotePromise);
        try {
            setIsMarketingQuoteLoadingView(true);
            const someSecondsDelayPromise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, MARKETING_LOADING_VIEW_DISPLAY_TIME_MS);
            });
            const results = await Promise.all([promiseOrTimeout, someSecondsDelayPromise]);
            const quotePromiseResponse = results[0];
            if (quotePromiseResponse.status === 'rejected') {
                setIsMarketingQuoteLoadingView(false);
                handleError(quotePromiseResponse.reason, policySubmissionVM.value.policyNumber);
            }
            if (results[0].message === translator(messages.ratingServiceTimedOut)) {
                handleError(quotePromiseResponse.reason, policySubmissionVM.value.policyNumber);
            }
            policySubmissionVM.value = quotePromiseResponse;
            setIsMarketingQuoteLoadingView(false);
            const totalPremium = _.get(policySubmissionVM.value, 'totalCost.amount');
            if (totalPremium > MAX_PREMIUM_ALLOWED) {
                return history.push('/security-check');
            }
            return policySubmissionVM;
        } catch (error) {
            pushFormStepErrorInfo(policySubmissionVM, MTA_STEPS.MTA_DETAILS, error);
            setIsMarketingQuoteLoadingView(false);
            if (error?.appErrorCode === POLICYCHANGE_ERROR_CODE) {
                setShowWorkingOnQuoteText(false);
                setShowSavedQuoteText(false);
                setShowContactUsTextWithAmendments(true);
                setErrorMessage(translator(messages.midTermErrorOnline));
                setShowError(true);
            } else {
                handleError(error, policySubmissionVM.value.policyNumber);
            }
            return false;
        } finally {
            clearTimeout(timeoutId);
        }
    }, [
        policySubmissionVM,
        showContentsReplReferralMessage,
        isContentsCovTierNew,
        isContentsCovTierOld,
        promiseWithTimeout,
        history,
        Next,
        EndorsementService,
        authHeader,
        setIsMarketingQuoteLoadingView,
        translator,
        MAX_PREMIUM_ALLOWED,
        pushFormStepErrorInfo,
        handleError,
        cleanNotUpdatedCoverages,
        cleanCostInfos]);

    const getReferralMessageHeader = () => {
        return translator(messages.GetInTouch);
    };

    const getReferralMessageDescription = () => {
        if (isContentsReplCostsConfirmed === false) {
            return translator(messages.ContentsLimitExceeded);
        }
        if (isWithinRenewalPeriod === true) {
            return translator(messages.WithinRenewalCycle);
        }
        if (isLimitsValidationError === true) {
            return translator(messages.limitsValidationErrorText);
        }
        return '';
    };

    const getReferralMessageText = () => {
        if (isLimitsValidationError === true) {
            return translator(messages.limitsValidationErrorDesc);
        }
        return '';
    };
    const validateReplCost = useCallback((value, path) => {
        setNewContentsReplCost(value);
        if (!isSevenDigit(value) || value === '') {
            setIsContentsReplCostValid(false);
        } else {
            _.set(policySubmissionVM, path, newContentsReplCost);
            updateWizardData(policySubmissionVM);
            setIsContentsReplCostValid(true);
        }
    }, [isSevenDigit, policySubmissionVM, newContentsReplCost, updateWizardData]);

    const checkNewContentsReplCosts = useCallback((value, path) => {
        validateReplCost(value, path);
        if (value < previousContentsReplacementCost) {
            setShowContentsReplReferralMessage(true);
        }
        checkContentsCovTierLevelNew(value);
    }, [validateReplCost, previousContentsReplacementCost, checkContentsCovTierLevelNew]);

    const handleContentCostOverride = (value, path) => {
        if (value?.length <= 7) {
            setNewContentsReplCost(value);
            // eslint-disable-next-line max-len, no-unused-expressions
            value.length === 0 ? setIsContentsReplCostValid(false) : setIsContentsReplCostValid(true);
            _.set(policySubmissionVM, path, newContentsReplCost);
            updateWizardData(policySubmissionVM);
        } else {
            setIsContentsReplCostValid(false);
        }
    };
    // Added in .jsx as additional styling needs to be added
    const renderContentsReplacementCostLabel = useCallback(() => {
        return (
            <span>
                {translator(messages.costOfReplacingContents)}
                <strong>
                    {currencyFormatter.formatCurrency(previousContentsReplacementCost)}
                </strong>
                {translator(messages.replacingContentsAccurate)}
            </span>
        );
    }, [previousContentsReplacementCost, currencyFormatter, translator]);

    const getLexVisible = useCallback(() => {
        const LEX_COV_ID = 'LEXPersonalLegalExpensesCov';
        const LEX_DUPLICATED_TERM_ID = 'LEXPersonalLegalExpensesCovHomeDuplicated';
        const LEX_REMOVE_CHARGE_TERM_ID = 'LEXPersonalLegalExpensesCovRemoveCharge';
        const YES_CODE = 'Yes';

        const lexCov = _.get(policySubmissionVM, 'lobData.value.lexLine.lineCoverages.coverages')
            ?.find((cov) => cov.publicID === LEX_COV_ID);
        if (!lexCov) {
            return false;
        }
        const isDuplicated = lexCov.terms
            ?.find((term) => term.publicID === LEX_DUPLICATED_TERM_ID)
            ?.chosenTermValue === YES_CODE;
        const isChargeRemoved = lexCov.terms
            ?.find((term) => term.publicID === LEX_REMOVE_CHARGE_TERM_ID)
            ?.chosenTermValue === YES_CODE;

        return !isDuplicated || !isChargeRemoved;
    }, [policySubmissionVM]);

    const onHelpGuideClick = useCallback(() => {
        pushLinkClickInfo(translator(messages.helpfulguidelink), helpguideUrl);
        window.open(helpguideUrl, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        effectiveDate: {
            tooltip: {
                text: translator(messages.EffectiveDateTooltip)
            },
            onBlur: onEffectiveDateChange,
            minDate: minDate.toDate(),
            maxDate: getMidnightFromMomentDate(maxDate),
            disabled: isLimitsValidationError === true
        },
        pageContainer: {
            visible: !showError
        },
        midTermErrorMessageContainer: {
            visible: showError
        },
        midTermError: {
            errorMessage: errorMessage,
            showWorkingOnQuoteText: showWorkingOnQuoteText,
            showSavedQuoteText: showSavedQuoteText,
            showContactUsTextWithAmendments: showContactUsTextWithAmendments,
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        contentsReplacementCostLabel: {
            renderLabel: renderContentsReplacementCostLabel
        },
        contentsReplacementCostValue: {
            value: isContentsReplCostsConfirmed,
            onValueChange: confirmContentsReplacementCostLimit,
            disabled: isLimitsValidationError === true
        },
        replacingContentsCostOverride: {
            visible: isContentsReplCostsConfirmed === false,
            onValueChange: handleContentCostOverride,
            value: newContentsReplCost,
            validators: contentsCostValidator,
            onBlur: checkNewContentsReplCosts,
            error: !isContentsReplCostValid
        },
        replContentsCostValidMsg: {
            visible: !isContentsReplCostsConfirmed && !isContentsReplCostValid,
            content: newContentsReplCost === '' ? translator(messages.replContentsCostValidMsg1) : translator(messages.replContentsCostValidMsg2)
        },
        contentsLimitExceededContainer: {
            visible: true
        },
        referralMessageContainer: {
            visible: isWithinRenewalPeriod === true
                || isLimitsValidationError === true
        },
        referralMessage: {
            header: getReferralMessageHeader(),
            description: getReferralMessageDescription(),
            description1: getReferralMessageText(),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        valuablesCardWrapper: {
            visible: showValuables
                && isWithinRenewalPeriod !== true
                && !_.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM')
        },
        valuablesAwayCardWrapper: {
            visible: showValuables
                && isWithinRenewalPeriod !== true
                && !_.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM')
        },
        contentsContainer: {
            visible: showValuables
                && isWithinRenewalPeriod !== true
                && !_.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM')
        },
        personalLegalExpensesWrapper: {
            visible: _.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM')
                && getLexVisible()
        },
        CyclingProtectionWrapper: {
            visible: _.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM') && isContentsAvailable
        },
        protectingYourValuables: {
            value: _.get(policySubmissionVM, 'submissionVM.lobData.homeLine.coverables.homhomeProperty.securityQuestions'),
            onValueChange: handleValueChange,
            isDisabled: isLimitsValidationError
        },
        helpfulguidelink: {
            onClick: onHelpGuideClick
        }

    };
    const generateBicycleExtensionCoverPath = (submissionVM) => {
        const lobCoveragesPath = 'lobData.homeLine.lineCoverages.coverages.value';
        const lobCoveragePath = generateLineCoveragesPath(submissionVM)(lobCoveragesPath)('HOMContentsCov');
        const lobPedalCycleExtensionPath = generateLineCoveragesPath(submissionVM)(`${lobCoveragePath}.terms`)('HOMContentsCovPedalCycleExtension');
        return [lobCoveragePath, lobPedalCycleExtensionPath];
    };

    const generateLegalExpensesCoverPath = useCallback((polChangeVM) => {
        const lobCoveragesPath = 'lobData.lexLine.lineCoverages.coverages.value';
        const lobCoveragePath = generateLineCoveragesPath(polChangeVM)(lobCoveragesPath)('LEXPersonalLegalExpensesCov');
        const lobPersonalLegalExpensesPath = generateLineCoveragesPath(polChangeVM)(`${lobCoveragePath}.terms`)('Level of Cover');
        return lobPersonalLegalExpensesPath;
    }, []);

    const legalExpensesCoverPath = generateLegalExpensesCoverPath(policySubmissionVM);
    const bicycleExtensionPath = generateBicycleExtensionCoverPath(policySubmissionVM);
    const highValueItemsPath = generateHighValueItemsPath(policySubmissionVM);
    const replacementCostPath = generateReplacementCostPath(policySubmissionVM);
    const doesReplacementCostExceedLimitPath = generateDoesReplacementCostExceedLimitPath(
        policySubmissionVM
    );
    const unspecifiedValuablesPath = generateUnspecifiedValuablesPath(policySubmissionVM);
    const unspecifiedPedalCyclePath = generateUnspecifiedPedalCylePath(policySubmissionVM);
    const highValueBicyclesPath = generateHighValueItemsPath(policySubmissionVM);

    const paths = {
        highValueItemsPath,
        replacementCostPath,
        doesReplacementCostExceedLimitPath,
        unspecifiedValuablesPath,
        highValueBicyclesPath,
        unspecifiedPedalCyclePath,
        bicycleExtensionPath,
        legalExpensesCoverPath
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            valuablesCard: (valuablesCardProps) => (
                <ValuablesCard
                    {...valuablesCardProps}
                    wizardData={policySubmissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={() => setLimitsValidationError(false)}
                    isDisabled={isLimitsValidationError}
                    valuablesSingleArticleLimit={valuablesSingleArticleLimit}
                    valuablesReplacementLimit={valuablesReplacementLimit}
                />
            ),
            valuablesAwayCard: (valuablesAwayCardProps) => (
                <ValuablesAwayCard
                    {...valuablesAwayCardProps}
                    wizardData={policySubmissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={() => setLimitsValidationError(false)}
                    isDisabled={isLimitsValidationError}
                />
            ),
            CyclingProtectionCoverage: (CyclingProtectionCoverageProps) => (
                <CyclingProtectionCoverage
                    {...CyclingProtectionCoverageProps}
                    wizardData={policySubmissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={() => setLimitsValidationError(false)}
                    isDisabled={isLimitsValidationError}
                />
            ),
            PersonalLegalExpenses: (PersonalLegalExpensesProps) => (
                <PersonalLegalExpenses
                    {...PersonalLegalExpensesProps}
                    wizardData={policySubmissionVM}
                    updateWizardData={updateWizardData}
                    paths={paths}
                    hideLimitsReferral={() => setLimitsValidationError(false)}
                    isDisabled={isLimitsValidationError}
                />
            ),
            protectingYourValuables: ProtectingYourValuables
        },
    };
    useEffect(() => {
        if (!isBespoke) {
            const isValid = isContentsReplCostsConfirmed || (!isContentsReplCostsConfirmed && isContentsReplCostValid && newContentsReplCost !== '');
            setIsNGHValid(isValid || !isContentsAvailable);
        }
    }, [
        isContentsAvailable,
        isContentsReplCostsConfirmed,
        isContentsReplCostValid,
        newContentsReplCost,
        isBespoke
    ]);
    const isNextDisabled = useCallback(() => {
        // isNGHValid is validated for NGH and excluded for bespoke
        return (!isNGHValid && !isBespoke)
            || isLimitsValidationError
            || isWithinRenewalPeriod
            || !isComponentValid;
    }, [isNGHValid, isBespoke, isLimitsValidationError, isWithinRenewalPeriod, isComponentValid]);

    return (
        <WizardPage
            onNext={onNext}
            disableNext={isNextDisabled()}
            skipWhen={initialValidation}
            showPrevious={false}
            nextLabel={(showError && commonMessages.returnToPortal) || commonMessages.reviewChanges}
            cancelLabel={commonMessages.cancelAllChanges}
            showNext
            showCancel={!showError}
            onCancel={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySubmissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PolicyChangeDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(withViewModelService(PolicyChangeDetailsPage));
