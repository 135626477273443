import React, {
    useCallback, useContext
} from 'react';
import _ from 'lodash';
import {
    ViewModelForm,
    ViewModelServiceContext
} from 'gw-portals-viewmodel-react';
import { EntityUtil } from 'gw-portals-util-js';
import { wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useCurrency } from 'nfum-portals-utils-react';
import { CallOrContactInfo, NfumConfirmModal } from 'nfum-components-platform-react';
import useTagManager from '../../hooks/useTagManager';
import metadata from './ValuablesCard.metadata.json5';
import styles from './ValuablesCard.module.scss';
import messages from './ValuablesCard.messages';
import ValuablesModal from '../ValuablesModal/ValuablesModal';

const generateLineCoveragesPath = (submissionVM) => (lobPath) => (coverage) => {
    const lobCoverages = _.get(submissionVM, lobPath);
    if (lobCoverages) {
        const lobCoverageIndex = lobCoverages?.findIndex(
            (lobCoverage) => lobCoverage.displayName === coverage
                || lobCoverage.name === coverage || lobCoverage.publicID === coverage
        );
        return `${lobPath}[${lobCoverageIndex}]`;
    }

    return lobPath;
};

const mapHighValueItemToDTO = (itemData, { publicID, tempID, itemNumber } = {}) => {
    const dto = {
        '@deserialization-class': 'edge.capabilities.policycommon.coverage.schedule.dto.patterns.SimpleScheduledItemDTO',
        itemData: {
            ArticleExcessType: {
                typeCodeValue: 'All Claims'
            },
            ArticleKeptinBank: {
                booleanValue: itemData.storage
            },
            ArticleValuationMethod: {
                typeCodeValue: itemData.type
            },
            ArticleGeographicLimit: {
                typeCodeValue: itemData.geoLimit
            },
            ArticleDeductible: {
                bigDecimal: 0.0000
            },
            ArticleType: {
                typeCodeValue: itemData.description
            },
            ArticleLimit: {
                bigDecimal: Number(`${itemData.replacementCost}`.replaceAll(',', ''))
            }
        }
    };

    dto.tempID = tempID || `hvi-${EntityUtil.nextId()}`;

    if (publicID) {
        dto.itemData.PublicID = {
            typeCodeValue: publicID
        };
    }

    if (itemNumber) {
        dto.itemNumber = itemNumber;
    }

    return dto;
};

const generateHighValueItemsPath = (submissionVM) => {
    const lobSchedulesPath = 'lobData.homeLine.lineCoverages.schedules.value';
    const lobScheduleItemsPath = generateLineCoveragesPath(submissionVM)(lobSchedulesPath)('ScheduleItems');
    return lobScheduleItemsPath;
};

function ValuablesCard(props) {
    const currencyFormatter = useCurrency();
    const { showModal } = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        hideLimitsReferral,
        isDisabled,
        valuablesSingleArticleLimit,
        valuablesReplacementLimit
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useTagManager();

    const highValueItemsPath = generateHighValueItemsPath(submissionVM);

    const mapHighValueItemFromDTO = useCallback((itemData) => ({
        type: itemData.ArticleValuationMethod.typeCodeValue,
        description: itemData.ArticleType.typeCodeValue,
        replacementCost: currencyFormatter
            .formatCurrency(`${itemData.ArticleLimit.bigDecimal}`.replaceAll(',', ''), true, false),
        geoLimit: itemData.ArticleGeographicLimit.typeCodeValue,
        storage: itemData.ArticleKeptinBank.booleanValue,
    }), [currencyFormatter]);

    const openHighValueItemsModal = useCallback(async (items = []) => {
        const { type, data: formData } = await showModal(
            <ValuablesModal
                wizardData={submissionVM}
                singleArticleLimit={valuablesSingleArticleLimit}
                viewModelService={viewModelService}
            />
        );

        if (type === 'CANCEL') {
            return [];
        }

        items.push(formData);

        if (type === 'REOPEN') {
            await openHighValueItemsModal(items);
        }

        return items;
    }, [submissionVM, valuablesSingleArticleLimit, viewModelService, showModal]);

    const addItem = useCallback(async () => {
        hideLimitsReferral();
        const items = await openHighValueItemsModal();
        const scheduleItems = _.get(submissionVM, `${highValueItemsPath}.scheduleItems`) || [];
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${highValueItemsPath}.scheduleItems`, [...scheduleItems, ...items.map((item) => mapHighValueItemToDTO(item))]);
        updateWizardData(newSubmissionVM);
    }, [
        submissionVM,
        openHighValueItemsModal,
        updateWizardData,
        highValueItemsPath,
        viewModelService,
        hideLimitsReferral
    ]);

    const editItem = useCallback(async (lobPath, item) => {
        hideLimitsReferral();
        const initialFormData = mapHighValueItemFromDTO(item.itemData);

        const { type, data: formData } = await showModal(
            <ValuablesModal
                isEditMode
                wizardData={submissionVM}
                initialFormData={initialFormData}
                singleArticleLimit={valuablesSingleArticleLimit}
                viewModelService={viewModelService}
            />
        );

        if (type === 'CANCEL') {
            return;
        }

        const scheduleItems = _.get(submissionVM, lobPath);
        const index = _.get(submissionVM, lobPath).findIndex((hvi) => {
            return hvi?.itemData?.PublicID?.typeCodeValue
                ? hvi?.itemData?.PublicID?.typeCodeValue === item.itemData?.PublicID?.typeCodeValue
                : hvi?.tempID === item.tempID;
        });
        scheduleItems[index] = mapHighValueItemToDTO(
            _.merge(initialFormData, formData),
            {
                publicID: item.itemData?.PublicID?.typeCodeValue,
                tempID: item.tempID,
                itemNumber: item.itemNumber
            }
        );

        _.set(submissionVM, lobPath, scheduleItems);
        updateWizardData(submissionVM);

        if (type === 'REOPEN') {
            addItem();
        }
    }, [
        submissionVM,
        addItem,
        updateWizardData,
        valuablesSingleArticleLimit,
        mapHighValueItemFromDTO,
        hideLimitsReferral,
        viewModelService,
        showModal
    ]);

    const removeItem = useCallback(async (lobPath, publicID, tempID) => {
        hideLimitsReferral();
        const { type } = await showModal(
            <NfumConfirmModal variant="variant3" />
        );
        if (type === 'CANCEL') {
            return;
        }
        const newScheduleItems = _.get(submissionVM, lobPath).filter((item) => {
            return publicID
                ? item?.itemData?.PublicID?.typeCodeValue !== publicID
                : item?.tempID !== tempID;
        });
        _.set(submissionVM, lobPath, newScheduleItems);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, hideLimitsReferral, showModal]);

    const getHighValueItems = useCallback(() => {
        const lobScheduleItemsPath = generateHighValueItemsPath(submissionVM);
        const lobScheduleItems = _.get(submissionVM, `${lobScheduleItemsPath}.scheduleItems`);
        if (lobScheduleItems) {
            return lobScheduleItems
                .filter(({ itemData }) => !_.isEmpty(itemData.ArticleGeographicLimit));
        }
        return [];
    }, [submissionVM]);

    const generateValuablesOverrides = useCallback(() => {
        const lobPath = `${highValueItemsPath}.scheduleItems`;
        const highValueItems = getHighValueItems();
        const covOverrides = highValueItems.map((changedField, index) => {
            const category = changedField.itemData.ArticleValuationMethod.typeCodeValue;
            return {
                [`valuableName${index}`]: {
                    content: changedField.itemData.ArticleType.typeCodeValue
                },
                [`articleValuationMethod${index}`]: {
                    value: translator({
                        id: `typekey.SpecifiedValuableType_NFUM.${category}`,
                        defaultMessage: category
                    })
                },
                [`articleLimit${index}`]: {
                    value: currencyFormatter
                        .formatCurrency(changedField.itemData.ArticleLimit.bigDecimal, true)
                },
                [`articleGeographicLimit${index}`]: {
                    value: changedField.itemData.ArticleGeographicLimit.typeCodeValue === 'AtHomeOnly'
                        ? translator(messages.insideHome)
                        : translator(messages.insideOutsideHome)
                },
                [`articleKeptInBank${index}`]: {
                    value: changedField.itemData.ArticleKeptinBank.booleanValue
                        ? translator(messages.yes)
                        : translator(messages.no)
                },
                [`editButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => editItem(lobPath, changedField)
                },
                [`deleteButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => removeItem(
                        lobPath,
                        changedField.itemData?.PublicID?.typeCodeValue,
                        changedField.tempID
                    )
                }
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [
        translator,
        editItem,
        getHighValueItems,
        removeItem,
        highValueItemsPath,
        currencyFormatter,
        isDisabled
    ]);

    const openFullList = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/help-and-advice/ngh-valuables/';
        pushLinkClickInfo(translator(messages.fullList), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        highValueItems: {
            data: getHighValueItems(),
            visible: getHighValueItems().length > 0
        },
        valuablesCtaDescription: {
            content: _.replace(metadata.valuablesCtaDescription, '{value}', '1000'),
        },
        valuablesLimitValue: {
            content: `£${valuablesSingleArticleLimit}`
        },
        maxCover: {
            content: `£${valuablesReplacementLimit}`
        },
        addButton: {
            onClick: addItem,
            disabled: isDisabled
        },
        fullList: {
            onClick: openFullList
        },
        ...generateValuablesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            valuablesLimitExceeded: () => (
                <CallOrContactInfo
                    header={translator(messages.ExceededCoverLimit)}
                    description={translator(messages.ExceededCoverLimitContent)}
                />
            ),
        }
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            valuableLimitExceeded
        />
    );
}

ValuablesCard.propTypes = wizardProps;
export default ValuablesCard;
